body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

@font-face {
	font-family: "SF UI Display";
	font-style: normal;
	font-weight: normal;
	src: local("SF UI Display"),
		url("./assets/fonts/sf-ui-display-light-58646b33e0551.otf") format("woff");
}
@font-face {
	font-family: "SF UI Display medium";
	src: local("SF UI Display"),
		url("./assets/fonts/sf-ui-display-medium-58646be638f96.otf") format("woff");
}
@font-face {
	font-family: "SF UI Display bold";
	src: local("SF UI Display"),
		url("./assets/fonts/sf-ui-display-bold-58646a511e3d9.otf") format("woff");
}
@font-face {
	font-family: "SF UI Display semibold";
	src: local("SF UI Display"),
		url("./assets/fonts/sf-ui-display-semibold-58646eddcae92.otf")
			format("woff");
}

@font-face {
	font-family: "ZangeziSans07_TRIAL";
	font-style: normal;
	font-weight: normal;
	src: local("ZangeziSans07_TRIAL"),
		url("./assets/fonts/ZANGEZISANS07_TRIAL-BLACK.OTF") format("woff");
}

@font-face {
	font-family: "Gotham";
	font-style: normal;
	font-weight: normal;
	src: local("Gotham"), url("./assets/fonts/GothamMedium.ttf") format("woff");
}
