.namediv {
	gap: 10px;
	font-family: "SF UI Display";
	margin: 30px 0;
}

.firstname {
	display: flex;
	flex-direction: column;
}

.namediv label {
	color: #707070;
	font-family: "SF UI Display";
	font-size: 11px;
	margin-bottom: 0;
}

.lastname {
	display: flex;
	flex-direction: column;
}

.namediv input {
	width: 100%;
	background-color: #f1efde;
	border: unset !important;
	/* padding: 4px 8px; */
	border-bottom: 1px solid #707070 !important;
	padding-top: 20px;
	margin-bottom: 20px;
	font-size: 13px;
	font-family: "SF UI Display";
	color: #000;
}
.namediv input::placeholder {
	color: #000;
	font-family: "SF UI Display";
	font-weight: 600;
}
.profile.myaddress_page .heading-top-cont {
	margin-top: 24px;
	margin-bottom: 16px;
	margin-left: 0;
}

.namediv input:focus {
	outline: none;
}

.save-address .container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}
.save-address .container span.checkmark {
	position: unset;
}
.save-address label.container {
	padding: 0;
}
.save-address label.container .select-address {
	border: 2px solid #b7b6ac;
	padding: 20px 30px;
	position: relative;
	overflow: hidden;
	margin-bottom: 12px;
}

.save-address label.container input:checked ~ span.checkmark .select-address {
	box-shadow: 0px 0px 0px 2px #286152;
	border-color: #fff;
	border-radius: 0;
}
.checkmark .select-address .radio-btn span {
	width: 15px;
	height: 15px;
	background: #fff;
	position: absolute;
	left: 0;
	border-radius: 50%;
	border: 2px solid #fff;
	box-shadow: 0px 0px 0px 1px #286152;
	top: 10px;
	margin: 20px 20px;
}

.save-address
	label.container
	input:checked
	~ span.checkmark
	.select-address
	.radio-btn
	span {
	background: #286152;
}
.checkmark .select-address {
	position: relative;
}
/*  .select-address .address-detail {
	padding-left: 20px;
	margin-left: 30px;
	border-left: 1px solid #7070705c;
} */
.checkmark .select-address .address-detail h5 {
	width: 100%;
	display: flex;
	padding: 4px 0;
	align-items: center;
}
.checkmark .select-address .address-detail h5 span.property {
	font-size: 14px;
	color: #707070;
	font-weight: 300;
	font-family: "SF UI Display";
}
.checkmark .select-address .address-detail h5 span.value {
	margin-left: 20px;
	font-weight: 300;
	color: #707070;
	font-size: 13px;
	font-family: "SF UI Display";
}
.checkmark .select-address .button-group {
	position: absolute;
	bottom: 20px;
	right: 20px;
}
.checkmark .select-address .address-detail button.btn {
	background: #e7e7e7;
	color: #7d7d7d;
	font-size: 15px;
	font-weight: 500;
	text-transform: capitalize;
	opacity: 1;
	border-radius: 0;
	padding: 6px 20px;
}
.checkmark .select-address .address-detail button.lnk {
	background: transparent;
	border: 0;
	font-size: 15px;
	color: #1473e6;
	text-decoration: underline;
	margin-left: 10px;
}
.add-new-address {
	padding-top: 20px;
	position: relative;
}
.save-address {
	margin-top: 40px;
}
.deafult {
	display: flex;
	align-items: center;
	position: absolute;
	right: 20px;
	bottom: 10px;
}

.saved-address-heading {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.editbutton-address {
	text-align: right;
}

.saved-address-heading a,
.editbutton-address a {
	color: #286152;
	font-size: 13px;
	font-family: "SF UI Display";
	margin-left: 10px;
	font-weight: 600;
}

.deafult span.dot {
	width: 16px;
	height: 13px;
	display: block;
	border-radius: 50%;
	border: 2px solid #f4f4f4;
	box-shadow: 0px 0px 0px 2px #286152;
}
.deafult h5 {
	color: #1f2c35;
	font-size: 15px;
	margin: 0 20px 0 0px;
}
.save-address label.container input:checked ~ span.checkmark span.dot {
	box-shadow: 0px 0px 0px 2px #286152;
	background: #286152;
}
.save-address label.container .select-address .actions a {
	color: #707070 !important;
	font-size: 16px;
	font-family: "SF UI Display";
	margin-left: 10px;
}

.save-address label.container .select-address .actions a i {
	color: #000;
}

.save-address label.container .select-address .actions {
	position: absolute;
	right: 20px;
	top: 30px;
}
