.Donate-listing {
	padding: 80px 0px;
	background-image: url("../img/bgDesign.png");
	background-size: 100% 100%;
	background-attachment: fixed;
	background-size: 100%;
}

.graph-container {
	width: 160px;
	margin-left: auto;
	margin-right: auto;
	display: block;
}

.graph-donut {
	position: relative;
	display: inline-block;
	width: 160px;
	text-align: center;
}

.graph-donut canvas {
	position: absolute;
	top: 16px;
	left: 0;
}

.graph-title {
	margin-top: 75px;
	text-align: center;
	font-size: 18px;
	color: #453d33;
}

.percent {
	display: inline-block;
	font-size: 20px;
	color: #000;
	border: 1px solid #e3e5e6;
	padding: 1rem;
	border-top: 0;
	border-bottom: 0;
	margin-left: 3rem;
	margin-top: 3px;
	margin-bottom: 3px;
}

.percent:after {
	content: "%";
}
.dntn_prod_desc {
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	border: 1px solid #e3e5e6;
	border-left: 0;
	border-right: 0;
	border-bottom: 0;
	gap: 12px;
}

.pledged_dv {
	border-left: 1px solid;
	padding-left: 12px;
}
.dntn_prod {
	border: 1px solid #e3e5e6;
	margin: 2rem 0;
	border-radius: 14px;
}
.pledged_dv p {
	margin-bottom: 0;
}
.dntn_prod h4 {
	padding: 10px 0;
}
.dntn_prod a img.img-fluid {
	width: 415px;
	border-radius: 13px;
	object-fit: cover;
	height: 293px;
}

@media (max-width: 768px) {
	.Donate-listing {
		background: none;
	}
}
@media (max-width: 320px) {
	.amount_section2 h3 {
		font-weight: bold;
		font-size: 20px;
		color: #262626;
		font-family: "SF UI Display";
		margin: 0px;
		/* margin-left: 61px; */
	}
}
