.Footer {
	background-color: #286152;
	padding: 80px 0px;
}

.Footer .main-title {
	font-size: 22px;
	color: #fff;
	font-family: "SF UI Display";
	font-weight: bold;
	margin-bottom: 20px;
}

.Footer a {
	font-size: 17px;
	color: #fff;
	font-family: "SF UI Display";
	font-weight: 400;
	margin: 0;
	line-height: 35px;
}

.Footer input {
	border: unset;
	width: 100%;
	background-color: #286152;
	color: #fff;
	padding: 23px 0px;
	border-bottom: 1px solid #fff;
}

.footer_padding {
	padding-right: 7rem;
}

.Footer input::placeholder {
	color: #fff;
}

.Footer .big-title {
	text-align: center;
	font-size: 200px;
	font-family: "ZangeziSans07_TRIAL";
	color: #e4e4e4;
}

.Footer .copy-right {
	font-size: 14px;
	font-family: SF UI Display;
	text-align: center;
	color: #ffffff8a;
}

@media (max-width: 1200px) {
	.Footer .big-title {
		font-size: 123px;
	}
}
