.blogs-page {
	padding: 80px 0px;
	background-image: url("../img/bgDesign.png");
	background-size: 100% 100%;
	background-attachment: fixed;
	background-size: 100%;
}

.blogs-page .main-title {
	color: #286152;
	font-size: 50px;
	font-weight: bold;
	text-align: center;
	margin-bottom: 12px;
	font-family: ZangeziSans07_TRIAL;
}

.blogs-page .para {
	font-size: 16px;
	color: #0000008c;
	font-family: "SF UI Display";
	text-align: center;
	padding: 0px 106px;
}

.blogs-page .img-cont {
	overflow: hidden;
	height: 100%;
	width: 100%;
}
.blogs-page .img-cont img {
	width: 100%;
}

.blogs-page .tour_card {
	margin: 40px 0px;
}

.blogs-page .tour_card .card_title {
	font-size: 28px;
	font-family: "SF UI Display";
	color: #000000d6;
	font-weight: 400;
}

.blogs-page .tour_card .card_para {
	font-size: 16px;
	color: #0000008c;
	font-family: "SF UI Display";
}

.blogs-page .tour_card .tour_tag {
	font-size: 20px;
	color: #00000069;
	font-family: "SF UI Display";
	margin: 10px 0px;
}

.blogs-page .tour_card .blog_more {
	color: #fff;
	background: #286152;
	padding: 7px 16px;
	border-radius: 20px;
	text-transform: uppercase;
}

.blogs-page .tour_card .blog_more:hover {
	background-color: #000;
	color: #fff;
}

@media (max-width: 787px) {
	.blogs-page {
		background: none;
	}
}

@media (max-width: 768px) {
	.blogs-page .para {
		padding: 0px 39px;
	}
}

@media (max-width: 414px) {
	.blogs-page .para {
		padding: 0px 9px;
	}
}

@media (max-width: 390px) {
	.blogs-page .para {
		padding: 0px 9px;
	}
}

@media (max-width: 375px) {
	.blogs-page .para {
		padding: 0px 9px;
	}
}

@media (max-width: 320px) {
	.blogs-page .para {
		padding: 0px 9px;
	}
}
