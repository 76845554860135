.home-banner {
	background-image: url("../img/homeBanner.png");
	background-size: 100% 100%;
	width: 100%;
	background-position: center;
	height: 900px;
	background-repeat: no-repeat;
	border-radius: 0 0 60px 65px;
}
.home-banner .banner-cont {
	padding: 170px 0px;
}

.home-banner .banner-cont .main-point {
	font-size: 35px;
	color: #fff;
	font-family: "ZangeziSans07_TRIAL";
	font-weight: bold;
	text-align: center;
}

.home-banner .banner-cont .main-title {
	font-size: 85px;
	color: #fff;
	font-family: "ZangeziSans07_TRIAL";
	font-weight: bold;
	text-align: center;
}

.home-banner .banner-cont .para {
	font-size: 15px;
	font-family: "SF UI Display";
	color: #fff;
	font-weight: 400;
	text-align: center;
	margin-top: 24px;
	padding: 0px 90px;
}

.home-about {
	padding: 80px 0px;
	background-image: url("../img/bgDesign.png");
	background-size: 100% 100%;
	background-attachment: fixed;
	background-size: 100%;
}

.home-about .shadow-title {
	font-size: 100px;
	text-align: center;
	font-weight: bold;
	color: #0000000f;
	margin-bottom: -23px;
	font-family: ZangeziSans07_TRIAL;
}

.home-about .about-title {
	color: #286152;
	font-size: 50px;
	font-weight: bold;
	text-align: center;
	font-family: ZangeziSans07_TRIAL;
}

.home-about .img-cont {
	overflow: hidden;
	height: 100%;
	width: 100%;
}

.home-about .img-cont img {
	width: 100%;
}

.home-about .para {
	font-size: 16px;
	color: #0000008f;
	font-family: SF UI Display;
	text-align: center;
	margin-top: 65px;
}
.home-about .para2 {
	font-size: 16px;
	color: #0000008f;
	font-family: SF UI Display;
	text-align: center;
	margin-top: 45px;
}

.home-product {
	padding: 80px 0px;
}

.set_margin {
	margin-top: 65px;
}

.home-product .shadow-title {
	font-size: 82px;
	text-align: center;
	font-weight: bold;
	color: #0000000f;
	margin-bottom: -23px;
	font-family: ZangeziSans07_TRIAL;
}

.home-product .about-title {
	color: #286152;
	font-size: 50px;
	font-weight: bold;
	text-align: center;
	margin-bottom: 78px;
	font-family: ZangeziSans07_TRIAL;
}

.home-product .product-card {
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.home-product .product-card:hover {
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.home-product .product-card img {
	width: 100%;
}

.home-product .product-card .content {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 26px 10px;
}

.home-product .product-card .content .title {
	font-family: "SF UI Display";
	font-size: 22px;
	color: #0000009e;
	font-weight: 400;
}

.home-product .product-card .content .price {
	font-family: "SF UI Display";
	font-size: 22px;
	color: #0000009e;
	font-weight: bold;
}

.home-social {
	padding: 80px 0px;
}

.home-social .shadow-title {
	font-size: 82px;
	text-align: center;
	font-weight: bold;
	color: #0000000f;
	margin-bottom: -23px;
	font-family: ZangeziSans07_TRIAL;
}

.margin_sett {
	margin: 40px 0;
}
.home-social .about-title {
	color: #286152;
	font-size: 50px;
	font-weight: bold;
	text-align: center;
	margin-bottom: 78px;
	font-family: ZangeziSans07_TRIAL;
}

.home-social .all-icon {
	width: 60%;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	margin-top: 12px;
	margin-bottom: 16px;
}

.home-social .all-icon2 {
	width: 60%;
	margin: 0 auto;
	display: flex;
	justify-content: space-around;
	margin-top: 12px;
	margin-bottom: 16px;
}

.home-social .icon-bg {
	background-c2lor: #286152;
	padding: 30px;
	border-radius: 86px;
}

.home-social .icon-bg img {
	width: 40px;
}

@media (max-width: 500px) {
	input.time_icon {
		background-image: url("../img/back.PNG") !important;
		background-size: 46px !important;
		background-repeat: no-repeat !important;
		background-position: center right !important;
		appearance: none;
	}

	input.date_icon {
		background-image: url("../img/date-icon.PNG") !important;
		background-size: 40px !important;
		background-repeat: no-repeat !important;
		background-position: center right !important;
		appearance: none;
	}
	input.time_icon::after {
		content: "Date";
		position: absolute;
		font-family: " ZangeziSans07_TRIAL";
		font-weight: 600;
	}
	input.date_icon::after {
		content: "Time";
		position: absolute;
		font-family: " ZangeziSans07_TRIAL";
		font-weight: 600;
	}
	input[type="date"]::-webkit-inner-spin-button,
	input[type="date"]::-webkit-calendar-picker-indicator {
		-webkit-appearance: none;
	}
	input[type="time"]::-webkit-inner-spin-button,
	input[type="time"]::-webkit-calendar-picker-indicator {
		-webkit-appearance: none;
	}
}
.home-social .icon-name {
	font-size: 16px;
	font-family: "SF UI Display";
	color: #000000b8;
	text-align: center;
	margin: 10px;
}

.home-post {
	padding: 105px 0px;
	background: #286152;
}

.home-post .img-cont {
	overflow: hidden;
	height: 100%;
	width: 100%;
}

.home-post .img-cont img {
	width: 100%;
}

.home-post .shadow-title {
	font-size: 82px;
	font-weight: bold;
	color: #ffffff14;
	/* margin-bottom: -23px; */
	font-family: " ZangeziSans07_TRIAL";
	/* margin-top: 40px; */
	/* margin-bottom: -14px; */
	margin-right: 162px;
}

.home-post .main-title {
	color: #fff;
	font-size: 44px;
	font-family: "ZangeziSans07_TRIAL";
}

.home-post .para {
	font-family: "SF UI Display medium";
	font-size: 16px;
	color: #ffffff80;
	width: 82%;
	text-align: justify;
}

.home-post .para-points {
	font-family: "SF UI Display";
	font-size: 16px;
	color: #ffffff80;
	width: 82%;
	font-family: "SF UI Display medium";
}

.home-video {
	padding: 80px 0px;
}

.home-video .shadow-title {
	font-size: 82px;
	text-align: center;
	font-weight: bold;
	color: #0000000f;
	margin-bottom: -23px;
	font-family: ZangeziSans07_TRIAL;
}

.home-video .about-title {
	color: #286152;
	font-size: 50px;
	font-weight: bold;
	text-align: center;
	margin-bottom: 78px;
	font-family: ZangeziSans07_TRIAL;
}
.ourfarm_carousel .slick-slide img {
	transform: scale(0.97);
	height: 398px;
}
.home-newsLetter {
	background-color: #286152;
	padding: 55px 0px;
}

.home-newsLetter .main-title {
	font-size: 40px;
	font-family: "ZangeziSans07_TRIAL";
	color: #fff;
	margin: 0;
}
.home-newsLetter .book-btn {
	color: #286152;
	background-color: #fff;
	border-radius: 36px;
	text-transform: uppercase;
	padding: 7px 25px;
}

.home-newsLetter .book-btn:hover {
	background-color: #000;
	color: #fff;
}
.home-newsLetter .book-btn:hover img {
	filter: brightness(0) invert(1);
}
.home-newsLetter input {
	border: unset;
	width: 100%;
	background-color: #286152;
	color: #fff;
	padding: 23px 32px;
	border-bottom: 1px solid #fff;
}

.home-newsLetter input::placeholder {
	color: #fff;
}
.head_title_contact .shadow-title2 {
	margin-right: 160px;
}
.home-contact .home-contact-image {
	height: 75vh;
}

ul {
	list-style: none;
}

li.para-points svg {
	color: #fff;
}

.home_absolute {
	position: relative;
}
/* .btn_absolute {
	position: absolute;
	top: -110px;
	left: 820px;
} */

.home-social .all-icon .icon-bg,
.home-social .all-icon2 .icon-bg {
	background-color: #286152;
	/* padding: 18px; */
	border-radius: 50%;
	width: 70px;
	height: 70px;
	display: flex;
	justify-content: center;
	align-items: center;
}
section.home-banner {
	position: relative;
}

.btn-gr {
	position: absolute;
	bottom: 39px;
	right: 0;
	left: 0;
	right: 0;
	top: unset;
	display: flex;
	justify-content: center;
}
section.home-post .col-lg-7.make_margin {
	margin-top: 3rem;
}
@media (min-width: 1505px) {
	.btn_absolute {
		position: absolute;
		top: -110px;
		left: 650px;
	}
}

@media (max-width: 1453px) {
	.btn_absolute {
		position: absolute;
		top: -100px;
		left: 700px;
	}
}

@media (max-width: 1458px) {
	.btn_absolute {
		position: absolute;
		top: -100px;
		left: 600px;
	}
}

@media (max-width: 1440px) {
	.btn_absolute {
		position: absolute;
		top: -110px;
		left: 600px;
	}
}
@media (max-width: 1404px) {
	.home_absolute {
		position: relative;
	}

	.btn_absolute {
		position: absolute;
		top: -120px;
		left: 600px;
	}
}

@media (max-width: 1366px) {
	.set_para_home {
		margin-top: 62px;
	}

	.home-post {
		padding: 30px 0px;
	}
	.home-contact .para {
		font-size: 16px;
	}
	/* .set_para_home2 {
		margin-top: 110px;
	} */
	.home-banner .banner-cont .para {
		font-size: 22px;
	}
	.home-banner {
		height: 100vh;
	}

	.home-about-image,
	.home-about-image2 {
		height: 72vh;
		object-fit: contain;
	}
	.home-post .shadow-title {
		font-size: 55px;
		margin-top: 0;
		margin-left: -147px;
		margin-bottom: 5px;
	}
	.home-post .main-title {
		font-size: 34px;
	}
	.home-about-section .para,
	.home-about-section .para2 {
		font-size: 17px;
	}
	.home-about .book-btn {
		padding: 9px 20px;
		font-size: 25px;
	}
	.home-product .content .title,
	.home-product .content .price {
		font-size: 25px !important;
	}
	.home-social .all-icon .icon-name,
	.home-social .all-icon2 .icon-name {
		font-size: 18px !important;
		font-weight: 600;
	}

	.home-social .all-icon .icon-bg,
	.home-social .all-icon2 .icon-bg {
		background-color: #286152;
		/* padding: 18px; */
		border-radius: 50%;
		width: 80px;
		height: 80px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	/* .home-social .all-icon .icon-bg img,
	.home-social .all-icon2 .icon-bg img {
		width: 5vw;
	} */

	.home-post .img-cont img {
		height: 100vh;
		object-fit: contain;
	}
	.home-video .shadow-title {
		font-size: 57px;
	}

	.home-video .about-title {
		font-size: 38px;
		margin-bottom: 28px;
		margin-top: 19px;
	}

	.home-video .ourfarm_carousel img {
		height: 54vh;
		width: 100%;
	}

	.home-newsLetter .main-title {
		margin-top: 20px;
	}

	.home-newsLetter .newletter-input::placeholder {
		font-size: 20px;
		color: #ffffff9c !important;
	}

	.home-newsLetter .book-btn {
		padding: 10px 22px;
		font-size: 18px !important;
		margin-top: 5px;
	}

	.home-contact .home-contact-image {
		height: 69vh;
	}

	.home-contact .about-title {
		font-size: 35px;
		margin-top: 14px;
	}

	.home-all-input input::placeholder,
	.home-all-input select {
		font-size: 17px !important;
		color: #00000059;
		font-weight: 600;
	}

	.btn-cont .BOOKNOW {
		padding: 8px 14px !important;
		font-size: 14px !important;
	}
	.check-container p {
		font-size: 14px !important;
		color: #000000ab;
	}
	.home-contact .shadow-title {
		font-size: 61px;
	}
	.btn_absolute {
		position: absolute;
		top: -98px;
		left: 554px;
		width: 15%;
	}
}

@media (max-width: 1304px) {
	.home-social .icon-bg img {
		width: 50px;
	}

	.btn-cont .BOOKNOW {
		padding: 8px 0px !important;
		font-size: 14px !important;
	}
}

/* @media (max-width: 1280px) {
	.btn_absolute {
		position: absolute;
		top: 450px;
		left: 400px;
	}
} */

@media (max-width: 1199px) {
	.home-post .shadow-title {
		margin-left: -11px;
	}
	.set_para_home {
		margin-top: -10px;
	}
	.home-about .para {
		font-size: 12px;
		margin-top: 2px;
	}
}

@media (max-width: 1200px) {
	.home-contact input,
	select {
		width: 84% !important;
	}
	.home-contact .home-contact-image {
		height: 69vh;
		margin-top: 17px;
	}
	.set_para_home {
		margin-top: -10px !important;
	}
	.home-post .img-cont img {
		height: 100%;
	}
	.btn-cont .BOOKNOW {
		padding: 8px 0px !important;
		font-size: 14px !important;
	}
	.home-banner .banner-cont {
		padding: 135px 0px 0px;
	}
	.home-product .product-card .content .price {
		font-size: 19px !important;
	}
	/* .home-banner {
		height: 100%;
	} */
	.home-post .shadow-title {
		font-size: 65px;
		margin-top: 13px;
		margin-bottom: 5px;
	}
	.home-post .para,
	.home-post .para-points {
		font-size: 14px;
		margin: 13px 0px;
	}
	.home-all-input input::placeholder,
	.home-all-input select {
		font-size: 16px !important;
		color: #00000059;
		font-weight: 600;
	}
	.home-contact input,
	select {
		background: transparent;
		border: unset;
		border-bottom: 1px solid #bdb5b5;
		padding: 17px 10px;
		width: 96%;
	}
	.home-newsLetter .book-btn {
		padding: 10px 20px;
		font-size: 20px !important;
		margin-top: 28px;
	}
	.home-newsLetter .main-title {
		font-size: 32px;
	}
	.home-contact .shadow-title {
		font-size: 82px;
		font-weight: bold;
		color: #0000000f;
		margin-bottom: -14px;
		font-family: ZangeziSans07_TRIAL;
		margin-top: 14px;
	}
	.home-banner .banner-cont .main-title {
		font-size: 70px;
	}
	.home-banner .banner-cont .main-point {
		font-size: 30px;
	}
	.home-banner .banner-cont .para {
		font-size: 18px;
	}
	.home-about .shadow-title {
		font-size: 63px;
		margin-bottom: 6px;
	}
	.home-post {
		padding: 17px 0px;
	}
	/* .set_para_home {
		margin-top: 114px;
	} */
	.home-about {
		padding: 15px 0px;
	}
	.home-about .about-title {
		font-size: 40px;
		margin-top: 3px;
	}
	.check-container p {
		font-size: 11px !important;
	}
	.home-about .para {
		font-size: 14px;
		margin-top: 18px;
	}
	.home-about-section .para,
	.home-about-section .para2 {
		font-size: 17px;
	}
	.home-about-image,
	.home-about-image2 {
		height: 68vh;
		margin-bottom: 30px;
	}

	.btn_absolute {
		position: absolute;
		top: -70px;
		left: 400px;
		width: 20%;
	}
}

@media (max-width: 1160px) {
	.btn_absolute {
		position: absolute;
		top: -70px;
		left: 420px;
		width: 20%;
	}
	.home-newsLetter .book-btn {
		padding: 10px 15px;
		font-size: 17px !important;
		margin-top: 28px;
	}
}

@media (max-width: 1024px) {
	.btn_absolute {
		position: absolute;
		top: 10px;
		left: 420px;
		width: 100%;
		padding: 8px 15px;
	}
	.btn_absolute {
		position: absolute;
		top: -60px;
		left: 280px;
		width: 40%;
	}
	.home-newsLetter .book-btn {
		padding: 10px 17px;
		font-size: 17px !important;
		margin-top: 27px;
	}
	.home-newsLetter input {
		padding: 23px 5px;
	}
}

@media (max-width: 991px) {
	.home-post .shadow-title {
		margin-left: -200px;
	}
	.home-about .shadow-title {
		font-size: 41px;
		margin-bottom: 4px;
	}
	section.home-about .img-cont img {
		width: 100%;
		height: 300px !important;
	}
	.margin_btm {
		margin-bottom: 40px !important;
	}
	.home-post .main-title {
		font-size: 24px;
	}
	.set_para_home {
		margin-top: 35px;
	}
	.home-newsLetter .main-title {
		font-size: 25px;
	}
	.home-contact .shadow-title {
		font-size: 60px;
		margin-top: -1px;
	}

	.btn-cont .BOOKNOW {
		padding: 8px 3px !important;
		font-size: 14px !important;
	}
	.home-newsLetter .book-btn {
		padding: 9px 92px;
		font-size: 19px !important;
	}
	.home-newsLetter .main-title {
		font-size: 24px;
	}
	.home-about-section .para,
	.home-about-section .para2 {
		font-size: 13px;
	}
	.home-post {
		padding: 10px 0px;
	}
	.home-post .img-cont {
		margin-bottom: -150px;
	}
	.lectuus_wewe {
		margin-top: 12px;
	}

	.home-contact .home-contact-image {
		height: 100%;
		width: 100%;
	}

	.home-about .about-title {
		font-size: 37px;
		margin-top: 16px;
	}
	.home-about .para {
		font-size: 15px;
		margin-top: 30px;
	}
	.check-container p {
		font-size: 11px !important;
		color: #000000ab;
	}

	.home-contact input,
	select {
		padding: 20px 4px;
		width: 90%;
	}
	.home-contact .home-contact-image {
		height: 100%;
	}
	.home-banner .banner-cont .para {
		font-size: 13px;
	}
	.home-banner .banner-cont .main-title {
		font-size: 53px;
	}
	.home-banner .banner-cont .main-point {
		font-size: 23px;
	}
	.btn_absolute {
		position: absolute;
		top: -70px;
		left: 230px;
		width: 40%;
	}
}

@media (max-width: 820px) {
	.home-contact input,
	select {
		width: 99% !important;
	}

	.secondbtn {
		margin-left: 3px;
		margin-bottom: 16px;
	}
}

@media (max-width: 768px) {
	.home-post-image1 {
		height: 74vh !important;
	}
	.ourfarm_carousel .slick-slide img {
		height: 250px;
	}
	.home-banner {
		height: 70vh;
	}
	.home-about {
		padding: 6px 0px;
		background: none;
	}
	.home-about .shadow-title {
		font-size: 60px;
		margin-bottom: 4px;
	}
	.home-about-image,
	.home-about-image2 {
		height: 67vh;
	}
	.home-about .about-title {
		font-size: 46px;
	}

	.home-about .para,
	.home-about .para2 {
		font-size: 16px;
		margin-top: 19px;
	}
	.home-about .book-btn {
		padding: 12px 25px;
		font-size: 25px;
	}
	.home-banner .banner-cont .para {
		font-size: 13px;
		margin-top: 15px;
		padding: 0px 4px;
	}
	.home-banner .para {
		font-size: 17px;
	}
	.main-point {
		font-size: 19px !important;
	}
	.home-banner .banner-cont {
		padding: 72px 0px 0px;
	}
	.main-title {
		font-size: 30px !important;
	}
	.home-about .img-cont {
		margin: 20px 0;
	}
	.home-about-image3,
	.home-about-image4,
	.home-about-image5 {
		height: 67vh;
	}
	.home-product .shadow-title {
		font-size: 62px;
		margin-bottom: -11px;
	}
	.home-product .about-title {
		margin-bottom: 23px;
	}
	.home-product {
		padding: 7px 0px;
	}
	.home-post-image1 {
		height: 100vh !important;
	}
	.home-social .shadow-title {
		font-size: 60px;
		margin-bottom: -2px;
	}
	.home-newsLetter .main-title {
		font-size: 25px !important;
	}
	.home-contact .shadow-title {
		font-size: 49px;
		margin-top: 12px;
	}
	.Footer .big-title {
		font-size: 87px;
	}
	.home-newsLetter .book-btn {
		margin: 20px 0px;
		font-size: 25px !important;
	}
	.check-container {
		width: 100%;
	}

	.home-contact .BOOKNOW {
		width: 100%;
		padding: 40px 0px;
	}

	.btn_absolute {
		position: absolute;
		top: -70px;
		left: 200px;
		width: 40%;
	}
	.home-product .product-card .content .title {
		margin-bottom: 0;
	}
	.home-product .product-card .content .price {
		margin-bottom: 0;
	}
}

@media (max-width: 575px) {
	.btn_absolute {
		position: absolute;
		top: -80px;
		left: 170px;
		width: 40%;
	}
	.home-about .shadow-title {
		font-size: 50px;
		margin-bottom: 0px;
	}
}

@media (max-width: 500px) {
	.abcdefgh {
		height: unset !important;
		margin-bottom: unset !important;
	}
	.lectus-quam.lectuus_wewe.set_para_home2 {
		overflow-x: hidden;
	}
	.home-post .para,
	.home-post .para-points {
		font-size: 14px;
		margin: 13px 0px;
		padding: 0px 6px;
	}
	.btn-cont .BOOKNOW {
		font-size: 18px !important;
	}
	.home-newsLetter .main-title {
		margin-top: -44px;
	}
	.home-newsLetter {
		padding: 0px 0px;
		padding-bottom: 40px;
	}
	.set_margin {
		margin-top: -111px;
		margin-bottom: 0px;
	}
	.unit_link_set {
		margin-left: -36px;
		width: 134%;
	}
	.home-product .product-card .content .price {
		font-size: 22px !important;
		margin-top: -9px;
	}
	.home-about-image,
	.home-about-image2 {
		object-fit: inherit;
		height: auto !important;
	}
	.margin_set2 {
		margin: 0;
	}
	.margin_set {
		margin: 0px;
	}
	.home-about-image,
	.home-about-image2 {
		margin-bottom: -28px;
	}
	.home-banner {
		border-radius: 0px;
		background-size: cover;
		width: 100%;
		height: 60vh;
		background-repeat: no-repeat;
	}
	.home-banner .main-point {
		font-size: 11px !important;
	}
	.btn_absolute {
		top: -56px;
		width: 50%;
		padding: 5px;
		left: 120px;
	}

	.Footer .big-title {
		text-align: center;
		font-size: 90px;
		font-family: "ZangeziSans07_TRIAL";
		color: #e4e4e4;
	}
	.banner-cont .main-title {
		font-size: 23px !important;
	}
	.home-banner .banner-cont .para {
		padding: 0px;
	}
	.home-banner .banner-cont {
		padding: 0px 0px;
		padding-top: 54px;
	}

	.banner-cont .para {
		font-size: 12px !important;
	}

	.home-about .shadow-title {
		font-size: 35px;
		margin-top: 14px;
	}

	.home-about-image,
	.home-about-image2 {
		height: 51vh;
	}
	.home-about-image3,
	.home-about-image4,
	.home-about-image5 {
		height: 51vh;
	}
	.home-product .about-title {
		margin-bottom: 22px;
		margin-top: 19px;
	}
	.home-about-section .about-title {
		font-size: 26px;
	}

	.home-about-section .para {
		font-size: 14px;
		margin-top: 14px;
	}

	.home-about-section .para,
	.home-about-section .para2 {
		font-size: 14px;
	}

	.home-about-section .book-btn {
		font-size: 20px;
		padding: 10px 20px;
		align-items: center;
		margin: 0 auto;
		text-align: center;
	}

	.home-about-section .book-btn img {
		display: none;
	}

	.home-product .shadow-title {
		font-size: 40px;
		margin-top: -100px;
		margin-bottom: 20px;
	}

	.home-product .about-title {
		font-size: 30px;
		margin-top: 27px;
	}

	.home-social .shadow-title {
		font-size: 35px;
		margin-top: 0px;
		margin-bottom: -20px;
	}

	.home-about .about-title {
		margin-top: 34px;
		margin-bottom: 17px;
	}

	.home-social {
		padding: 29px 0px;
	}

	.home-social .about-title {
		font-size: 25px;
	}
	.home-post-image1 {
		height: 69vh !important;
	}

	.home-social .all-icon,
	.all-icon2 {
		display: flex;
		flex-direction: column;
	}
	.all-icon .icon-bg,
	.all-icon2 .icon-bg {
		width: 36% !important;
		margin: auto;
	}

	.all-icon2 .icon-bg img {
		width: 196% !important;
	}
	.home-post .shadow-title {
		font-size: 36px !important;
		margin-top: -74px;
		margin-left: -7px;
		margin-bottom: 20px !important;
	}

	.home-post .para {
		width: 99%;
	}

	.home-post .main-title {
		font-size: 25px !important;
		margin-bottom: 18px;
	}

	.home-post .para {
		font-size: 17px !important;
	}
	.home-video .shadow-title {
		font-size: 60px !important;
		margin-top: 15px;
		margin-bottom: 20px !important;
	}
	.home-video .about-title {
		font-size: 30px !important;
	}
	.home-video {
		display: none !important;
	}

	.home-newsLetter .main-title {
		font-size: 26px !important;
	}

	.home-contact .img-cont img {
		width: 98%;
	}

	.home-contact .shadow-title {
		font-size: 46px !important;
		margin-top: 37px;
		width: 100%;
		margin-bottom: -26px !important;
		text-align: left;
		margin-left: 0 !important;
	}

	.home-contact .about-title {
		font-size: 26px !important;
		margin-left: 0px;
	}
	.home-contact .para {
		font-size: 15px;
	}
	.main-title {
		font-size: 22px !important;
	}
	.Footer .big-title {
		font-size: 61px;
	}
	.home-contact input,
	select {
		background: transparent;
		border: unset;
		border-bottom: 1px solid #bdb5b5;
		padding: 20px 10px;
		width: 97%;
	}
	button.btn.book-btn img.image-fluid.ml-2 {
		display: none;
	}

	.home-about .book-btn {
		padding: unset;
		font-size: 25px;
	}
	section.home-about .img-cont.lecture_Img img {
		object-fit: inherit;
	}
}

@media (max-width: 425px) {
	.home-newsLetter .main-title {
		margin-top: -15px;
	}
	.set_margin {
		margin-top: -11px;
	}
	.home-banner {
		height: 74vh;
	}
	.btn_absolute {
		top: -32px;
		width: 50%;
		padding: 3px;
		left: 111px;
	}
	.home-about-image3,
	.home-about-image4,
	.home-about-image5 {
		height: 64vh;
	}
	.home-post-image1 {
		height: unset !important;
	}
	.home-post .shadow-title {
		font-size: 36px !important;
		margin-top: 0px;
		margin-left: -7px;
		margin-bottom: 10px !important;
	}
	section.home-post .col-lg-7.make_margin {
		margin-top: 0rem;
	}
}

@media (max-width: 414px) {
	section.home-about .img-cont img {
		width: 100%;
		height: 300px !important;
	}
	.margin_btm {
		margin-bottom: 40px !important;
	}
	.home-post-image1 {
		height: unset !important;
	}
	section.home-about .img-cont.lecture_Img img {
		width: 100% !important;
		height: 500px !important;
		object-fit: inherit;
	}
	.home-newsLetter .main-title {
		margin-top: 16px;
	}
	.home-social {
		padding: 0px 0px 29px;
	}
}
@media (max-width: 393px) {
	.btn_absolute {
		top: -80px;
	}
	.home-post .shadow-title {
		width: 100%;
		text-align: left;
		margin-left: 0;
	}
}
@media (max-width: 390px) {
	.margin_sett {
		margin: 40px 0 0px;
	}
	.home-about .img-cont {
		margin: 0;
	}
	.lectus-quam.lectuus_wewe.set_para_home2 {
		overflow-x: hidden;
	}
	.home-post .para,
	.home-post .para-points {
		font-size: 14px;
		margin: 13px 0px;
		padding: 0px 6px;
	}
	/* .home-about .about-title {
		margin-top: 33px;
		margin-bottom: 70px;
	} */

	.home-social .all-icon .icon-bg,
	.home-social .all-icon2 .icon-bg {
		width: 107px !important;
		height: 100px;
	}
	.home-post .shadow-title {
		font-size: 34px !important;
		margin-top: 15px;
		margin-left: 4px;
		margin-bottom: 20px !important;
	}
	.home-about-image,
	.home-about-image2 {
		margin-top: 40px;
		margin-bottom: 47px !important;
	}
	.home-social {
		padding: 98px 0px 0px 0px;
	}
	.margin_set2 {
		margin: 17px 0;
	}

	.set_para_home {
		margin-top: 9px;
	}
	.set_margin {
		margin-top: 0px;
	}
	.unit_link_set {
		margin-left: -36px;
		width: 134%;
	}
	.check-container p {
		font-size: 17px !important;
	}
	.home-post .shadow-title {
		margin-left: -27px;
	}
	.home-social .icon-bg img {
		width: 120px;
	}
	.home-banner {
		background-size: cover;
		width: 100%;
		height: 60vh;
		background-repeat: no-repeat;
	}
	.BOOKNOW {
		margin-bottom: 0px;
	}
	.btn_absolute {
		top: -56px;
		width: 55%;
		padding: 5px;
		left: 90px;
		font-size: 15px;
	}
	.home-banner .main-point {
		font-size: 11px !important;
	}

	.Footer .big-title {
		text-align: center;
		font-size: 90px;
		font-family: "ZangeziSans07_TRIAL";
		color: #e4e4e4;
	}
	.banner-cont .main-title {
		font-size: 23px !important;
	}
	.home-banner .banner-cont .para {
		padding: 0px;
	}
	.home-banner .banner-cont {
		padding: 0px 0px;
		padding-top: 54px;
	}

	.banner-cont .para {
		font-size: 12px !important;
	}

	.home-about .shadow-title {
		font-size: 35px;
		margin-top: 0px;
		margin-bottom: -11px;
	}

	.home-about-image,
	.home-about-image2 {
		height: 51vh;
	}
	.home-about-image3,
	.home-about-image4,
	.home-about-image5 {
		height: 51vh;
	}
	.home-product .about-title {
		margin-bottom: 22px;
		margin-top: 32px;
	}
	.home-about-section .about-title {
		font-size: 26px;
	}

	.home-about-section .para {
		font-size: 14px;
		margin-top: 14px;
	}

	.home-about-section .para,
	.home-about-section .para2 {
		font-size: 14px;
	}

	.home-about-section .book-btn {
		font-size: 20px;
		padding: 10px 20px;
		align-items: center;
		margin: 0 auto;
		text-align: center;
	}

	.home-about-section .book-btn img {
		display: none;
	}

	.home-product .shadow-title {
		font-size: 40px;
		margin-top: -100px;
		margin-bottom: 20px;
	}

	.home-product .about-title {
		font-size: 30px;
	}

	.home-social .shadow-title {
		font-size: 35px;
		margin-top: -73px;
		margin-bottom: 0px;
	}

	.home-social .all-icon {
		margin-top: 21px;
	}

	.home-social .about-title {
		font-size: 25px;
	}
	.home-post-image1 {
		height: 69vh !important;
	}

	.home-social .all-icon,
	.all-icon2 {
		display: flex;
		flex-direction: column;
	}

	.all-icon .icon-bg,
	.all-icon2 .icon-bg {
		border-radius: 50% !important;
		width: 50% !important;
		margin: auto;
	}

	.all-icon .icon-bg img {
		width: 100% !important;
	}
	.all-icon2 .icon-bg img {
		width: 100% !important;
	}
	.home-post .shadow-title {
		font-size: 34px !important;
		margin-top: 15px;
		margin-left: 4px;
		margin-bottom: 20px !important;
	}
	.home-post .main-title {
		font-size: 21px !important;
		margin-bottom: 18px;
	}

	.home-post {
		padding: 25px 0px;
		background: #286152;
	}

	.home-post .para {
		font-size: 17px !important;
		width: 100%;
	}
	.home-video .shadow-title {
		font-size: 60px !important;
		margin-top: 15px;
		margin-bottom: 20px !important;
	}
	.home-video .about-title {
		font-size: 30px !important;
	}
	.home-video {
		display: none !important;
	}

	.home-newsLetter .main-title {
		font-size: 26px !important;
	}

	.home-contact .img-cont img {
		width: 98%;
	}

	.home-contact .img-cont img {
		height: 48vh;
	}

	.home-contact .shadow-title {
		font-size: 30px !important;
		margin-top: 15px;
		margin-bottom: -26px !important;
		margin-left: -58px;
	}
	.home-newsLetter .book-btn {
		padding: 5px 0;
		width: 100%;
		font-size: 20px !important;
		text-align: center;
	}

	.btn-cont .BOOKNOW {
		padding: 7px 0px !important;
		font-size: 17px !important;
	}

	.home-contact .about-title {
		font-size: 26px !important;
	}
	.home-contact .para {
		font-size: 15px;
	}
	.main-title {
		font-size: 22px !important;
	}
	.Footer .big-title {
		font-size: 47px;
	}
	.home-contact input,
	select {
		background: transparent;
		border: unset;
		border-bottom: 1px solid #bdb5b5;
		padding: 20px 10px;
		width: 97%;
	}
	button.btn.book-btn img.image-fluid.ml-2 {
		display: none;
	}

	.home-about .book-btn {
		padding: unset;
		font-size: 25px;
	}
	.home-newsLetter {
		padding: 0px 0px;
	}
	.home-banner {
		border-radius: 0px;
	}

	.img-data img {
		width: 100%;
		height: 100%;
	}
	.margin_btm {
		margin-bottom: -32px !important;
	}
}

@media (max-width: 375px) {
	.home-product .product-card .content {
		padding: 0px 10px;
	}
	section.home-post .col-lg-7.make_margin {
		margin-top: 0rem;
	}
	.margin_sett {
		margin: 0px;
	}
	.home-post .shadow-title {
		font-size: 31px !important;
	}
	.home-contact input,
	select {
		width: 99% !important;
	}
	.margin_btm {
		margin-bottom: 14px !important;
	}
	section.home-about .img-cont img {
		width: 100%;
		height: 300px !important;
		margin: 20px 0px 0 0;
		border-radius: 20px;
		object-fit: cover;
		margin-bottom: 0 !important;
	}
	section.home-about .img-cont.lecture_Img img {
		width: 100% !important;
		height: 400px !important;
		object-fit: inherit;
	}
}
@media (max-width: 360px) {
	section.home-about .img-cont img {
		margin-bottom: 0 !important;
	}
	.home-contact .shadow-title {
		margin-left: 0;
	}
	.home-contact .about-title {
		font-size: 26px !important;
		margin-left: 0;
	}
}
@media (max-width: 320px) {
	.home-social .about-title {
		font-size: 22px;
	}
	img.image-fluid.home-about-image2.abcdefgh {
		border-radius: 20px;
	}
	/* section.home-about .img-cont img {
		width: 100%;
		height: 226px !important;
	} */
	.home-post .shadow-title {
		margin-left: 0;
		width: 100%;
	}
	.home-about .about-title {
		margin-top: 6px;
		margin-bottom: 17px;
	}

	.btn_absolute {
		top: -88px;
		width: 55%;
		padding: 5px;
		left: 75px;
		font-size: 15px;
	}

	.margin_btm {
		margin-bottom: 21px !important;
	}
	.home-banner {
		background-size: cover;
		width: 100%;
		height: 100vh;
		background-repeat: no-repeat;
		border-radius: 0px;
	}
	.check-container p {
		font-size: 17px !important;
	}
	.home-contact input,
	select {
		font-size: 16px;
	}
	.lectuus_wewe {
		margin-top: 5px;
	}
	.set_para_home {
		margin-top: -22px;
	}
	.home-social {
		padding: 0px 0px;
	}
	.home-product .about-title {
		font-size: 27px;
	}
	.home-about-image3,
	.home-about-image4,
	.home-about-image5 {
		height: 100% !important;
	}
	.home-about .shadow-title {
		margin-top: 13px;
		margin-bottom: 8px;
	}
	.home-product .content .title,
	.home-product .content .price {
		font-size: 20px !important;
	}

	.home-post .para {
		font-size: 12px !important;
		width: 100%;
	}
	.home-post .para,
	.home-post .para-points {
		font-size: 13px;
		margin: 13px 0px;
		margin-left: 0px;
	}
	.home-newsLetter .main-title {
		font-size: 21px !important;
	}
	.home-newsLetter .book-btn {
		margin: 18px 0px;
		font-size: 20px !important;
	}
	.home-about .img-cont {
		margin: 7px 0;
	}
	.home-contact .shadow-title {
		font-size: 22px !important;
		margin-top: -9px;
		margin-bottom: 20px !important;
		margin-left: 6px;
	}
	.home-contact .about-title {
		font-size: 21px !important;
	}
	.home-contact .para {
		font-size: 12px;
	}
	section.home-about .img-cont.lecture_Img img {
		width: 100% !important;
		height: 400px !important;
		object-fit: inherit;
	}
}
