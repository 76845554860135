.detail_amount_total {
	text-align: center;
}

.main_heading .main_title {
	color: #286152;
	font-size: 50px;
	font-weight: bold;
	text-align: center;
	font-family: ZangeziSans07_TRIAL;
}

.main_heading .main_number {
	font-size: 31px;
	font-weight: 600;
}
.main_heading {
	margin: 0px 0 40px 0px;
}

.card_details {
	border: 2px solid #8e8d89;
	padding: 30px;
	text-align: center;
}

.details_head {
	font-size: 20px;
	font-weight: bold;
	color: #286152;
	font-family: "SF UI Display";
}

.all_booking_details ul {
	list-style: none;
	width: 100%;
	padding: 0;
}

.all_booking_details ul li {
	padding: 5px 0;
	display: flex;
	justify-content: space-between;
	color: #000;
	font-family: "SF UI Display";
	font-weight: 600;
}
