.actionLink {
	position: fixed;
	right: 0;
	top: 23%;
	z-index: 2;
}
.actionLink .contactDv {
	display: flex;
	align-items: end;
	transform: translateX(100%);
	transition: 0.6s;
	position: relative;
}

/* .actionLink .contactDv {
	transform: translateY(-60px);
} */

/* .actionLink .allIcons .contact-btn {
	transform: rotate(-90deg);
	background: #00b786e9;
	height: 75px;
	width: 200px;
	text-align: center;
	line-height: 60px;
	color: #515151;
	font-weight: 700;
	font-size: 16px;
	margin-bottom: 50px;
	border-radius: 15px 15px 0 0px;
	cursor: pointer;
	position: absolute;
	right: -84px;
	bottom: 250px;
	transition: 0.6s;
} */

.actionLink .allIcons .contact-btn {
	transform: rotate(-90deg);
	background: #00b786e9;
	height: 65px;
	width: 276px;
	text-align: center;
	line-height: 60px;
	color: #515151;
	font-weight: 700;
	font-size: 16px;
	margin-bottom: 50px;
	border-radius: 15px 15px 0 0px;
	cursor: pointer;
	position: absolute;
	right: -106px;
	bottom: -113px;
	transition: 0.6s;
	display: flex;
	align-items: center;
	justify-content: center;
}

/* .contact-btn svg.svg-inline--fa.fa-facebook-f,
.contact-btn svg.svg-inline--fa.fa-instagram,
.contact-btn svg.svg-inline--fa.fa-twitter,
.contact-btn svg.svg-inline--fa.fa-pinterest-p,
.contact-btn .followus {
	padding: 0 7px;
} */
.contact-btn svg {
	margin: 0 6px;
}
.contact-btn p {
	margin: 0px 10px 1px 1px;
}
.actionLink .allIcons .contact-btn:nth-child(2) {
	margin-top: 100px;
}
.Map_image img {
	mix-blend-mode: darken;
}

.actionLink .contactDv .contact-btn {
	transform: rotate(-90deg);
	background: #00b786e9;
	height: 75px;
	width: 54px;
	text-align: center;
	line-height: 60px;
	color: #515151;
	font-weight: 700;
	font-size: 16px;
	margin-bottom: 50px;
	border-radius: 15px 15px 0 0px;
	cursor: pointer;
	position: absolute;
	right: 230px;
	bottom: -138%;
	z-index: 9999;
	transition: 0.6s;
}
/* .actionLink .contactDv:hover .contact-btn {
	width: 155px;
	height: 60px;
	bottom: 0px;
} */
.actionLink .contactDv.newsletter .contact-btn {
	width: 130px;
	right: 80%;
	background: #000;
	color: #fff;
	top: 0;
}
/* .actionLink .contactDv .contact-btn:hover {
	bottom: 0;
	width: 150px;
	right: 77%;
} */
.actionLink .contactDv.newsletter .contact-btn {
	top: -30px;
}
.actionLink .contactDv.newsletter:hover .contact-btn {
	width: 100px;
	font-size: 13px;
	right: 90%;
	top: 80px;
}
.actionLink .contactDv .chatDv {
	background: #fbfbfb;
	padding: 20px 20px;
	width: 220px;
	border-radius: 5px;
	margin-bottom: -117px;
	position: relative;
	bottom: -130px;
}
/* .contactDv.newsletter .chatDv {
  width: 300px;
} */
.actionLink .contactDv .chatDv h3 {
	color: #0c0c0c;
	font-size: 15px;
	font-family: "Montserrat";
	font-weight: 600;
	margin-bottom: 10px;
}
.actionLink .contactDv .chatDv .form-control {
	border-radius: 0;
	color: #959292;
	font-family: "Montserrat";
	font-size: 10px;
	background: transparent;
	border: 0;
	border-bottom: 1px solid #707070c2;
	padding: 9px 0;
	overflow: unset;
	box-shadow: none;
}
.actionLink .contactDv .chatDv textarea {
	height: 70px;
	resize: none;
}
.actionLink .contactDv .chatDv button {
	background: #00b786e9;
	border: 0;
	font-family: "Montserrat";
	font-weight: 600;
	font-size: 12px;
	width: 100%;
	padding: 9px 0;
	border-radius: 5px;
}
.contactDv.newsletter .chatDv button {
	width: auto;
	padding: 10px 30px;
	border-radius: 0;
}
.chat-now {
	display: flex;
	align-items: center;
	cursor: pointer;
	bottom: unset;
	right: 5%;
	z-index: 3;
	position: absolute;
	margin-top: -90px;
}
.chat-now label {
	margin-right: 15px;
	margin-bottom: 0;
	font-family: "Montserrat";
	font-weight: 700;
	color: #515151;
}
.chat-now figure {
	margin: 0;
}

@media (max-width: 1024px) {
	.actionLink {
		display: none;
	}
}
