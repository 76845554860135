body {
	background-color: #f1efde;
	overflow-x: hidden;
}

@font-face {
	font-family: falmera;
	src: url("../fonts/Falmerapearson.otf");
}

.All_banner {
	background-image: url("../img/cloudBanner.png");
	background-size: cover;
	/* width: 100%;
	  height: 20vh; */
	background-repeat: no-repeat;
	background-size: 100% 100%;
}
.All_banner .main-cont {
	padding: 12px 0px;
}
.All_banner .main-title {
	color: #fff;
	font-size: 90px;
	font-family: "ZangeziSans07_TRIAL";
	text-align: center;
}

@media (max-width: 1100px) {
	.All_banner .main-title {
		font-size: 50px;
		padding: 30px 0px;
	}
}
.btn-container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.book-btn {
	color: #286152;
	background-color: #ffffff;
	border-radius: 36px;
	margin: 20px 0px;
	font-size: 18px;
	font-weight: 500;
	cursor: pointer;
}

.book-btn:hover {
	background: #000;
	color: #fff;
}

.book-btn:hover img {
	filter: brightness(0) invert(1);
}

.buttunset {
	padding: 7px 25px;
	width: 100%;
}

/* pagination css  */
.page-link {
	position: relative;
	display: block;
	padding: 0.5rem 0.75rem;
	margin-left: -1px;
	line-height: 1.25;
	color: #296253;
	background-color: #f1efde;
	border: 1px solid #bbbdbf;
	border-radius: 8px;
}

.page-item.disabled .page-link {
	color: #6c757d;
	pointer-events: none;
	cursor: auto;
	background-color: #f1efde;
	border: 1px solid #bbbdbf;
	border-radius: 8px;
}

.page-item.active .page-link {
	z-index: 1;
	color: #296253;
	background-color: #f1efde;
	border-color: #296253;
}

.page-item {
	padding: 4px;
}

/* pagination css  end */

/* Home contact form  */
.home-contact {
	padding: 80px 0px;
}

.home-contact .img-cont {
	overflow: hidden;
	height: 100%;
	width: 100%;
}
.home-contact .img-cont img {
	width: 100%;
}

.home-contact .shadow-title {
	font-size: 82px;
	font-weight: bold;
	color: #0000000f;
	margin-bottom: -18px;
	font-family: ZangeziSans07_TRIAL;
}

.home-contact .about-title {
	color: #286152;
	font-size: 50px;
	font-weight: bold;
	margin-bottom: 20px;
	font-family: ZangeziSans07_TRIAL;
}

.home-contact .para {
	font-size: 14px;
	font-family: "SF UI Display";
	color: #0000008c;
}
.home-contact input,
select {
	background: transparent;
	border: unset;
	border-bottom: 1px solid #bdb5b5;
	padding: 20px 10px;
	width: 85%;
	color: #838381 !important;
	outline: none;
}

.home-contact .checkbox-cont {
	display: flex;
}

.BOOKNOW {
	color: #fff;
	background: #286152;
	padding: 7px 16px;
	border-radius: 20px;
	text-transform: uppercase;
}

.BOOKNOW:hover {
	background: #000;
	color: #fff;
}

.home-contact .btn-cont {
	padding: 20px 10px;
}

.home-contact .check-container {
	display: flex;
	align-items: center;
	gap: 10px;
}

.home-contact .check-container .tick {
	width: auto !important;
}

.home-contact .check-container p {
	font-size: 14px;
	margin: 0px;
	color: #838381 !important;
}
/* Home contact form  End*/

.slick-slide img {
	display: block;
	width: 100%;
}

@media (max-width: 768px) {
	.page-item {
		padding: 9px;
	}
}

@media (max-width: 500px) {
	.home-contact .check-container p {
		font-size: 20px !important;
		margin: 0px;
		color: #838381 !important;
	}
}
