.bookingMember-page {
	padding: 80px 0px;
	background-image: url("../img/bgDesign.png");
	background-size: 100% 100%;
	background-attachment: fixed;
	background-size: 100%;
}

.bookingMember-page .main-Title {
	color: #286152;
	font-size: 50px;
	font-weight: bold;
	text-align: center;
	font-family: ZangeziSans07_TRIAL;
}

.bookingMember-page .main-para {
	font-size: 16px;
	color: #0000008f;
	font-family: SF UI Display;
	text-align: center;
	margin-bottom: 35px;
}

.bookingMember-page .member-card {
	height: auto;
	width: 100%;
	background: #296253;
	border-radius: 12px;
	overflow: hidden;
	margin: 22px 0px;
}

.bookingMember-page .member-card img {
	width: 100%;
}

.bookingMember-page .member-card .title {
	text-align: center;
	color: #fff;
	font-size: 20px;
	font-family: "SF UI Display";
}

@media (max-width: 768px) {
	.bookingMember-page {
		background: none;
	}
}
@media (max-width: 787px) {
	.bookingMember-page .main-Title {
		font-size: 33px;
	}
}

@media (max-width: 320px) {
	.bookingMember-page .main-Title {
		font-size: 19px;
	}
}
