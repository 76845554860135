.login {
	background-image: url("../img/logBg.png");
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	height: 91vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.login .login-card {
	/* height: 68%; */
	width: 35%;
	background: #fff;
	padding: 38px 65px;
}

.login .main-title-cont {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}

.login .hr-line {
	width: 25%;
	background-color: #286152 !important;
	border-color: #286152;
	border-top: #286152;
	height: 3px;
}

.login .main-title {
	color: #286152;
	font-size: 45px;
	font-weight: bold;
	text-align: center;
	font-family: ZangeziSans07_TRIAL;
	margin: 0px 10px;
}
.login .inner-title {
	font-size: 28px;
	text-align: center;
	font-family: "SF UI Display";
	color: #000;
	margin: 0px;
}

.login .inner-title span {
	font-weight: bold;
}

.login .form-cont {
}

.login .input-custom {
	width: 100%;
	border-radius: 12px;
	padding: 8px 10px;
	border: 1px solid #bdb8b8;
}

.login .link-box {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 0px;
}

.login .loginBtn {
	color: #fff;
	background-color: #286152;
	border-radius: 36px;
	padding: 7px 32px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.login .loginBtn:hover {
	background: #000;
	color: #fff;
}

.login .rem-cont {
	display: flex;
	justify-content: center;
	align-items: center;
}

.login .rem-text {
	margin: 0;
	margin-left: 10px;
	font-size: 14px;
	color: #636060;
	font-family: "SF UI Display";
}

.login .regs-cont {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 22px;
}

.login .regs-text {
	color: #000;
	font-size: 13px;
	font-family: "SF UI Display";
	margin: 3px;
}

.login .regs-text a {
	color: #286152;
	font-size: 13px;
	font-family: "SF UI Display";
	font-weight: bold;
	margin: 3px;
}

.login label {
	font-size: 15px;
	color: #2a263d;
	font-family: "SF UI Display";
	font-weight: 600;
}

.login .forget-title {
	font-size: 22px;
	text-align: center;
	font-family: "SF UI Display";
	color: #000;
}

.linkSetOtp span {
	font-size: 15px;
	color: #2a263d;
	font-family: "SF UI Display";
	font-weight: 600;
	letter-spacing: 0.6px;
}
.linkSetOtp span a {
	color: #286152;
	letter-spacing: 0.6px;
}

.password_tilte {
	font-size: 35px !important;
}

.password-title2 {
	font-size: 12px !important;
	letter-spacing: 0.6px;
}
.password-alert {
	font-size: 12px !important;
	color: rgba(128, 128, 128, 0.822) !important;
}

.change_password_first {
	position: relative;
	margin-bottom: 30px;
}

.change_password_first span {
	position: absolute;
	top: 80px;
	left: 7px;
}

@media (max-width: 1374px) {
	.login .main-title {
		font-size: 35px;
	}
}

@media (max-width: 1239px) {
	.login .main-title {
		font-size: 32px;
	}
}

@media (max-width: 1149px) {
	.login .main-title {
		font-size: 30px;
	}
}

@media (max-width: 1100px) {
	.login .login-card {
		width: 85%;
	}
	.login .login-card {
		padding: 38px 15px;
	}
	.login .login-card {
		width: 95%;
	}
}

@media (max-width: 1024px) {
	.login .loginBtn {
		margin: 0 auto;
		width: 25%;
	}
}
@media (max-width: 414px) {
	.login .loginBtn {
		width: 70%;
	}
}

@media (max-width: 375px) {
	.login .loginBtn {
		width: 70%;
	}
}
