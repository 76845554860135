.policy-page {
	padding: 80px 0px;
	background-image: url("../img/bgDesign.png");
	background-size: 100% 100%;
	background-attachment: fixed;
	background-size: 100%;
}

.policy-page .main-title {
	color: #286152;
	font-size: 50px;
	font-weight: bold;
	text-align: center;
	margin-bottom: 12px;
	font-family: ZangeziSans07_TRIAL;
}

.policy-page .para {
	font-size: 16px;
	color: #0000008c;
	font-family: "SF UI Display";
	/* text-align: center; */
	padding: 0px 106px;
}

@media (max-width: 768px) {
	.policy-page {
		background: none;
		padding: 37px 0px;
	}
	.policy-page .main-Title {
		font-size: 17px;
		margin-bottom: 22px;
	}
	.policy-page .para {
		font-size: 13px;
		padding: 0px 33px;
	}
}

@media (max-width: 324px) {
	.policy-page .main-Title {
		font-size: 36px;
		padding: 37px 0px;
	}
	.policy-page .main-Title {
		font-size: 19px;
		margin-bottom: 22px;
	}
	.policy-page .para {
		font-size: 13px;
		padding: 0px 33px;
	}
}

@media (max-width: 320px) {
	.policy-page .para {
		font-size: 13px;
		text-align: justify;
	}
}
