.Camping-page {
	padding: 80px 0px;
	background-image: url("../img/bgDesign.png");
	background-size: 100% 100%;
	background-attachment: fixed;
	background-size: 100%;
}

.Camping-page .main-Title {
	color: #286152;
	font-size: 44px;
	font-weight: bold;
	text-align: center;
	font-family: ZangeziSans07_TRIAL;
}

.Camping-page .main-para {
	font-size: 16px;
	color: #0000008f;
	font-family: SF UI Display;
	text-align: center;
	margin-bottom: 35px;
	max-width: 65%;
	margin: 0 auto 35px;
}

.Camping-page .formContainer {
	padding: 20px 170px;
}

.Camping-page .formContainer .sub-title {
	font-size: 24px;
	color: #2a263d;
	font-family: "SF UI Display";
	font-weight: 500;
	margin: 0;
	margin-bottom: 22px;
	margin-top: 22px;
}

.Camping-page .formContainer .sub-title-sm {
	font-size: 15px;
	color: #2a263d85;
	font-family: "SF UI Display";
	margin: 0;
	margin-top: 28px;
}

.Camping-page .formContainer input,
.Camping-page .formContainer select {
	width: 100%;
	background-color: #f1efde;
	border: unset;
	padding: 4px 8px;
	border-bottom: 1px solid #c3c3c3;
	padding-top: 20px;
}

.Camping-page button {
	color: #fff;
	background-color: #286152;
	border-radius: 36px;
	padding: 7px 25px;
}

.Camping-page button:hover {
	background-color: #000000;
	color: #fff;
}

.Camping-page .formContainer .card-type {
	display: flex;
	max-width: 50%;
}

.centerRadioBtn {
	display: flex;
	justify-content: center;
	align-items: center;
}

.GardenTour-page
	.formContainer
	.card-type
	input:checked
	~ span.checkmark
	figure {
	box-shadow: 0px 0px 0px 1px #fdae25;
}
.Camping-page .formContainer .card-type span.checkmark figure {
	height: 35px;
	line-height: 35px;
	text-align: center;
	margin-right: 10px;
	width: 75px;
	overflow: hidden;
}

.priceRate img {
	width: 15px;
	margin-top: -7px;
	margin-right: -3px;
}

@media (min-width: 1280px) {
	.Camping-page button:nth-child(2) {
		margin-left: 10px !important;
	}
}

@media (max-width: 1200px) {
	.camping_btn {
		display: flex;
		gap: 10px;
		flex-direction: column;
	}
}

@media (max-width: 768px) {
	.Camping-page {
		background: none;
	}
	.Camping-page .main-Title {
		font-size: 33px;
	}
	.Camping-page .main-para {
		margin: 0 auto 0px;
	}
	.Camping-page .formContainer {
		padding: 18px 71px;
	}
	.Camping-page .formContainer .sub-title {
		margin-left: -17px;
	}
	.camping_btn {
		display: flex;
		gap: 10px;
		flex-direction: column;
	}
	.camping_btn button.btn {
		width: 100%;
	}
	.Camping-page .main-para {
		max-width: 98%;
	}
}

@media (max-width: 633px) {
	.Book_range_center .priceRate {
		margin-bottom: 8px;
	}
	.Book_range_center {
		padding: 0px;
	}
	.Camping-page .formContainer {
		padding-left: 15px;
	}
	.Camping-page .main-Title {
		font-size: 33px;
	}
}

@media (max-width: 500px) {
	.Camping-page .formContainer {
		padding-left: 15px;
	}
	.Camping-page .formContainer {
		padding: 18px 25px !important;
	}
	.Camping-page .main-Title {
		font-size: 32px;
	}
	.priceRate img {
		width: 17px;
		margin-top: -7px;
		margin-right: -5px;
	}
}

@media (max-width: 480px) {
	.Camping-page .main-Title {
		font-size: 25px;
	}
}

@media (max-width: 429px) {
	.Camping-page .main-Title {
		font-size: 26px;
	}
}

@media (max-width: 429px) {
	.Camping-page .main-Title {
		font-size: 21px;
	}
}

@media (max-width: 414px) {
	.Book_range_center .priceRate {
		margin-bottom: -5px;
	}
}

@media (max-width: 390px) {
	.Camping-page .main-Title {
		font-size: 20px;
	}
	.priceRate {
		font-size: 23px;
	}
	.priceRate img {
		width: 8px;
	}
}

@media (max-width: 375px) {
	.Book_range_center .priceRate {
		margin-bottom: -9px;
	}
	.Camping-page .main-Title {
		font-size: 18px;
	}
}

@media (max-width: 360px) {
	.Camping-page .main-Title {
		font-size: 17px;
	}
	.priceRate {
		font-size: 19px;
	}
	.priceRate img {
		width: 11px;
		margin-top: -7px;
		margin-right: -3px;
	}
}
