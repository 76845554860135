.checkout-page {
	padding-bottom: 80px;
}
.checkout-page .container {
	padding: 60px 40px;
}
.checkout-page .head {
	text-align: center;
	padding-bottom: 60px;
}
.checkout-page .head h1 {
	color: #13adc8;
}
.checkout-page h3 {
	font-weight: bold;
	font-size: 20px;
	color: #262626;
	font-family: "SF UI Display";
}
.margin-est {
	margin-left: 8px;
}
/* First Column Css Start Here */
.checkout-page .first-top-bar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 40px;
}
.checkout-page .first-top-bar h3 {
	margin-bottom: 0;
}
.checkout-page .first-top-bar p {
	font-size: 15px;
	color: #000;
	font-weight: 500;
	/* opacity: 0; */
}
.second-top-bar .title {
	padding-bottom: 60px !important;
}
.checkout-page .first-top-bar p a {
	text-decoration: none;
	transition: 0.6s;
}
.checkout-page .first-top-bar p a:hover {
	color: #1aac7a;
}
.checkout-page .first-top-bar p a {
	font-weight: 500;
	color: #000;
}
.checkout-page label {
	font-size: 15px;
	font-weight: 500;
	color: #2a263d;
}
.checkout-page select {
	border-radius: 0;
	height: 50px !important;
	border-color: #70707059;
	font-weight: 500;
	font-size: 14px;
}
/* Saved Address Css Start Here */
.checkout-page .saved-address label.container .select-address .selection {
	position: absolute;
	right: 0;
	top: 0;
	padding: 10px 20px;
	opacity: 0;
}
.checkout-page
	.saved-address
	label.container
	.select-address
	.selection
	i.fa.fa-check {
	background: #0eb702;
	color: #fff;
	font-size: 14px;
	width: 25px;
	height: 25px;
	text-align: center;
	line-height: 25px;
	border-radius: 50%;
}
.checkout-page
	.saved-address
	label.container
	input:checked
	~ span.checkmark
	.select-address
	.selection {
	opacity: 1;
}
.checkout-page .saved-address .title {
	padding-bottom: 25px;
}
.checkout-page .saved-address .title h5 {
	font-size: 15px;
}
/* The container */
.checkout-page .saved-address .container {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
/* Hide the browser's default radio button */
.checkout-page .saved-address .container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}
.checkout-page .saved-address span.checkmark {
	position: unset;
}
.checkout-page .saved-address label.container {
	padding: 0;
}
.checkout-page .saved-address label.container .select-address {
	border: 2px solid #b5b5b5;
	padding: 20px 30px;
	position: relative;
	overflow: hidden;
	border-radius: 10px;
}
.checkout-page
	.saved-address
	label.container
	input:checked
	~ span.checkmark
	.select-address {
	box-shadow: 0px 0px 0px 2px #286152;
	border-color: #286152;
	border-radius: 10px;
}
.checkout-page .saved-address .select-address .radio-btn span {
	width: 15px;
	height: 15px;
	background: #fff;
	position: absolute;
	left: 0;
	border-radius: 50%;
	border: 2px solid #fff;
	box-shadow: 0px 0px 0px 1px #1aac7a;
	top: 10px;
	margin: 20px 20px;
}
.checkout-page
	.saved-address
	label.container
	input:checked
	~ span.checkmark
	.select-address
	.radio-btn
	span {
	background: #1aac7a;
}
.checkout-page .saved-address .select-address {
	position: relative;
}
.checkout-page .saved-address .select-address .address-detail {
	padding-left: 20px;
	margin-left: 30px;
	border-left: 1px solid #7070705c;
}
.checkout-page .saved-address .select-address .address-detail h5 {
	width: 100%;
	padding: 4px 0;
}
.checkout-page .saved-address .select-address .address-detail h5 span.property {
	font-size: 16px;
	color: #00000096;
	font-weight: 100;
}
.checkout-page .saved-address .select-address .address-detail h5 span.value {
	margin-left: 20px;
	font-weight: 500;
	color: #000000;
	font-size: 14px;
}
.checkout-page .saved-address .select-address .address-detail button.btn {
	background: #e7e7e7;
	color: #7d7d7d;
	font-size: 15px;
	font-weight: 500;
	text-transform: capitalize;
	opacity: 1;
	position: absolute;
	right: 20px;
	bottom: 20px;
}
.checkout-page .add-new-address {
	padding-top: 20px;
	position: relative;
}
.checkout-page .add-new-address label.container {
	padding: 0 25px 20px;
	font-size: 15px;
	color: #262626;
	font-weight: 700;
}
.checkout-page .add-new-address label.container span.checkmark {
	background: #fff;
	width: 15px;
	height: 15px;
	position: absolute;
	left: 0;
	border-radius: 50%;
	border: 2px solid #fff;
	box-shadow: 0px 0px 0px 1px #1aac7a;
	top: 4.5px;
}
.checkout-page .add-new-address label.container input {
	position: absolute;
	left: 0px;
	opacity: 0;
}
.checkout-page .add-new-address label.container input:checked ~ span.checkmark {
	background: #1aac7a;
}
.checkout-page .form-check label.form-check-label {
	font-family: "SF UI Display";
	font-weight: bold;
	color: #262626;
	font-size: 18px;
}
.checkout-page .add-new-address .form-group label {
	font-size: 15px;
	font-weight: 500;
	color: #2a263d;
	font-family: "SF UI Display";
}
.checkout-page .add-new-address .form-group .form-control {
	border-color: #b7b4b494 !important;
	border: 1px solid;
	background-color: #f1efde;
	border-radius: 28px;
	padding: 10px 24px;
}
.checkout-page .add-new-address .checkbox {
	display: flex;
	align-items: center;
}
.checkout-page .add-new-address .checkbox label {
	margin-bottom: 0;
	color: #636060;
	font-size: 15px;
	padding-left: 15px;
}
/* Saved Address Css End Here */

/* Saved Payment Css Start Here */
.checkout-page .payment-details {
	padding-top: 30px;
}
.checkout-page .payment-details .heading {
	padding-bottom: 10px;
}

.checkout-page .payment-details .saved-payment-method h5 {
	padding-bottom: 20px;
	font-size: 15px;
	color: #262626;
	margin-top: 20px;
	margin-bottom: 0px;
}
.checkout-page .payment-details .saved-payment-method label.container {
	padding: 0;
}
.checkout-page
	.payment-details
	.saved-payment-method
	label.container
	span.checkmark
	.card-details {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 1px solid #70707099;
	padding: 10px 20px;
	position: relative;
}
.checkout-page
	.payment-details
	.saved-payment-method
	label.container
	input:checked
	~ span.checkmark
	.card-details {
	box-shadow: 0px 0px 0px 2px #286152;
	border-color: #fff;
}
.checkout-page
	.payment-details
	.saved-payment-method
	label.container
	span.checkmark
	.card-details
	.visa-card {
	display: flex;
	align-items: center;
	padding-left: 40px;
}
.checkout-page
	.payment-details
	.saved-payment-method
	label.container
	span.checkmark
	.card-details
	.visa-card
	figure {
	margin-bottom: 0;
}

.checkout-page
	.payment-details
	.saved-payment-method
	label.container
	span.checkmark
	.card-details
	.visa-card
	.input-box {
	margin: 0px 20px;
}
.checkout-page
	.payment-details
	.saved-payment-method
	label.container
	span.checkmark
	.card-details
	.visa-card
	.input-box
	label {
	color: #000000;
	font-size: 8px;
	font-weight: 600;
}
.checkout-page
	.payment-details
	.saved-payment-method
	label.container
	span.checkmark
	.card-details
	.visa-card
	.card-number-input {
	border: 0;
	height: unset;
	font-size: 18px;
	color: #343a40;
	font-weight: 600;
}
.checkout-page
	.payment-details
	.saved-payment-method
	label.container
	span.checkmark
	.card-details
	.extra-btn {
	display: flex;
	align-items: center;
}
.checkout-page
	.payment-details
	.saved-payment-method
	label.container
	span.checkmark
	.card-details
	.extra-btn
	button.btn {
	margin-right: 20px;
	background: #e7e7e7;
	color: #7d7d7d;
	font-size: 15px;
	font-weight: 500;
	text-transform: capitalize;
	opacity: 1;
	margin-left: -43px;
}
.checkout-page
	.payment-details
	.saved-payment-method
	label.container.second-card
	button.btn.default.disabled {
	visibility: hidden;
}
.checkout-page
	.payment-details
	.saved-payment-method
	label.container
	span.checkmark
	.card-details
	.extra-btn
	.selection {
	opacity: 0;
}
.checkout-page
	.payment-details
	.saved-payment-method
	label.container
	input:checked
	~ span.checkmark
	.card-details
	.extra-btn
	.selection {
	opacity: 1;
}
.checkout-page
	.payment-details
	.saved-payment-method
	label.container
	span.checkmark
	.card-details
	.extra-btn
	.selection
	i {
	background: #0eb702;
	color: #fff;
	font-size: 14px;
	width: 25px;
	height: 25px;
	text-align: center;
	line-height: 25px;
	border-radius: 50%;
}
.checkout-page
	.payment-details
	.saved-payment-method
	label.container
	input[type="radio"] {
	opacity: 0;
}
.checkout-page
	.payment-details
	.saved-payment-method
	label.container
	span.checkmark
	.card-details
	.radio-btn
	span {
	background: #fff;
	width: 15px;
	height: 15px;
	position: absolute;
	left: 15px;
	border-radius: 50%;
	border: 2px solid #fff;
	box-shadow: 0px 0px 0px 1px #1aac7a;
	top: 27.5px;
}
.checkout-page
	.payment-details
	.saved-payment-method
	label.container
	input:checked
	~ span.checkmark
	.card-details
	.radio-btn
	span {
	background: #a0d757;
}
.checkout-page-page .add-new-address label.container span.checkmark {
	/* background: #fff;
    width: 15px;
    height: 15px;
    position: absolute;
    left: 0;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0px 0px 0px 1px #f35d63;
    top: 24.5px; */
}
.checkout-page
	.payment-details
	.saved-payment-method
	.add-new-method
	label.container
	span.checkmark {
	border: 2px solid #fff;
	width: 15px;
	height: 15px;
	background: #fff;
	position: absolute;
	left: 0;
	border-radius: 50%;
	box-shadow: 0px 0px 0px 1px #1aac7a;
	top: 5px;
}
.checkout-page
	.payment-details
	.saved-payment-method
	.add-new-method
	input::placeholder {
	font-family: "GT Walsheim Pro regular";
	color: #2a263d;
}
/* label.anp_label {
    cursor: pointer;
  } */
/* .form-group #exampleCheckanp {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  } */
/* check box ssd start here */
.form-check label.label_ssd {
	position: relative;
	cursor: pointer;
}

.form-check label.label_ssd:before {
	/* content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #636060;
    box-shadow: 0 1px 2px rgb(0 0 0 / 5%), inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
    padding: 7px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    left: -19px; */
}

.form-check #exampleCheckssd:checked + .label_ssd::after {
	content: "";
	display: block;
	position: absolute;
	top: 4px;
	left: 6px;
	width: 6px;
	height: 13px;
	border: solid #1aac7a;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
}

#exampleCheckssd {
	opacity: 0;
	position: absolute;
	z-index: -1;
}
/* check box ssd end here */
/* check box ana start here */
.form-check label.label_ana {
	position: relative;
	cursor: pointer;
}

.form-check label.label_ana:before {
	/* content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #636060;
    box-shadow: 0 1px 2px rgb(0 0 0 / 5%), inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
    padding: 7px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
    left: -19px; */
}

.form-check #exampleCheckana:checked + .label_ana::after {
	content: "";
	display: block;
	position: absolute;
	top: 4px;
	left: 6px;
	width: 6px;
	height: 13px;
	border: solid #1aac7a;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
}

#exampleCheckana {
	opacity: 0;
	position: absolute;
	z-index: -1;
}
/* check box ana end here */
/* check box start here */
.checkbox label.for_checkbox_label {
	position: relative;
	cursor: pointer;
}

.checkbox label.for_checkbox_label:before {
	content: "";
	-webkit-appearance: none;
	background-color: transparent;
	border: 1px solid #636060;
	box-shadow: 0 1px 2px rgb(0 0 0 / 5%),
		inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
	padding: 7px;
	display: inline-block;
	position: relative;
	vertical-align: middle;
	cursor: pointer;
	margin-right: 5px;
	left: -19px;
}

.checkbox #for_checkbox:checked + .for_checkbox_label::after {
	content: "";
	display: block;
	position: absolute;
	top: 4px;
	left: 6px;
	width: 6px;
	height: 13px;
	border: solid #1aac7a;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
}

#for_checkbox {
	opacity: 0;
	position: absolute;
	z-index: -1;
}
/* check box end here */

.form-check label.anp_label {
	position: relative;
	cursor: pointer;
}

.form-check label.anp_label:before {
	content: "";
	-webkit-appearance: none;
	background-color: transparent;
	border: 1px solid #636060;
	box-shadow: 0 1px 2px rgb(0 0 0 / 5%),
		inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
	padding: 7px;
	display: inline-block;
	position: relative;
	vertical-align: middle;
	cursor: pointer;
	margin-right: 5px;
	left: -19px;
}

.form-check #exampleCheckanp:checked + label.anp_label:after {
	content: "";
	display: block;
	position: absolute;
	top: 4px;
	left: 6px;
	width: 6px;
	height: 13px;
	border: solid #1aac7a;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
}

#exampleCheckanp {
	opacity: 0;
	position: absolute;
	z-index: -1;
}
.checkout-page .payment-details .saved-payment-method .add-new-method {
	position: relative;
}
.checkout-page
	.payment-details
	.saved-payment-method
	.add-new-method
	label.container {
	padding-left: 30px;
	font-size: 15px;
	color: #262626;
	font-weight: 700;
	padding-bottom: 15px;
}
.checkout-page .payment-details .saved-payment-method .add-new-method {
	padding-top: 10px;
}
.checkout-page
	.payment-details
	.saved-payment-method
	.add-new-method
	label.container
	input:checked
	~ span.checkmark {
	background: #1aac7a;
}
.checkout-page
	.payment-details
	.saved-payment-method
	.add-new-method
	.form-group
	.form-control {
	border-radius: 0;
	height: 50px;
	border-color: #70707059;
}
.checkout-page .payment-details .saved-payment-method .add-new-method button {
	width: 100%;
	padding: 15px 0;
	cursor: pointer;
	background: #1aac7a;
	color: #fff;
	font-size: 18px;
	text-transform: uppercase;
	font-weight: 600;
	border: 2px solid #fff;
	transition: 0.6s;
}
.checkout-page
	.payment-details
	.saved-payment-method
	.add-new-method
	button:hover {
	box-shadow: 0px 0px 0px 2px #1aac7a;
}
/* Saved Payment Css Start Here */

/* First Column Css End Here */

/* Second Column Css Start Here */
.checkout-page .second-top-bar .form-group {
	display: flex;
	align-items: flex-start;
}
.checkout-page .second-top-bar .form-group .form-control {
	border: 1px solid #b4b4b4;
	padding: 16px 20px;
	height: 45px;
	border-radius: 28px;
	background-color: #f1efde;
}
.checkout-page .second-top-bar .form-group .form-control::placeholder {
	color: #2a263d;
	font-family: "Montserrat";
	font-weight: 500;
}
.checkout-page .second-top-bar .form-group button {
	height: 45px;
	width: 50%;
	margin-left: 15px;
	background-color: #286152;
	/* background-image: linear-gradient(to right, #A2D858 , #51A851); */
	color: #ffffff;
	font-size: 18px;
	font-weight: 500;
	cursor: pointer;
	border: 1px solid #286152;
	transition: 0.6s;
	border-radius: 28px;
	padding: 8px 15px;
}
.checkout-page .second-top-bar .form-group button:hover {
	box-shadow: 0px 0px 0px 2px #1aac7a;
}
.checkout-page .cart-product-detail {
	border: 1px solid #b4b4b4;
	padding: 50px 50px;
	position: relative;
	overflow: hidden;
}
.checkout-page .cart-product-detail .checkout-page-products {
	height: 280px;
	overflow-y: scroll;
	overflow-x: hidden;
}
.checkout-page .cart-product-detail .checkout-page-products::-webkit-scrollbar {
	width: 0;
	background: transparent;
}
.checkout-page .cart-product-detail .products {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #70707073;
	padding: 18px 0;
	position: relative;
}
.checkout-page .cart-product-detail .br-right:before {
	content: "";
	background: #1aac7a;
	width: 4px;
	height: 60%;
	position: absolute;
	display: flex;
	align-items: center;
	right: -20px;
}
.checkout-page .cart-product-detail .products .abt-product {
	display: flex;
	align-items: center;
}
.checkout-page .cart-product-detail .products .abt-product .pro-dtl {
	padding-left: 15px;
}
.checkout-page .cart-product-detail .products .abt-product .pro-dtl h5.name {
	font-size: 16px;
	font-weight: 500;
	color: #000;
	font-family: "GT Walsheim Pro regular";
	font-weight: 400;
	margin-bottom: 0;
}
.checkout-page
	.cart-product-detail
	.products
	.abt-product
	.pro-dtl
	ul.variations {
	margin-bottom: 0;
	padding: 0;
	list-style: none;
	display: flex;
	align-items: center;
}
.checkout-page .cart-product-detail .products .img-box figure {
	margin-bottom: 0;
}
.checkout-page
	.cart-product-detail
	.products
	.abt-product
	.pro-dtl
	ul.variations
	li {
	border-right: 1px solid #1f2c3582;
	padding: 0 7px;
	font-weight: 700;
	color: #000;
}
.checkout-page
	.cart-product-detail
	.products
	.abt-product
	.pro-dtl
	ul.variations
	li:last-child {
	border: 0;
}
.checkout-page
	.cart-product-detail
	.products
	.abt-product
	.pro-dtl
	ul.variations
	li:first-child {
	padding-left: 0;
	color: #6d6d6d;
	font-size: 13px;
	font-weight: 400;
}
.checkout-page .cart-product-detail .products .product-price h4.price {
	color: #25544a;
	font-size: 16px;
	font-weight: 400;
	font-family: "GT Walsheim Pro regular";
	margin-left: 24px;
	display: inline-block;
}
.checkout-page .cart-product-detail .pricing-details {
	padding-top: 40px;
}
.checkout-page .cart-product-detail .pricing-details .item-total h4.property {
	font-size: 17px;
	color: #000000;
	font-weight: 500;
	font-family: "SF UI Display";
}
.checkout-page .cart-product-detail .pricing-details .item-total h4.value {
	font-size: 18px;
	color: #000000;
	font-weight: 400;
	font-family: "SF UI Display";
}
.checkout-page .cart-product-detail .pricing-details .item-total {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px 0;
}
.checkout-page .cart-product-detail .pricing-details {
	border-bottom: 1px solid #c5c5c5;
	padding-bottom: 30px;
}
.checkout-page .cart-product-detail .order-total {
	padding-top: 30px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.checkout-page .cart-product-detail .order-total h3.property {
	color: #25544a;
	font-weight: 600;
	font-size: 20px;
	font-family: "GT Walsheim Pro";
}
.checkout-page .cart-product-detail .order-total h3.value {
	font-weight: 400;
	font-family: "GT Walsheim Pro";
	font-size: 18px;
}
/* Second Column Css End Here */
.checkout-page .border-last {
	background: #7070705c;
	padding: 0.5px;
	margin: 40px 0;
}
.checkout-page .confirm-orderDv {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.checkout-page .confirm-orderDv .checkbox {
	display: flex;
	align-items: center;
}
.checkout-page .confirm-orderDv .checkbox label {
	margin-bottom: 0;
	padding-left: 20px;
	font-size: 17px;
	color: #000000;
	font-weight: 400;
	font-family: "Montserrat";
}
.checkout-page .confirm-orderDv .button-group button {
	width: 100%;
	padding: 12px 80px;
	cursor: pointer;
	background: #286152;
	/* background-image: linear-gradient(to right, #A2D858 , #51A851); */
	color: #fff;
	font-size: 18px;
	text-transform: uppercase;
	font-weight: 600;
	border: 0;
	transition: 0.6s;
	border: 2px solid #286152;
	border-radius: 28px;
}
.checkout-page .confirm-orderDv .button-group button:hover {
	box-shadow: 0px 0px 0px 2px #1aac7a;
}
.add-new-method .form-check-input {
	top: 17px !important;
}

.checkout-page .checkout-products {
	height: 200px;
	overflow-y: scroll;
	padding-right: 1rem;
	overflow-x: hidden;
}
.checkout-page .checkout-products::-webkit-scrollbar-track {
	/* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
	background-color: transparent;
}

.checkout-page .checkout-products::-webkit-scrollbar {
	width: 2px;
	background-color: transparent;
}

.checkout-page .checkout-products::-webkit-scrollbar-thumb {
	background-color: #296253;
	border: 2px solid transparent;
}

.checkout-page ::-webkit-scrollbar {
	width: 10px;
}

/* Track */
.checkout-page ::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
.checkout-page ::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
.checkout-page ::-webkit-scrollbar-thumb:hover {
	background: #555;
}
/* Checkout Css Start Here */
/* Media Query Start Here */
@media (max-width: 1100px) {
	.checkout-page .confirm-orderDv .button-group button {
		padding: 12px 20px;
	}
}
@media (max-width: 768px) {
	.checkout-page .confirm-orderDv .button-group button {
		width: 220px;
		padding: 12px 0px;
		font-size: 12px;
	}
	.checkout-page .payment-details .saved-payment-method .add-new-method {
		margin-bottom: 40px;
	}
	.checkout-page .container {
		padding: 30px 10px 0px 10px;
	}
	.container .title {
		padding: 15px 0 20px 0 !important;
	}
}
@media (max-width: 575px) {
	.checkout-page .saved-address label.container .radio-btn {
		display: none;
	}
	.checkout-page .saved-address .select-address .address-detail {
		padding-left: 0px;
		margin-left: 0px;
		border-left: 0;
	}
	.checkout-page .saved-address .select-address .address-detail button.btn {
		position: unset;
	}
	.checkout-page .confirm-orderDv {
		display: block;
	}
	.checkout-page .confirm-orderDv .checkbox {
		margin-bottom: 20px;
	}
	.checkout-page .cart-product-detail {
		padding: 50px 20px;
	}
}
@media (max-width: 500px) {
	.checkout-page .first-top-bar {
		display: block;
		text-align: center;
	}
	.checkout-page .saved-address .select-address .address-detail h5 span.value {
		font-size: 11px;
	}
	.checkout-page
		.saved-address
		.select-address
		.address-detail
		h5
		span.property {
		font-size: 12px;
		font-weight: 500;
	}
	.checkout-page .saved-address label.container .select-address {
		padding: 20px 10px;
	}
}
@media (max-width: 400px) {
	.checkout-page
		.payment-details
		.saved-payment-method
		label.container
		span.checkmark
		.card-details
		.extra-btn
		button.btn {
		display: none;
	}
}

.confirm-orderDv .form-control {
	width: auto !important;
}

.inner-anchor span {
	color: #ffffff;
}

.checkout-page .cart-product-detail .checkout-page-products::-webkit-scrollbar {
	width: 3px;
	background: transparent;
}

.checkout-page
	.cart-product-detail
	.checkout-page-products::-webkit-scrollbar-thumb {
	background: #1aac7a;
}
.form-check-input {
	position: absolute;
	margin-top: 0.25rem;
	margin-left: -1.25rem;
	left: 16px;
	top: 2px;
}
.form-check-input.ana {
	margin-top: 1.25rem;
}
.second-top-bar .form-check-input {
	position: absolute;
	margin-top: 0.25rem;
	margin-left: -1.25rem;
	left: 16px;
	top: 15px;
}
/* responsive starts here */
@media (max-width: 414px) {
	.checkout-page .cart-product-detail .products .product-price h4.price {
		margin-right: 10px;
	}
	.checkout-page .cart-product-detail .products .abt-product .pro-dtl h5.name {
		font-size: 13px;
	}
}
@media (max-width: 375px) {
	.product-price {
		margin-top: 20px;
		display: block;
	}
}
/* responsive ends here */

.second-top-bar input.form-control.usd {
	background: #fff9f6;
	border: 0px !important;
	font-size: 24px;
	font-family: "GT Walsheim Pro regular";
	font-weight: bold;
	color: #1aac7a;
	height: 59px;
	margin-top: 14px;
}
.abt-product .img-box img {
	border-radius: 6px;
	height: 44px;
	width: 44px;
	object-fit: cover;
}
