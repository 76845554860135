.product-details {
	padding: 80px 0px;
	background-image: url("../img/bgDesign.png");
	background-size: 100% 100%;
	background-attachment: fixed;
	background-size: 100%;
}

.product-details .similar-title {
	font-size: 30px;
	color: #286152;
	font-weight: bold;
	font-family: "ZangeziSans07_TRIAL";
	margin-bottom: 52px;
}

.product-details .content {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 18px 0px;
}

.BorderCard {
	border: 2px solid #28615273;
	padding: 20px;
	/* height: 270px; */
	margin-bottom: 24px;
	min-height: 270px;
}
.product-details .sizeSelect.amountselectdiv {
	width: 100px;
}
.map_adjust {
	margin-top: 65px;
}

.product-details .content .title,
.title2 {
	font-size: 14px;
	font-family: "SF UI Display";
	color: #000000c7;
}

.border_product_detail {
	border-left: 1px solid #707070ce;
	margin-bottom: 20px;
}

.product-details .map-title {
	font-size: 35px;
	font-family: "SF UI Display";
	color: #000000c7;
	font-weight: bold;
}

.product-details .rating {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.product-details .productName {
	font-size: 28px;
	font-family: "SF UI Display";
	color: #000000d4;
}

.product-details .maples {
	margin: 0;
	font-size: 20px;
	color: #00000057;
	font-family: "SF UI Display";
}

.product-details .desc-title {
	font-size: 20px;
	color: #464646;
	font-family: "SF UI Display";
	font-weight: bold;
	margin-bottom: 0;
}
.product-details .productDesc {
	font-size: 16px;
	font-family: "SF UI Display";
	color: #00000073;
}

.product-details .price-heart {
	display: flex;
	justify-content: space-between;
}

.product-details .price {
	font-size: 18px;
	font-family: Poppins-SemiBold;
	color: #ffffff;
	margin: 8px;
}
.product-details .price {
	font-size: 18px;
	font-family: Poppins-SemiBold;
	color: #d60012;
	margin: 8px;
}
.product-details .sizeBtn:hover {
	padding: 8px 28px;
	background: #296253;
	color: #fff;
	font-weight: 500;
	font-size: 18px;
	border: unset;
	transition: 0.6s;
	border-radius: 22px;
	text-transform: uppercase;
}

.product-details .sizeBtn:hover .heartIcon,
.product-details .sizeBtn:hover .cartIcon {
	color: white !important;
}

/* .product-details .AddBtn {
	background: #fff;
	color: #000;
	border: unset;
} */

.product-details .sizeBtn {
	padding: 8px 28px;
	background: transparent;
	color: #296253;
	font-weight: 500;
	font-size: 18px;
	border: unset;
	transition: 0.6s;
	border: 1px solid #296253;
	margin-left: 8px;
	border-radius: 22px;
	text-transform: uppercase;
}
/* .product-details .sizeBtn:hover {
	background: #fff;
	color: #000;
	border: 1px solid rgb(10, 10, 10);
} */

.product-details .tags-right {
	float: right;
	width: 80%;
	background-color: #296253;
	color: #fff;
	padding: 10px 25px;
	border-radius: 8px;
}

.product-details .tags-right p {
	margin: 0;
}

.product-details .video-cont {
	width: 100%;
	height: 100%;
	overflow: hidden;
	padding: 25px 0px;
}

.product-details .video-cont img {
	width: 100%;
	height: 100%;
}

.product-details .list {
	display: flex;
	justify-content: initial;
	align-items: center;
	margin-top: 16px;
}

.product-details .listItems {
	font-size: 18px;
	font-family: Roboto-Regular;
	color: #fff;
	margin: 0px;
	margin-left: 6px;
}

.product-details .qty-contaner {
	display: flex;
}

.product-details .qty-btn {
	background-color: #bbbbbb;
	color: #ffff;
	border: 1px solid #ffff;
	padding: 8px 10px;
	transition: 0.6s;
}
.product-details .qty-btn:hover {
	background-color: #fff;
	color: rgb(0, 0, 0);
	border: 1px solid #ffff;
	padding: 8px 10px;
	transition: 0.6s;
}
.product-details .qty-num {
	background-color: transparent;
	color: #ffff;
	border: 1px solid #ffff;
	padding: 8px 15px;
	margin: 0px 4px;
}
.product-details .quantity-wrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.product-details .quantity-wrap .text {
	font-size: 17px;
	font-family: "SF UI Display";
	color: #0000008a;
}

.product-details .quantity-wrap .text2 {
	font-size: 13px;
	font-family: "SF UI Display";
	color: #000000;
	font-weight: bold;
}

.product-details .main-borderAmount {
	border: 1px solid #9d9d9d;
	border-radius: 8px;
	margin-left: 0;
}

.cardButtonAdjust {
	margin-left: -8px;
}

.product-details .sizeSelect {
	width: 136px;
	background: transparent;
	color: #878787;
	padding: 7px 15px;
}

.product-details select.sizeSelect {
	border-bottom: unset !important;
}

.product-details .sizeSelect option {
	/* width: 136px; */
	color: rgb(0, 0, 0);
	padding: 7px 15px;
}

/* product-slide  */
.product-slide .inner-image-container {
	padding: 20px 20px;
}

/* product-slide  end */

/* bottom-container  */
.bottom-container .nav-tabs {
	border-bottom: unset !important;
}

.bottom-container .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
	color: #fff;
	background-color: #d60012;
	border-color: unset !important;
}
.bottom-container .nav-tabs .nav-link {
	border: 1px solid #edededf2 !important;
	border-top-left-radius: unset !important;
	border-top-right-radius: unset !important;
	color: #fff !important;
	margin-left: 14px !important;
}

.bottom-container .detail-tab {
	padding: 50px 20px;
}
.bottom-container .detail-tab .title {
	font-size: 20px;
	color: #ffffff;
	font-family: Poppins-SemiBold;
}

.bottom-container .detail-tab .desc {
	font-size: 12px;
	font-family: Poppins-Regular;
	color: #ffffff;
}
.bottom-container .detail-tab ul li {
	color: #ffffff;
	font-size: 14px;
	font-family: Montserrat-Medium;
}

.bottom-container .review-container .title {
	font-size: 20px;
	color: #ffffff;
	font-family: Poppins-SemiBold;
}

/* General Hydroponic */
.pack-wrap {
	display: flex;
	align-items: center;
	margin-top: 6px;
	gap: 20px;
	margin-bottom: 1.5rem;
}
.packValue p {
	margin-bottom: 0;
	font-family: "SF UI Display";
	font-size: 16px;
}
.pack-wrap .packValue {
	background-color: #fff;
	padding: 5px;
	align-items: center;
	width: 57px;
	text-align: center;
}

.pack-wrap .packValue:hover {
	background-color: #f1efde;
	color: #464646;
}

.hypdro .title2 {
	font-size: 20px;
	color: #464646;
	font-family: "SF UI Display";
	font-weight: bold;
	margin-bottom: 8px;
}

.productName2 {
	margin-top: 7px !important;
}

.para_red {
	color: red;
}

/* bottom-container end  */
@media (max-width: 1200px) {
	.product-details .sizeBtn {
		margin-bottom: 11px;
		width: 100%;
	}
	button.btn.sizeBtn {
		width: 100%;
	}
}

@media (max-width: 1024px) {
	.product-details .desc-title {
		margin-bottom: 13px;
	}
	.product-details .tags-right {
		width: 100%;
	}
	.product-details .quantity-wrap {
		display: flex;
		/* justify-content: space-between; */
		align-items: inherit;
		width: 100% !important;
		flex-direction: column;
	}
	.leave_comment {
		padding: 0px 0 50px;
	}
	.map_adjust {
		margin-top: 18px;
	}
	.product-details .similar-title {
		font-size: 23px;
		margin-bottom: 54px;
		margin-top: 28px;
	}
	.product-details .sizeSelect {
		width: 138px !important;
	}
	button.btn.sizeBtn {
		margin-bottom: 16px;
	}
}

@media (max-width: 991px) {
	.product-details .quantity-wrap {
		flex-direction: row;
	}
	.product-details .tags-right {
		float: left;
		width: 60%;
		background-color: #296253;
		color: #fff;
		padding: 10px 25px;
		border-radius: 8px;
	}
	.product-details .desc-title {
		font-size: 20px;
		color: #464646;
		font-family: "SF UI Display";
		font-weight: bold;
		margin-top: 14px;
	}
	.product-details .quantity-wrap .text {
		margin-bottom: 33px;
	}
}

@media (max-width: 768px) {
	.heading {
		padding-top: -10px;
	}
	.product-details {
		background: none;
	}
	.product-details .productDesc {
		font-size: 12px;
	}
	.product-details .quantity-wrap .text {
		font-size: 13px;
	}
	.product-details .sizeSelect {
		width: 112px;
	}
	.product-slide .inner-image-container {
		padding: 7px 7px;
	}
	.product-details .listItems {
		font-size: 14px;
	}
	.product-details .AddBtn {
		font-size: 12px;
	}
	.product-details .sizeBtn {
		font-size: 12px;
	}
	.product-details .tags-right {
		float: unset;
		margin: 15px 0px;
	}
	.product-details .sizeBtn {
		width: 100%;
		margin: 0;
	}
	.product-details .AddBtn {
		width: 100%;
		margin-bottom: 5px;
	}
	.ClientsReviewsSec {
		padding: 15px 2px;
	}
	.ClientsReviewsSec .post-star-cont {
		float: unset;
	}
	button.btn.sizeBtn.setbuttonprod {
		margin-bottom: 12px;
	}
	.product-details .quantity-wrap {
		flex-direction: row;
	}
}

@media (max-width: 575px) {
	button.btn.sizeBtn.setbuttonprod {
		margin-bottom: 12px;
	}
}

@media (max-width: 425px) {
	.product-details .quantity-wrap {
		margin-top: 16px;
	}
	.product-details.hypdross {
		padding: 30px 0 0 0 !important;
	}
	.product-details .maples {
		margin-top: 20px !important;
	}
}
@media (max-width: 375px) {
	.product-details .price {
		font-size: 15px;
	}
	.product-details .main-borderAmount {
		margin-left: -14px;
	}
}

@media (max-width: 360px) {
	.product-details .quantity-wrap {
		align-items: baseline;
	}
}

@media (max-width: 320px) {
	.bottom-container .nav-tabs .nav-link {
		margin-left: 0px !important;
		font-size: 13px !important;
	}
	.bottom-container .detail-tab {
		padding: 38px 8px;
	}
	.bottom-container .detail-tab .desc {
		font-size: 11px;
	}
	.bottom-container .detail-tab ul li {
		font-size: 12px;
	}
	.product-details .tags-right {
		width: 72%;
		padding: 10px 25px;
	}
}

.CardRelative {
	position: relative;
}

.plusSign {
	display: none;
}

.CardRelative:not(:last-child)::after {
	content: "+";
	display: block;
	position: absolute;
	right: -9px;
	top: 40%;
	font-size: 28px;
}
.frequentlyBtn {
	background: #286152;
	padding: 8px 25px;
	border-radius: 24px;
	text-transform: uppercase;
	transition: 0.6s;
	font-size: 19px;
	color: #fff;
	transition: 0.3s ease-in-out;
}

.frequentlyBtn:hover {
	background-color: #000000;
	color: #ffff;
}
