.detail_amount_total {
	text-align: center;
}

.main_heading .main_title {
	color: #286152;
	font-size: 50px;
	font-weight: bold;
	text-align: center;
	font-family: "ZangeziSans07_TRIAL";
}

.main_heading .main_number {
	font-size: 33px;
	font-weight: 600;
	font-family: "SF UI Display semibold";
}
.main_heading {
	margin: 0px 0 40px 0px;
}

.card_details {
	border: 2px solid #8e8d89;
	padding: 30px;
	text-align: center;
}

.head3 {
	margin-top: 28px;
}

.details_head2 {
	font-size: 20px;
	font-weight: bold;
	color: #286152;
	margin-bottom: 20px;
	font-family: "SF UI Display bold";
}

.Datepara p,
.Datenumber p {
	font-size: 16px;
	font-family: "SF UI Display medium";
	color: #000000;
}

.quantityset h4 {
	font-size: 18px;
	font-family: "SF UI Display semibold";
	color: #1f2c35;
}
.quantityset p {
	font-size: 16px;
	font-family: "SF UI Display medium";
	color: #1f2c35;
}

.pricePlant {
	color: #296253;
	font-family: "SF UI Display semibold";
	font-size: 18px;
}

.all_booking_details {
	display: flex;
	justify-content: space-between;
}

.Coneflower {
	display: flex;
	text-align: left;
}

.Coneflower .quantityset {
	margin-left: 20px;
}

.card_details .allDetailCard {
	height: 200px;
	overflow-y: scroll;
	padding-right: 1rem;
}
.card_details .allDetailCard::-webkit-scrollbar-track {
	/* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
	background-color: transparent;
}

.card_details .allDetailCard::-webkit-scrollbar {
	width: 2px;
	background-color: transparent;
}

.card_details .allDetailCard::-webkit-scrollbar-thumb {
	background-color: #296253;
	border: 2px solid transparent;
}
.detailamount {
	display: flex;
	justify-content: space-between;
}

.Datepara {
	text-align: initial;
}

.Datenumber {
	text-align: left;
}

@media (max-width: 360px) {
	.main_heading .main_title {
		font-size: 39px;
	}
	.main_heading .main_number {
		font-size: 26px;
	}
	.Coneflower img {
		width: 60px;
		height: 62px;
	}
	.quantityset h4 {
		font-size: 10px;
		margin-top: 10px;
	}
	.quantityset p {
		font-size: 13px;
	}
	.pricePlant {
		font-size: 14px;
		margin-top: 10px;
	}
	.floatleft {
		text-align: end;
	}
	.floatright {
		text-align: start;
	}
	.Datepara p,
	.Datenumber p {
		font-size: 14px;
	}
}

@media (max-width: 320px) {
	.main_heading .main_title {
		font-size: 35px;
	}
	.main_heading .main_number {
		font-size: 22px;
	}
}
