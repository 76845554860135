.Framers-page {
	padding: 80px 0px;
	background-image: url("../img/bgDesign.png");
	background-size: 100% 100%;
	background-attachment: fixed;
	background-size: 100%;
}

.Framers-page .main-Title {
	color: #286152;
	font-size: 44px;
	font-weight: bold;
	text-align: center;
	font-family: ZangeziSans07_TRIAL;
}

.Framers-page .formContainer .sub-title-sm {
	font-size: 15px;
	color: #2a263d85;
	font-family: "SF UI Display";
	margin: 0;
	margin-top: 28px;
}

.Framers-page .main-para {
	font-size: 16px;
	color: #0000008f;
	font-family: SF UI Display;
	text-align: center;
	margin-bottom: 35px;
	max-width: 65%;
	margin: 0 auto 35px;
}

.Framers-page .formContainer {
	padding: 20px 170px;
}

.Framers-page .formContainer .sub-title {
	font-size: 24px;
	color: #2a263d;
	font-family: "SF UI Display";
	font-weight: 500;
	margin: 0;
	margin-bottom: 22px;
	margin-top: 22px;
}

.Framers-page .formContainer input,
.Framers-page .formContainer select {
	width: 100%;
	background-color: #f1efde;
	border: unset;
	padding: 4px 8px;
	border-bottom: 1px solid #c3c3c3;
	padding-top: 20px;
}

.Framers-page button {
	color: #fff;
	background-color: #286152;
	border-radius: 36px;
	padding: 7px 25px;
}

.Framers-page button:hover {
	background-color: #000000;
	color: #fff;
}

.Framers-page .formContainer .card-type {
	display: flex;
	max-width: 50%;
}
.GardenTour-page
	.formContainer
	.card-type
	input:checked
	~ span.checkmark
	figure {
	box-shadow: 0px 0px 0px 1px #fdae25;
}
.Framers-page .formContainer .card-type span.checkmark figure {
	height: 35px;
	line-height: 35px;
	text-align: center;
	margin-right: 10px;
	width: 75px;
	overflow: hidden;
}
.secondbtn {
	margin-left: 10px;
}

@media (max-width: 1024px) {
	.Framers-page .main-Title {
		font-size: 36px;
	}
}

@media (max-width: 820px) {
	.Framers-page .main-Title {
		font-size: 35px;
	}
	.Framers-page .main-para {
		font-size: 25px;
		max-width: 80%;
	}
	.Framers-page .formContainer {
		padding: 20px 83px;
	}
	.Framers-page .formContainer .sub-title {
		font-size: 31px;
	}
}

@media (max-width: 768px) {
	.priceRate {
		margin-top: 10px;
		font-size: 18px;
	}
	.priceRate img {
		width: 12px !important;
	}
	.Framers-page .main-para {
		font-size: 19px;
	}
	.Framers-page {
		background: none;
	}
	.Framers-page .main-Title {
		font-size: 18px;
	}
	.Framers-page .main-para {
		margin: 0 auto 0px;
	}
	.Framers-page .formContainer {
		padding: 18px 11px;
	}
	.Framers-page .formContainer .sub-title {
		margin-left: 7px;
	}
	.secondbtn {
		margin-left: 0px;
	}
	.camping_btn {
		display: flex;
		gap: 10px;
		flex-direction: column;
	}
	.camping_btn button.btn {
		width: 100%;
	}
	.Framers-page .main-para {
		max-width: 98%;
	}
}

@media (max-width: 414px) {
	.priceRate {
		margin-top: 0px;
	}

	.secondbtn {
		margin-left: 1px;
	}
	.Framers-page .main-Title {
		font-size: 18px;
	}
	.Framers-page .formContainer .sub-title {
		font-size: 26px;
	}
	.Framers-page .formContainer .sub-title-sm {
		font-size: 15px;
	}

	.Framers-page .main-para {
		font-size: 19px;
	}
}

@media (max-width: 393px) {
	.priceRate {
		margin-top: 0px;
	}
	.Framers-page .main-para {
		font-size: 19px;
	}
	.Framers-page .formContainer .sub-title {
		font-size: 26px;
	}
}

@media (max-width: 390px) {
	.priceRate {
		margin-top: 0px;
	}
	.Framers-page .formContainer .sub-title {
		font-size: 26px;
	}
	.Framers-page .formContainer .sub-title-sm {
		font-size: 15px;
	}

	.Framers-page .main-para {
		font-size: 19px;
	}

	.Framers-page .formContainer input,
	.Framers-page .formContainer select {
		font-size: 15px;
	}
	.Framers-page button {
		padding: 5px 25px;
		font-size: 16px;
	}
	.Framers-page .main-para {
		font-size: 15px;
	}
	.Framers-page button {
		padding: 4px 25px;
		font-size: 19px;
	}
}

@media (max-width: 375px) {
	.Framers-page .main-Title {
		font-size: 16px;
	}
	.priceRate img {
		width: 14px;
		margin-top: -7px;
		margin-right: -5px;
	}
	.secondbtn {
		margin-left: 1px;
	}
}

@media (max-width: 320px) {
	.Framers-page .main-Title {
		font-size: 19px;
	}
	.Framers-page button {
		font-size: 16px;
	}
	.Framers-page .formContainer .sub-title-sm {
		font-size: 18px;
	}
	.Framers-page .main-para {
		font-size: 13px;
	}

	.Book_range_center {
		flex-direction: column;
	}
	.Framers-page .formContainer input,
	.Framers-page .formContainer select {
		font-size: 14px;
	}
	.secondbtn {
		margin-left: -2px !important;
	}
	.Framers-page .formContainer .sub-title {
		font-size: 19px;
		margin-top: 27px;
	}
}
