/* Blog Css Start Here */
.blogs_details {
	padding: 60px 0;
}
.blogs_details .detail {
	padding: 40px 0px;
}
.blogs_details .detail .title {
	padding-bottom: 25px;
}
.blogs_details .detail .title h3 {
	color: #ffff;
}

.reviews_sec {
	margin-top: 70px;
}
.reviews_sec .heading {
	padding-bottom: 30px;
}
.reviews_sec .heading h3 {
	font-size: 35px;
	color: #000000;
}
.reviews_sec .reviewDv {
	border-bottom: 1px solid #ddd;
}
.reviews_sec .reviewDv:last-child {
	border: 0;
}
.reviews_sec .reviewDv:last-child .reviews {
	border-bottom: 0;
}
.reviews_sec .reviewDv .answersDv {
	padding: 15px 0 15px 100px;
	position: relative;
}
.reviews_sec .reviewDv .answersDv .vertical-line {
	width: 1px;
	height: 90%;
	background: #ddd;
	position: absolute;
	left: 10%;
	top: 5%;
}
.reviews_sec .reviewDv .reviews {
	display: flex;
	align-items: flex-start;
	border-bottom: 1px solid #ddd;
	padding: 15px 0px;
}
.reviews_sec .reviewDv .reviews .user-img img {
	max-width: 400px;
	width: 52px;
	border-radius: 50%;
}
.reviews_sec .reviewDv .reviews .detailDv {
	padding-left: 16px;
}
.reviews_sec .reviewDv .reviews .detailDv .header {
	display: flex;
	align-items: center;
	padding-bottom: 10px;
}
.reviews_sec .reviewDv .reviews .detailDv .header h5.name {
	font-size: 18px;
	color: #000000;
	margin: 0;
}
.reviews_sec .reviewDv .reviews .detailDv .header p {
	color: #909090;
	font-size: 12px;
	margin: 0 0 0px 8px;
}
.reviews_sec .reviewDv .reviews .detailDv p {
	color: #525252;
	font-size: 13px;
}
.reviews_sec .bottom-bar ul {
	padding: 0;
	list-style: none;
	margin: 0;
}
.reviews_sec .bottom-bar .rating {
	display: flex;
	align-items: center;
}
.reviews_sec .bottom-bar ul li {
	display: inline-block;
	color: #c23127;
}
.reviews_sec .bottom-bar p {
	margin: 0 0 0 8px;
}
.reviews_sec .bottom-bar {
	display: flex;
	align-items: center;
}
.reviews_sec .bottom-bar .rplyDv {
	margin-left: 30px;
}
.reviews_sec .bottom-bar .rplyDv a {
	color: #262262;
	text-decoration: none;
}
.reviews_sec .answersDv .answer:last-child {
	border: 0;
	padding-bottom: 0;
}
.ClientsReviewsSec .info .name {
	font-size: 14px;
	color: #080808;
	font-family: "SF UI Display";
}
.ClientsReviewsSec .info .date {
	font-size: 14px;
	color: #787878;
	font-family: "SF UI Display";
}
@media (max-width: 1100px) {
	.blogs_details .detail .title h3 {
		font-size: 30px;
		line-height: 40px;
	}
}
@media (max-width: 575px) {
	.blogs_details {
		padding: 30px 0;
	}
	.blogs_details .detail {
		padding: 10px 0px;
	}
	.blogs_details .detail .title h3 {
		font-size: 25px;
		line-height: 35px;
	}
	.blogs_details .detail p {
		font-size: 14px;
		text-align: justify;
	}
}
@media (max-width: 480px) {
	.blogs_details .detail .title h3 {
		font-size: 22px;
		line-height: 32px;
	}
}
/* Client Comments Start Here */
.ClientsReviewsSec {
	padding: 35px 2px;
}
.ClientsReviewsSec .review-user-send {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.ClientsReviewsSec .review-user-send .user-name {
	font-size: 16px;
	color: #000000;
	font-family: "SF UI Display";
	font-weight: bold;
	margin: 0;
}

.ClientsReviewsSec .review-user-send .user-about {
	font-size: 12px;
	color: #909090;
	font-family: "SF UI Display";
	margin: 0;
}

.ClientsReviewsSec .post-star-cont {
	float: right;
}

.ClientsReviewsSec .user-imgCont {
	height: 70px;
	width: 70px;
	overflow: hidden;
	margin-right: 16px;
}

.ClientsReviewsSec .user-imgCont img {
	width: 100%;
}
.ClientsReviewsSec .head {
	padding-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.ClientsReviewsSec .head h3 {
	color: #282828;
	font-size: 26px;
	font-family: "SF UI Display";
	font-weight: bold;
}
.ClientsReviewsSec .head h5.total-comments {
	color: #ffffff;
	font-size: 18px;
	font-family: Poppins-SemiBold;
}
.ClientsReviewsSec .brieflyReview {
	display: flex;
	align-items: flex-start;
}
.ClientsReviewsSec img {
	width: 100%;
}
.ClientsReviewsSec .brieflyReview .img-box {
	min-width: 80px;
	border-radius: 50%;
}
.ClientsReviewsSec .brieflyReview .contentDv {
	padding-left: 20px;
}
.ClientsReviewsSec .brieflyReview .contentDv .info {
	display: flex;
	align-items: center;
	padding-bottom: 10px;
}
.ClientsReviewsSec .brieflyReview .contentDv .info .spacer {
	padding: 0 10px;
}
.ClientsReviewsSec .brieflyReview .contentDv .info h6 {
	margin-bottom: 0;
}
.ClientsReviewsSec .brieflyReview .descrip {
	padding-right: 20%;
}
.ClientsReviewsSec .brieflyReview .descrip p {
	color: #525252;
	font-family: "SF UI Display";
	font-size: 13px;
}
.ClientsReviewsSec .brieflyReview .rating .rply-btn a {
	font-size: 13px;
	font-family: Poppins-Regular;
	color: #565656;
}
.ClientsReviewsSec .replyDv .brieflyReview .img-box {
	min-width: 90px;
}
.ClientsReviewsSec .replyDv {
	background: #f5f5f5;
	padding: 10px 30px 0px;
	margin-left: 120px;
	margin-top: 30px;
	margin-bottom: 60px;
}
.ClientsReviewsSec .replyDv .brieflyReview {
	padding: 15px 0;
	border-bottom: 1px solid #e0e0e0;
}
.ClientsReviewsSec .replyDv .brieflyReview:last-child {
	border: 0;
}
.ClientsReviewsSec .replyDv .brieflyReview .descrip p {
	margin-bottom: 0;
}
.ClientsReviewsSec .border-topp {
	border-top: 1px solid #e3e3e3;
}
@media (max-width: 991px) {
	.ClientsReviewsSec .replyDv {
		margin-left: 30px;
	}
	.ClientsReviewsSec .brieflyReview .descrip {
		padding-right: 0%;
	}
}

@media (max-width: 787px) {
	.reviews_sec {
		margin-top: -110px;
	}
}

@media (max-width: 575px) {
	.ClientsReviewsSec .head {
		display: block;
		text-align: center;
	}
	.ClientsReviewsSec .head h3 {
		font-size: 30px;
		line-height: 40px;
	}
	.ClientsReviewsSec .replyDv {
		margin-left: 0px;
	}
	.ClientsReviewsSec img {
		width: 100%;
		margin: 0px 0px 40px;
	}
	.ClientsReviewsSec .brieflyReview {
		display: block;
		text-align: center;
	}
	.ClientsReviewsSec .replyDv .brieflyReview {
		display: block;
		text-align: center;
	}
	.ClientsReviewsSec .brieflyReview .descrip p {
		text-align: justify;
		text-align-last: center;
	}
	.ClientsReviewsSec .brieflyReview .contentDv {
		padding-left: 0px;
	}
	.ClientsReviewsSec .brieflyReview .contentDv .info {
		justify-content: center;
	}
}
/* Client Comments Start Here */

.leave_comment {
	padding: 50px 0 70px;
}
.leave_comment .head h3 {
	color: #1c1c1c;
	padding-bottom: 20px;
}
.leave_comment .form-control {
	border: unset;
	/* border-radius: 0; */
	border-bottom: 2px solid #cbcbcbc7;
	margin-bottom: 15px;
	padding: 20px 20px;
	background: transparent;
}
.leave_comment .form-control::placeholder {
	font-size: 15px;
	color: #63606091;
}
.leave_comment textarea {
	height: 130px;
	resize: none;
	background: transparent;
}
.leave_comment button {
	color: #fff;
	background: #286152;
	padding: 8px 25px;
	border-radius: 24px;
	text-transform: uppercase;
	transition: 0.6s;
	font-size: 19px;
	transition: 0.3s ease-in-out;
}

.leave_comment button:hover {
	background-color: #000000;
	color: #fff;
}

.leave_comment button img {
	width: unset;
}

@media (max-width: 1024px) {
	.ClientsReviewsSec .post-star-cont {
		float: left;
	}
	button.btn.SubmitReview {
		padding: 1px 7px;
		margin-top: 13px !important;
	}
}

@media (max-width: 575px) {
	.leave_comment {
		padding: 0px 0 20px;
	}
	button.btn.SubmitReview {
		padding: 0px 7px;
	}
	button.btn.SubmitReview img {
		width: unset;
		margin-top: -13px;
		margin: 0px;
		padding: 10px 8px;
	}
}
@media (max-width: 480px) {
	.leave_comment .head h3 {
		font-size: 30px;
		line-height: 40px;
	}
	.reviews_sec .reviewDv .answersDv {
		padding: 14px 0 14px 40px;
		margin-left: -50px;
	}
}

/* @media (max-width: 414px) {
	.reviews_sec .reviewDv .answersDv {
		margin-left: -104px;
	}
} */

@media (max-width: 390px) {
	.reviews_sec .reviewDv .answersDv {
		margin-left: -39px !important;
	}
}

@media (max-width: 375px) {
	.reviews_sec .reviewDv .answersDv {
		margin-left: -104px;
	}
}

@media (max-width: 320px) {
	.ClientsReviewsSec .brieflyReview .descrip p {
		font-size: 10px;
	}
	.ClientsReviewsSec {
		padding: 65px 0px;
	}
	.leave_comment .head h3 {
		font-size: 22px;
	}
	.reviews_sec .reviewDv .reviews .detailDv p {
		font-size: 9px;
	}
	.reviews_sec .reviewDv .reviews .detailDv .header h5.name {
		font-size: 10px;
	}
	.reviews_sec .reviewDv .reviews .detailDv .header p {
		font-size: 9px;
	}
	.reviews_sec .reviewDv .answersDv {
		padding: 15px 0 15px 29px;
	}
	.product-details .similar-title {
		font-size: 19px;
	}
}
/* Blog Css End Here */

.SubmitReview {
	color: #fff;
	background: #286152;
	padding: 5px 16px;
	border-radius: 20px;
	margin-top: -8px !important;
}
