.about-page {
	padding: 80px 0px;
	background-image: url("../img/bgDesign.png");
	background-size: 100% 100%;
	background-attachment: fixed;
	background-size: 100%;
}

.about-page .main-Title {
	color: #286152;
	font-size: 50px;
	font-weight: bold;
	text-align: center;
	font-family: ZangeziSans07_TRIAL;
}

.about-page .main-para {
	font-size: 16px;
	color: #0000008f;
	font-family: SF UI Display;
	text-align: center;
	margin-bottom: 35px;
}

.about-page .img-cont {
	overflow: hidden;
	height: 60%;
	width: 100%;
}
.about-page .img-cont img {
	width: 100%;
}

.about-page .img-cont2 img {
	width: 100%;
}

.about-page section.home-contact .img-cont {
	height: 100%;
}

.about-page section.home-contact .img-cont img {
	height: 100%;
}

.about-pageSec2 {
	margin-top: 30px;
	padding: 105px 0px;
	background: #286152;
}
.about-pageSec2 .img-cont {
	overflow: hidden;
	height: 100%;
	width: 100%;
}

.about-us-text {
	margin-top: -42px;
	text-align: justify;
}

.about-pageSec2 .img-cont img {
	width: 100%;
	height: 100%;
}

.about-pageSec2 .shadow-title {
	font-size: 82px;
	font-weight: bold;
	color: #ffffff14;
	/* margin-bottom: -23px; */
	font-family: ZangeziSans07_TRIAL;
	margin-top: 40px;
	margin-bottom: -14px;
}

.about-pageSec2 .main-title {
	color: #fff;
	font-size: 44px;
	font-family: "ZangeziSans07_TRIAL";
}

.about-pageSec2 .para {
	font-family: "SF UI Display";
	font-size: 16px;
	color: #ffffff61;
	width: 82%;
	margin-top: 22px;
}

.about-pageSec2 .para-points {
	font-family: "SF UI Display";
	font-size: 16px;
	color: #ffffff61;
	width: 82%;
}

@media (max-width: 1200px) {
	.about-page .main-Title {
		margin-top: 100px;
	}
	.about-page .img-cont {
		height: 54%;
	}
}

@media (max-width: 1024px) {
	.home-contact .shadow-title {
		font-size: 74px;
	}
	.about-page .main-Title {
		margin-top: -5px;
	}
	.about-pageSec2 .main-title {
		margin-top: 16px;
		font-size: 34px;
	}
	.about-pageSec2 .para {
		width: 88%;
	}
}

@media (max-width: 900px) {
	.about-page .main-Title {
		margin-top: 70px;
	}
	.book-btn {
		width: 91%;
	}
}

@media (max-width: 992px) {
	.about-page .main-Title {
		margin-top: -10px;
	}
	.about-pageSec2 .shadow-title {
		font-size: 51px;
		margin-bottom: -8px;
	}
	.about-pageSec2 .main-title {
		font-size: 30px;
	}
	.about-pageSec2 .para {
		font-size: 13px;
		width: 82%;
	}
}

@media (max-width: 768px) {
	.about-page {
		background: none;
		padding: 23px 0px;
	}
	.about-page .main-Title {
		font-size: 31px;
		margin-top: 49px;
	}
	.about-pageSec2 {
		padding: 27px 0px;
		margin-top: 40px;
	}
	.about-pageSec2 .shadow-title {
		font-size: 49px;
		margin-top: 15px;
		margin-bottom: 12px;
	}
	.about-pageSec2 .para {
		width: 100%;
	}
	.about-page .img-cont img {
		margin-bottom: 20px;
	}
}

@media (max-width: 414px) {
	.home-contact .shadow-title {
		margin-bottom: -16px !important;
	}
	.about-pageSec2 .shadow-title {
		margin-top: 50px;
		margin-bottom: 13px;
	}
}

@media (max-width: 375px) {
	.about-pageSec2 .shadow-title {
		font-size: 49px;
		margin-top: 51px;
		margin-bottom: 12px;
	}
	.about-pageSec2 .para-points {
		width: 100%;
		margin-left: -36px;
	}
	.home-contact .shadow-title {
		font-size: 30px !important;
		margin-top: 15px;
		margin-bottom: -11px !important;
		margin-left: -1px;
	}
	.home-contact .about-title {
		margin-left: 0px;
	}
}
