.imageContainer {
	background-image: url("../img/bgDesign.png");
	background-size: 100% 100%;
	background-attachment: fixed;
	background-size: 100%;
}

.testimonial-page {
	margin-top: 20px;
}

.testimonial-page .first-section {
	margin-bottom: 10px;
}

.testimonial-page .second-section {
	margin-bottom: 50px;
}

/* .testimonialslider {
	width: 77% !important;
} */

.testimonial_set_btn button.slick-arrow.slick-next {
	margin-top: 141px;
	margin-right: 440px;
	color: #afafafa1 !important;
	z-index: 99999;
	/* font-size: 67px !important; */
}

.testimonial_set_btn button.slick-arrow.slick-prev {
	margin-top: 141px;
	margin-left: 420px;
	z-index: 99999;
}

.productName {
	margin-top: 20px;
	font-size: 30px;
	font-family: "SF UI Display";
	color: #000000;
	font-weight: 600;
}

.maples {
	margin-top: -10px;
	font-size: 14px;
	font-weight: 600;
	color: #00000057;
	font-family: "SF UI Display";
}
.slick-next:before {
	font-size: 32px;
	color: #286152 !important;
	margin-left: 2px;
	content: "";
	background-image: url(../img/testimonial/test2.png);
	height: 54px;
	width: 54px;
	display: block;
	background-repeat: no-repeat;
	background-size: 37px;
	margin-top: 4px;
}

.slick-prev:before {
	font-size: 32px;
	color: #286152 !important;
	margin-left: -18px;
	content: "";
	background-image: url(../img/testimonial/test1.png);
	height: 54px;
	width: 54px;
	display: block;
	background-repeat: no-repeat;
	background-size: 37px;
	margin-top: 4px;
}
.silder_set_div {
	position: relative;
}

.testimonial-para {
	margin-top: -10px;
	font-size: 14px;
	font-weight: 600;
	font-family: "SF UI Display";
	color: #0000009d;
}

@media (max-width: 1200px) {
	.tourists-title {
		font-size: 38px;
	}

	/* .slick-next:before {
		margin-left: 68px;
	}

	.slick-prev:before {
		margin-left: -141px;
	} */

	.slick-next,
	.slick-prev {
		top: unset;
		left: 450px;
		bottom: -22px;
	}
	.slick-next:before {
		margin-left: 48px;
	}
	.testimonial_set_btn button.slick-arrow.slick-prev {
		margin-left: 0px;
	}
	.slick-prev:before {
		margin-top: 20px;
	}
	.slick-next:before {
		margin-top: 20px;
	}
}

@media (max-width: 991px) {
	/* .slick-prev:before {
		margin-left: -748px;
	}
	.slick-next:before {
		margin-left: -298px;
	} */
	.slick-next,
	.slick-prev {
		top: unset;
		left: 9px;
		bottom: -22px;
	}
	.testimonial_set_btn button.slick-arrow.slick-prev {
		margin-left: 0px;
	}
	.slick-prev:before {
		margin-left: 0px;
	}
	.testimonial_set_btn button.slick-arrow.slick-next {
		margin-top: 0px;
		margin-right: 0px;
	}
	.slick-prev:before {
		margin-top: 20px;
	}
	.slick-next:before {
		margin-top: 20px;
	}
}

@media (max-width: 768px) {
	.tourists-title {
		font-size: 32px;
	}
	.imageContainer {
		background-image: none;
	}
	.slick-prev:before {
		margin-top: 20px;
	}
	.slick-next:before {
		margin-top: 20px;
	}
}

@media (max-width: 320px) {
	.tourists-title {
		font-size: 18px;
	}
}
