.contact-page {
	padding: 80px 0px;
	background-image: url("../img/bgDesign.png");
	background-size: 100% 100%;
	background-attachment: fixed;
	background-size: 100%;
}

.contact-page .main-Title {
	color: #286152;
	font-size: 50px;
	font-weight: bold;
	text-align: center;
	font-family: ZangeziSans07_TRIAL;
}

.contact-page .main-para {
	font-size: 16px;
	color: #0000008f;
	font-family: SF UI Display;
	text-align: center;
	margin-bottom: 35px;
}

.location-map {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 15px auto;
}

.contact-page input {
	width: 100%;
	padding: 10px 22px;
	border-radius: 22px;
	background: #f1efde;
	border: 1px solid #c5c5c5;
}

.contact-page textarea {
	width: 100%;
	height: 172px;
	padding: 10px 22px;
	border-radius: 22px;
	background: #f1efde;
	border: 1px solid #c5c5c5;
}
.contact-page .input-div {
	display: grid;
	padding: 14px 55px;
}

.contact-page .comp-info {
	text-align: center;
}

.contact-page .icon-cont {
	background-color: #286152;
	color: #fff;
	width: 80px;
	height: 80px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
}

.contact-page .icon-cont .icons-info {
	color: #ffff;
	font-size: 42px;
}

.contact-page .content .title {
	font-size: 32px;
	color: #286152;
	font-family: "ZangeziSans07_TRIAL";
	margin: 18px 0px;
}

.contact-page .content .detail {
	font-size: 15px;
	color: #000000;
	font-family: "SF UI Display";
}

.contact-page .content .small-info {
	font-family: "SF UI Display";
	color: #286152;
	font-size: 16px;
}
.book-btn2 {
	color: #fff;
	background-color: #286152;
	border-radius: 36px;
	padding: 7px 25px;
	width: 21%;
	margin: 20px 0px;
	font-size: 18px;
	font-weight: 500;
}

.border-bottom22 {
	border-bottom: 2px solid #0000002c;
	margin-bottom: 20px;
	width: 89%;
	margin: 20px auto;
}

.single_amount input:checked ~ span {
	color: #fff;
	background-color: #286152;
}
.single_amount span {
	display: block;
	width: 100%;
	cursor: pointer;
	padding: 10px 22px;
	border-radius: 22px;
	background: #f1efde;
	border: 1px solid #c5c5c5;
}

.Contact-page button {
	color: #fff;
	background-color: #286152;
	border-radius: 36px;
	padding: 7px 25px;
	width: 17%;
	justify-self: end;
	font-weight: 500;
	text-transform: uppercase;
}

.Contact-page button:hover {
	background: #000;
	color: #fff;
}

.amount_section h3 {
	font-weight: bold;
	font-size: 20px;
	color: #262626;
	font-family: "SF UI Display";
	margin: 0px 0px 40px 5px;
}
.amount_section2 h3 {
	font-weight: bold;
	font-size: 20px;
	color: #262626;
	font-family: "SF UI Display";
	margin: 0px 0px 40px 5px;
	margin-left: 61px;
}
.amount_section3 h3 {
	font-weight: bold;
	font-size: 20px;
	color: #262626;
	font-family: "SF UI Display";
	margin: 0px 0px 40px 5px;
}

@media (max-width: 1280px) {
	.amount_section2 h3 {
		padding-left: 71px;
		margin: 0;
		padding-bottom: 20px;
	}
	.amount_section3 h3 {
		padding-left: 6px;
		margin: 0;
	}
}

@media (max-width: 1024px) {
	.contact-page .main-para {
		width: 95%;
		margin-left: 23px;
	}
	.amount_section2 h3 {
		padding-left: 61px;
		margin: 0;
	}
}

@media (max-width: 787px) {
	.contact-page {
		background: none;
	}
	.Contact-page button {
		width: 19%;
	}
}

@media (max-width: 768px) {
	.contact-page .main-para {
		font-size: 13px;
	}
	.contact-page .input-div {
		padding: 14px 10px;
	}
	.contact-page .main-Title {
		font-size: 31px;
	}
	.book-btn2 {
		padding: 7px 25px;
		width: 70%;
	}
}

@media (max-width: 414px) {
	.amount_section2 h3 {
		margin: 0px;
	}
	.details_head {
		margin-left: -33px !important;
	}
	.details_head2 {
		margin-left: -55px !important;
	}
	.Contact-page button {
		width: 33%;
	}
}
@media (max-width: 390px) {
	.Contact-page button {
		padding: 7px 8px;
		width: 42% !important;
		justify-self: center;
	}
}
@media (max-width: 375px) {
	.amount_section2 h3 {
		margin: 0px;
	}
	.details_head {
		margin-left: -33px !important;
	}
	.details_head2 {
		margin-left: -55px !important;
	}
}

@media (max-width: 320px) {
	.contact-page .main-Title {
		font-size: 36px;
	}
	.contact-page .main-Title {
		font-size: 19px;
	}
	.contact-page .main-para {
		font-size: 13px;
	}
	.Contact-page button {
		width: 100%;
		display: block;
		/* float: left; */
	}
}
