.hoverbtn:hover {
	background-color: black !important;
	color: #fff !important;
}

@media (max-width: 1200px) {
	.fullbtn {
		width: 100% !important;
	}
}

@media (max-width: 764px) {
	.fullbtn {
		width: 100% !important;
	}
}
