.GardenTour-page {
	padding: 80px 0px;
	background-image: url("../img/bgDesign.png");
	background-size: 100% 100%;
	background-attachment: fixed;
	background-size: 100%;
}

.GardenTour-page .main-Title {
	color: #286152;
	font-size: 44px;
	font-weight: bold;
	text-align: center;
	font-family: ZangeziSans07_TRIAL;
}

.GardenTour-page .main-para {
	font-size: 16px;
	color: #0000008f;
	font-family: SF UI Display;
	text-align: center;
	margin-bottom: 35px;
	max-width: 65%;
	margin: 0 auto 35px;
}

.GardenTour-page .formContainer {
	padding: 20px 170px;
}

.GardenTour-page .formContainer .sub-title {
	font-size: 24px;
	color: #2a263d;
	font-family: "SF UI Display";
	font-weight: 500;
	margin: 0;
	margin-bottom: 22px;
	margin-top: 22px;
}

.GardenTour-page .formContainer .sub-title-sm {
	font-size: 15px;
	color: #2a263d85;
	font-family: "SF UI Display";
	margin: 0;
	margin-top: 28px;
}

.GardenTour-page .formContainer input {
	width: 100%;
	background-color: #f1efde;
	border: unset;
	padding: 4px 8px;
	border-bottom: 1px solid #c3c3c3;
	padding-top: 20px;
}
.secondbtn {
	margin-left: 10px;
}

.GardenTour-page button {
	color: #fff;
	background-color: #286152;
	border-radius: 36px;
	padding: 7px 25px;
	text-transform: uppercase;
}

.GardenTour-page button:hover {
	background: #000;
	color: #fff;
}

.GardenTour-page .formContainer .card-type {
	display: flex;
	max-width: 50%;
}
.GardenTour-page
	.formContainer
	.card-type
	input:checked
	~ span.checkmark
	figure {
	box-shadow: 0px 0px 0px 1px #fdae25;
}
.GardenTour-page .formContainer .card-type span.checkmark figure {
	height: 35px;
	line-height: 35px;
	text-align: center;
	margin-right: 10px;
	width: 75px;
	overflow: hidden;
}

.addCampingRadio {
	margin: 15px 0;
}
p.sub-title-sm.with_check {
	display: flex;
	gap: 14px;
	align-items: flex-start;
}
label.for_radio input {
	width: unset !important;
}
label.for_radio span {
	padding-left: 14px;
}
p.sub-title-sm.with_check input[type="checkbox"] {
	width: unset;
	display: block;
	height: unset;
	margin-top: 5px;
}
@media (max-width: 768px) {
	.GardenTour-page button {
		margin-left: -13px;
	}
	.GardenTour-page {
		background: none;
	}
	.GardenTour-page .main-Title {
		font-size: 38px;
	}
	.GardenTour-page .main-para {
		margin: 0 auto 0px;
	}
	.GardenTour-page .formContainer {
		padding: 18px 71px;
	}
	.GardenTour-page .formContainer .sub-title {
		margin-left: -17px;
	}
	.secondbtn {
		margin-left: 0px;
	}
	.camping_btn {
		display: flex;
		gap: 10px;
		flex-direction: column;
	}
	.camping_btn button.btn {
		width: 100%;
	}
	.GardenTour-page .main-para {
		max-width: 98%;
	}
}
@media (max-width: 500px) {
	.GardenTour-page .formContainer {
		padding-left: 15px;
	}
	.GardenTour-page .formContainer {
		padding: 18px 25px !important;
	}
}

@media (max-width: 414px) {
	.GardenTour-page .main-Title {
		font-size: 29px;
	}
}

@media (max-width: 375px) {
	.GardenTour-page .main-Title {
		font-size: 26px;
	}
	.addCampingRadio {
		margin: 3px 0 0 0;
	}
}

@media (max-width: 323px) {
	.GardenTour-page .main-Title {
		font-size: 21px;
	}
	.Book_range_center .priceRate {
		margin-bottom: -4px;
	}
}
