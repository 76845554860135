.ordersdetails-page {
	padding: 80px 0px;
	background-image: url("../img/bgDesign.png");
	background-size: 100% 100%;
	background-attachment: fixed;
    background-size: 100%;
}

.ordersdetails-page .AddBtn {
	padding: 8px 28px;
	background: #296253;
	color: #fff;
	font-weight: 500;
	font-size: 18px;
	border: unset;
	transition: 0.6s;
	border-radius: 22px;
	text-transform: uppercase;
}

.ordersdetails-page .AddBtn:hover {
	background: #fff;
	color: #000;
	border: unset;
}

.ordersdetails-page .maples {
	margin: 0;
	font-size: 17px;
	font-weight: 600;
	color: #00000057;
	font-family: "SF UI Display";
}

.ordersdetails-page .productName {
	font-size: 20px;
	font-family: "SF UI Display";
	color: #000000;
	font-weight: 600;
}

.ordersdetails-page .desc-title {
	font-size: 20px;
	color: #464646;
	font-family: "SF UI Display";
	font-weight: bold;
}

.ordersdetails-page .productDesc {
	font-size: 15px;
	font-family: "SF UI Display";
	color: #0000009c;
	font-weight: 600;
}

.ordersdetails-page .desc-title {
	font-size: 17px;
	color: #464646;
	font-family: "SF UI Display";
	font-weight: bold;
}

.ordersdetails-page .firstDesc {
	border-right: 1px solid #707070;
}

.ordersdetails-page .size-select-div {
	width: 30vw;
}

.ordersdetails-page .size-select-div p {
	font-size: 17px;
	color: #464646;
	font-family: "SF UI Display";
	font-weight: bold;
}

.ordersdetails-page .size-select-div span {
	font-size: 16px;
	color: #464646;
	font-family: "SF UI Display";
}

.ordersdetails-page .size-select-div .PaidStatus,
i {
	color: #00bc26;
}

.ordersdetails-page .size-select-div .counter {
	background-color: #f2f2f2;
	color: #0000009c;
	padding: 5px 20px;
}
