.thankyou .happyShopping {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 60px 30px;
	width: 40%;
	margin: 90px auto;
	text-align: center;
	background-color: #efefef;
	border-radius: 10px;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.thankyou .happyShopping h3 {
	color: #286152;
}
.thankyou .happyShopping .OrderDetails p {
	margin-left: 20px;
	font-weight: 500;
	color: #000000;
	font-size: 14px;
}
.setButtonThank {
	width: 100%;
	padding: 12px 80px;
	cursor: pointer;
	background: #286152;
	/* background-image: linear-gradient(to right, #A2D858 , #51A851); */
	color: #fff;
	font-size: 18px;
	text-transform: uppercase;
	font-weight: 600;
	border: 0;
	transition: 0.6s;
	border: 2px solid #286152;
	border-radius: 28px;
}

/* .thankyou .happyShopping .button-group a.btn {
	background: #8dc63f;
	font-size: 18px;
	padding: 8px 30px;
	color: #c5f9e8;
	font-weight: 600;
	box-shadow: none;
	border-radius: 50px;
} */
@media (max-width: 991px) {
	.thankyou .happyShopping {
		width: 100%;
	}
}
