.Membership-page {
	padding: 80px 0px;
	background-image: url("../img/bgDesign.png");
	background-size: 100% 100%;
	background-attachment: fixed;
	background-size: 100%;
}

.Membership-page .main-Title {
	color: #286152;
	font-size: 44px;
	font-weight: bold;
	text-align: center;
	font-family: ZangeziSans07_TRIAL;
}

.Membership-page .main-para {
	font-size: 16px;
	color: #0000008f;
	font-family: SF UI Display;
	text-align: center;
	margin-bottom: 35px;
	max-width: 65%;
	margin: 0 auto 35px;
}

/* .Membership-page .formContainer {
	padding: 20px 170px;
} */

.Membership-page .formContainer .sub-title {
	font-size: 24px;
	color: #2a263d;
	font-family: "SF UI Display";
	font-weight: 500;
	margin: 0;
	margin-bottom: 22px;
	margin-top: 22px;
}

.Membership-page .formContainer .sub-title-sm {
	font-size: 15px;
	color: #2a263d85;
	font-family: "SF UI Display";
	margin: 0;
	margin-top: 28px;
}
.selectamoutdiv2 {
	background-color: #286152;
	left: -22px;
	position: relative;
	width: 110%;
}

.selectamoutdiv23 {
	background-color: transparent;
	left: 0px;
	position: relative;
	width: 110%;
}

.selectamoutdiv2 input {
	position: relative;
	left: 35px;
}

.selectamoutdiv23 input {
	margin-top: 10px;
}

.selectamoutdiv {
	border-radius: 25px;
}

.week_Amount {
	color: #000;
}

.checkleft {
	margin-left: 10px;
}

.weekDollartxt11 p {
	color: #000;
	margin-left: -15px;
}

.weekDollartxt11 .ween {
	margin-left: 4px;
}

.weekDollartxt11 .ween2 {
	margin-left: -1px;
}

.weekDollartxt {
	color: #f1efde;
}

.week_Amount {
	display: flex;
	color: #f1efde;
	text-align: start;
	gap: 30px;
	margin-left: 3rem;
}

.selectamoutdiv .form-check .form-check-input {
	float: left;
	margin-left: 1em;
	margin-top: 8px;
}
.week_Amount p.weekDollartxt {
	margin-right: 2rem;
}
.week_Amount .weekDollar {
	/* margin-left: 83px; */
}
.week_Amount p {
	margin-bottom: 0;
}
.Membership-page button {
	color: #fff;
	background-color: #286152;
	border-radius: 36px;
	padding: 7px 25px;
}

.Membership-page button:hover {
	background-color: #000000;
	color: #fff;
}

.Membership-page .formContainer .card-type {
	display: flex;
	max-width: 50%;
}

.membership-card {
	margin: 35px 0 35px;
}

.Membership-page .formContainer .card-type span.checkmark figure {
	height: 35px;
	line-height: 35px;
	text-align: center;
	margin-right: 10px;
	width: 75px;
	overflow: hidden;
}

.membership-card .cardStyle {
	height: 60vh;
	border-left: 2px solid #707070 !important;
	border-right: 2px solid #707070 !important;
}

.membership-card .cardStyle2 {
	height: 60vh;
}

.membership-card .card {
	background-color: #f1efde;
	display: flex;
	flex-direction: column;
	align-items: center;
	border: none;
}

.membership-card .card h4 {
	color: #286152;
	text-align: center;
	font-family: ZangeziSans07_TRIAL;
}

.card img {
	width: 15vw;
}

.membership-card .main-para12 {
	font-size: 16px;
	font-family: SF UI Display;
	text-align: center;
	margin-bottom: 35px;
	padding: 0 13px;
}

.set-paymentDetail {
	display: flex;
	flex-direction: column;
}

.set-paymentDetail .form-check-input[type="checkbox"] {
	border-radius: 1.25em;
}

.forms-set {
	margin: 15px 0;
	padding: 6px;
}

.labeltext {
	color: #2a263d !important;
}

.first-payment-input span {
	color: #2a263d;
	font-family: SF UI Display;
	font-size: 12px;
}

.first-payment-input input {
	width: 100%;
	background-color: #f1efde;
	border: unset !important;
	padding: 4px 8px;
	border-bottom: 1px solid #707070 !important;
	padding-top: 20px;
}

.set-paymentDetail2 {
	display: flex;
	flex-direction: row;
	gap: 15px;
}

.set-maindiv .card-type {
	display: flex;
	max-width: 50%;
	margin-top: 40px;
}
.set-maindiv .card-type input:checked ~ span.checkmark figure {
	box-shadow: 0px 0px 0px 1px #fdae25;
}
.set-maindiv card-type span.checkmark figure {
	height: 35px;
	line-height: 35px;
	text-align: center;
	margin-right: 10px;
	width: 75px;
	overflow: hidden;
}

@media (max-width: 1280px) {
	.set-paymentDetail {
		margin-left: -26px;
		padding: 9px 0px;
	}
	.membership-card .cardStyle {
		height: 56vh;
	}
}

@media (max-width: 1024px) {
	.week_Amount p.weekDollartxt {
		margin-right: 0rem;
	}
	.weekDollartxt11 .ween2 {
		margin-left: -9px;
	}
	.week_Amount p {
		margin-bottom: 0;
		font-size: 15px;
	}
}

@media (max-width: 820px) {
	.Membership-page .main-para {
		font-size: 16px;
		max-width: 88%;
	}
	.membership-card .main-para12 {
		font-size: 16px;
		width: 90%;
	}
	.membership-card .cardStyle2 {
		height: 32vh;
	}
	.membership-card .cardStyle {
		height: 32vh;
	}
	.set-paymentDetail {
		width: 91%;
		margin-left: 15px;
	}
	.membership-card .card {
		margin-bottom: 15px;
	}
}

@media (max-width: 787px) {
	.Membership-page {
		background: none;
	}
	.reviews_sec {
		margin-top: 0;
	}
}

@media (max-width: 764px) {
	.bottom-container section.ClientsReviewsSec div#reviews .row.bottom_contain {
		flex-direction: column-reverse;
	}
	.Membership-page .main-para {
		max-width: 78%;
	}
	.membership-card .cardStyle {
		height: 39vh;
	}
	.membership-card .card {
		margin-bottom: 52px;
	}
	.Membership-page .main-Title {
		font-size: 39px;
	}
	.set-paymentDetail {
		margin-left: 30px;
		margin-bottom: 26px;
	}
}

@media (max-width: 414px) {
	.margin-settin {
		margin-left: -30px;
	}
	.membership-card .cardStyle2 {
		height: 33vh;
	}
	.Membership-page .main-Title {
		font-size: 36px;
	}
}

@media (max-width: 375px) {
	.margin-settin {
		margin-left: -30px;
	}
	.profile .tabContainer .nav-link:nth-child(1) {
		min-width: 80px;
	}
	.profile .tabContainer .nav-link:nth-child(2) {
		min-width: 90px;
	}
	.profile .tabContainer .nav-link:nth-child(3) {
		min-width: 85px;
	}
	.profile .tabContainer .nav-link:nth-child(4) {
		min-width: 95px;
	}
	.profile .tabContainer .nav-link:nth-child(5) {
		min-width: 115px;
	}
	.profile .tabContainer .nav-link:nth-child(6) {
		min-width: 105px;
	}
	.profile .tabContainer .nav-link:nth-child(7) {
		min-width: 70px;
	}

	.membership-card .cardStyle2 {
		height: 39vh;
	}
	.Membership-page .main-Title {
		font-size: 33px;
	}
	.selectamoutdiv2 {
		left: -34px;
	}
	.Membership-page {
		padding: 57px 0px;
	}
	.selectamoutdiv23 {
		left: -11px;
		width: 100%;
	}
	.Membership-page .main-para {
		font-size: 15px;
	}
}

@media (max-width: 320px) {
	.Membership-page {
		padding: 31px 0px;
	}
	.Membership-page .main-para {
		font-size: 15px;
	}
	.membership-card .cardStyle2 {
		height: 40vh;
	}
	.Membership-page .main-Title {
		font-size: 26px;
	}
}
