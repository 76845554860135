.OurDonate-page {
	padding: 80px 0px;
	background-image: url("../img/bgDesign.png");
	background-size: 100% 100%;
	/* background-attachment: fixed; */
	background-size: 100%;
}

.main-Title {
	color: #286152;
	font-size: 50px;
	font-weight: bold;
	font-family: ZangeziSans07_TRIAL;
}
.main-Title2 {
	color: #286152;
	font-size: 30px;
	margin: 26px auto;
	text-align: center;
	font-weight: bold;
	font-family: ZangeziSans07_TRIAL;
}

.main-para {
	font-size: 12px;
	color: #0000008f;
	font-family: "SF UI Display";
	text-align: center;
}
.main-para2 {
	font-size: 12px;
	color: #0000008f;
	font-family: "SF UI Display";
	text-align: center;
	align-items: center;
	margin: 0 auto;
	width: 60%;
}

.amount-set {
	display: flex;
	flex-direction: row;
	width: 70%;
	text-align: center;
	font-family: "SF UI Display";
	font-weight: 600;
	margin-bottom: 10px;
}

.first-amount {
	width: 50%;
	background-color: #95b1aa;
	padding: 5px;
	color: #fff;
	border-top-left-radius: 100px;
	border-bottom-left-radius: 100px;
}

.second-amount {
	width: 50%;
	padding: 5px;
	border-top-right-radius: 100px;
	border-bottom-right-radius: 100px;
	background-color: #fff;
	color: #000;
}

.donated-amount {
	display: flex;
	flex-direction: column;
	width: 90%;
	font-family: "SF UI Display";
	font-weight: 600;
	text-align: left;
	justify-content: center;
}

.donated-amount .goal {
	width: 31%;
	margin-left: 20px;
}

.ImagesPlants img {
	height: 358px;
	object-fit: cover;
	border-radius: 12px;
}

.border-set {
	border-bottom: 1px solid #707070;
	border-top: 1px solid #707070;
	padding-top: 5px;
	padding-bottom: 5px;
}

.goal-amount p,
.doners-amount p {
	font-size: 15px;
	color: #0000008f;
	font-family: "SF UI Display";
	margin-bottom: 10px;
	margin-bottom: 0;
}

.goal-amount h6,
.doners-amount h6 {
	font-size: 15px;
	font-family: "SF UI Display";
	font-weight: 600;
}

.doners-btn {
	color: #fff;
	background: #286152;
	padding: 5px 16px;
	border-radius: 20px;
}

.doners-btn:hover {
	background-color: #000;
	color: #fff;
}

.zaam {
	display: flex;
	justify-content: space-between;
	margin-top: 40px;
}

.main-donour img {
	width: 180px;
	border-radius: 50%;
	margin-right: 10px;
	object-fit: cover;
}

.container .btn2 {
	align-items: center !important;
	justify-content: center !important;
	display: block !important;
	margin: 50px auto 0 !important;
}

.circle-div {
	position: relative;
}

.circle-div .left-circle {
	position: absolute;
	top: 0;
	left: 9px;
}

.circle-div .left-circle p {
	font-size: 13px;
	font-family: "SF UI Display medium";
	color: #286152;
	margin-top: 46px;
	margin-bottom: 3px;
	padding-top: -8px;
}
.circle-div .left-circle h4 {
	font-size: 15px;
	font-family: "SF UI Display bold";
	color: #286152;
}

.circle-div .right_circle {
	position: absolute;
	top: 20px;
	right: 10px;
}

.circle-div .right_circle p {
	font-size: 13px;
	font-family: "SF UI Display medium";
	color: #fff;
	margin-top: 46px;
	margin-bottom: 3px;
	padding-top: -8px;
	letter-spacing: 1px;
}
.circle-div .right_circle h4 {
	font-size: 15px;
	font-family: "SF UI Display bold";
	color: #fff;
}

@media (max-width: 1280px) {
	.circle-div .right_circle {
		position: absolute;
		top: 44px;
		right: 10px;
	}
	.circle-div .left-circle {
		position: absolute;
		top: 0;
		left: 0px;
	}
}

@media (max-width: 1200px) {
	.circle-div .left-circle {
		position: absolute;
		top: -5px;
		left: 9px;
	}
	.circle-div .left-circle p {
		font-size: 10px;
	}
	.circle-div .left-circle h4 {
		font-size: 12px;
	}
	.circle-div .right_circle {
		position: absolute;
		top: 15px;
		right: 10px;
	}
	.circle-div .right_circle p {
		font-size: 10px;
	}
	.circle-div .right_circle h4 {
		font-size: 12px;
	}
}

@media (max-width: 1024px) {
	.amount-set {
		width: 95%;
	}
	.CircularProgressbar {
		width: 188px;
		margin-left: -19px;
		vertical-align: middle;
	}
	.circle-div .right_circle {
		position: absolute;
		top: 47px;
		right: 27px;
	}
	.circle-div .left-circle {
		position: absolute;
		top: 0px;
		left: 7px;
	}
	.circle-div .left-circle p {
		font-size: 14px;
	}
	.circle-div .left-circle h4 {
		font-size: 14px;
	}

	.circle-div .right_circle p {
		font-size: 14px;
	}
	.circle-div .right_circle h4 {
		font-size: 14px;
	}
	.doners-btn {
		margin: 20px 0px 0px 0px;
	}
}

@media (max-width: 991px) {
	.amount-set {
		width: 29%;
		margin-bottom: 22px;
	}
	.zaam {
		display: flex;
		justify-content: space-evenly;
		margin-top: 40px;
		flex-wrap: wrap;
		/* margin-top: 9px; */
	}
}

@media (max-width: 990px) {
	.circle-div .left-circle {
		position: absolute;
		top: 10px;
		left: 15px;
	}
	.circle-div .left-circle p {
		font-size: 15px;
	}
	.circle-div .left-circle h4 {
		font-size: 17px;
	}
	.circle-div .right_circle {
		position: absolute;
		top: 50px;
		right: 20px;
	}
	.circle-div .right_circle p {
		font-size: 15px;
	}
	.circle-div .right_circle h4 {
		font-size: 17px;
	}
	.doners-btn {
		margin: 20px 0px 0px 0px;
	}
}

@media (max-width: 820px) {
	.amount-set {
		margin-left: 25px;
	}
	.donated-amount {
		margin-left: 44px;
	}
	.doners-btn {
		margin: 20px 0px 0px 26px;
	}
	.circle-div .left-circle {
		position: absolute;
		top: 10px;
		left: -2px;
	}
}

@media (max-width: 787px) {
	.amount-set {
		width: 33%;
		margin-left: 14px;
	}
}

@media (max-width: 768px) {
	.CircularProgressbar {
		margin-left: 99px;
	}
	.circle-div .right_circle {
		position: absolute;
		top: 50px;
		right: -135px;
	}
	.circle-div {
		width: 60% !important;
		margin-left: 130px;
		margin-bottom: 10px;
	}
	.circle-div .left-circle {
		position: absolute;
		top: -1px;
		left: 102px;
	}
	.circle-div .left-circle p {
		font-size: 15px;
	}
	.circle-div .left-circle h4 {
		font-size: 20px;
	}

	.circle-div .right_circle p {
		font-size: 15px;
	}
	.circle-div .right_circle h4 {
		font-size: 20px;
	}
	.OurDonate-page {
		background: none;
	}
	.zaam {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 20px;
	}

	.main-Title {
		font-size: 34px;
		margin-top: 17px;
	}
	.donated-amount .goal {
		width: 14%;
		margin-left: 20px;
	}
	.donated-amount {
		display: flex;
		flex-direction: column;
		width: 100%;
		font-family: "SF UI Display";
		font-weight: 600;
		text-align: left;
		justify-content: center;
		align-items: center;
		margin-bottom: 15px;
	}

	.doners-btn {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 6px auto;
	}
}
@media (max-width: 575px) {
	.circle-div {
		width: 50% !important;
		margin-left: 100px;
		margin-bottom: 25px !important;
	}
	.circle-div .left-circle {
		position: absolute;
		top: 20px;
		left: 12px;
	}
	.circle-div .left-circle p {
		font-size: 13px;
	}
	.circle-div .left-circle h4 {
		font-size: 17px;
	}
	.circle-div .right_circle {
		position: absolute;
		top: 50px;
		right: 17px;
	}
	.circle-div .right_circle p {
		font-size: 13px;
	}
	.circle-div .right_circle h4 {
		font-size: 17px;
	}
	.OurDonate-page {
		background: none;
	}
	.amount-set {
		height: 200px;
		margin: auto;
	}

	.donated-amount .goal {
		width: 14%;
		margin-left: 20px;
	}
	.donated-amount {
		display: flex;
		flex-direction: column;
		width: 100%;
		font-family: "SF UI Display";
		font-weight: 600;
		text-align: left;
		justify-content: center;
		align-items: center;
	}
}

@media (max-width: 540px) {
	.circle-div {
		width: 50% !important;
		margin-left: 100px;
		margin-bottom: 25px !important;
	}
	.circle-div .left-circle {
		position: absolute;
		top: 7px;
		left: 8px;
	}
	.circle-div .left-circle p {
		font-size: 13px;
	}
	.circle-div .left-circle h4 {
		font-size: 15px;
	}
	.circle-div .right_circle {
		position: absolute;
		top: 30px;
		right: 15px;
	}
	.circle-div .right_circle p {
		font-size: 13px;
	}
	.circle-div .right_circle h4 {
		font-size: 15px;
	}
}

@media (max-width: 480px) {
	.circle-div {
		width: 50% !important;
		margin-left: 100px;
		margin-bottom: 25px !important;
	}
	.circle-div .left-circle {
		position: absolute;
		top: 0px;
		left: 6px;
	}
	.circle-div .left-circle p {
		font-size: 13px;
	}
	.circle-div .left-circle h4 {
		font-size: 15px;
	}
	.circle-div .right_circle {
		position: absolute;
		top: 30px;
		right: 15px;
	}
	.circle-div .right_circle p {
		font-size: 13px;
	}
	.circle-div .right_circle h4 {
		font-size: 15px;
	}
}

@media (max-width: 400px) {
	.circle-div {
		width: 60% !important;
		margin-left: 50px;
		margin-bottom: 25px !important;
	}
	.circle-div .left-circle {
		position: absolute;
		top: 0px;
		left: 6px;
	}
	.circle-div .left-circle p {
		font-size: 13px;
	}
	.circle-div .left-circle h4 {
		font-size: 15px;
	}
	.circle-div .right_circle {
		position: absolute;
		top: 30px;
		right: 15px;
	}
	.circle-div .right_circle p {
		font-size: 13px;
	}
	.circle-div .right_circle h4 {
		font-size: 15px;
	}
}

@media (max-width: 414px) {
	.CircularProgressbar {
		margin-left: -138px;
	}
	.circle-div .left-circle {
		left: -57px;
	}

	.donated-amount {
		margin-left: -25px;
	}
	.amount-set {
		width: 95%;
	}
	.circle-div .right_circle {
		right: 78px;
	}

	.circle-div {
		margin-left: 151px;
	}
}

@media (max-width: 393px) {
	.donated-amount {
		margin-left: -14px;
		margin-top: 13px;
	}
	.circle-div .right_circle {
		right: 82px;
		top: 45px;
	}

	.circle-div .left-circle {
		left: -57px;
	}
}

@media (max-width: 390px) {
	.CircularProgressbar {
		margin-left: -99px;
	}

	.circle-div .left-circle {
		left: -31px;
	}
	.circle-div {
		margin-left: 110px;
	}
	.circle-div .right_circle {
		right: 65px;
		top: 53px;
	}
}

@media (max-width: 375px) {
	.CircularProgressbar {
		width: 188px;
		margin-left: -79px;
	}
	.circle-div .right_circle {
		top: 30px;
		right: -26px;
	}
	.circle-div .left-circle {
		left: -63px;
	}
	.donated-amount {
		margin-left: -12px;
	}
}

@media (max-width: 360px) {
	.circle-div .left-circle {
		left: -32px;
	}

	.circle-div .right_circle {
		top: 40px;
		right: 55px;
	}
}

@media (max-width: 346px) {
	.circle-div {
		width: 60% !important;
		margin-left: 50px;
		margin-bottom: 25px !important;
	}
	.circle-div .left-circle {
		position: absolute;
		top: 0px;
		left: 6px;
	}
	.circle-div .left-circle p {
		font-size: 10px;
	}
	.circle-div .left-circle h4 {
		font-size: 10px;
	}
	.circle-div .right_circle {
		position: absolute;
		top: 20px;
		right: 15px;
	}
	.circle-div .right_circle p {
		font-size: 10px;
	}
	.circle-div .right_circle h4 {
		font-size: 10px;
	}
	.main-donour img {
		width: 140px;
	}
	.doners-btn {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 19px auto !important;
	}
}

@media (max-width: 320px) {
	.amount-set {
		width: 84%;
		margin-left: 14px;
	}
	.circle-div .right_circle {
		right: 52px;
	}
	.circle-div {
		margin-left: 92px;
	}
	.circle-div .left-circle {
		left: -18px;
	}
	.main-para2 {
		width: 100%;
	}
}
