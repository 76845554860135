.imageContainer {
	background-image: url("../img/bgDesign.png");
	background-size: 100% 100%;
	background-attachment: fixed;
	background-size: 100%;
}

.tourists-title {
	color: #286152;
	font-size: 44px;
	font-weight: bold;
	margin-bottom: 20px;
	font-family: ZangeziSans07_TRIAL;
	margin: 20px 0;
}

.main-touristlover-div p {
	font-family: SF UI Display;
	color: #0000009f;
}
