/* profile section css starts here */

.bg-header {
	background: #fff;
}
.bg-header ul.navbar-nav li.nav-item a {
	color: #000;
}
.profile-row .order-table-wrapper table {
	width: 99% !important;
	margin-left: 3px;
}
figure {
	margin: 0;
}

.profile-img .profile-upload {
	height: 100%;
	width: 100%;
	position: absolute;
	right: 0px;
	opacity: 0;
}
/* .profile-img .edit-container {
    background-color: #f7f7f7;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    position: absolute;
    margin-left: 80px;
    margin-top: 90px;
    box-shadow: 2px 2px 2px rgb(175 174 174 / 25%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .profile-img .edit-container input {
    position: absolute;
    margin-left: 1px;
    width: 24px;
    opacity: 0;
  } */

.profile .tabContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #296253;
	padding: 8px 8px;
	color: #fff;
	border-top-right-radius: 12px;
	border-top-left-radius: 12px;
}
.profile .tabContainer .nav-link {
	display: block;
	padding: unset;
	color: #a7a6a6;
}

.profile .nav-link.active {
	color: #fff;
}

.profile a {
	outline: none !important;
}
.profile {
	padding: 80px 0px;
	background-image: url("../img/bgDesign.png");
	background-size: 100% 100%;
	background-attachment: fixed;
	background-size: 100%;
}
.profile h2.profile-heading {
	color: #2a263d;
	font-size: 43px;
	font-weight: 700;
}
.profile h3.username {
	color: #000000;
	font-family: "SF UI Display";
	font-size: 25px;
	margin-bottom: 30px;
	font-weight: bold;
}
.profile button.logout-btn {
	color: #fff;
	background-color: #286152;
	border-radius: 36px;
	padding: 7px 15px;
	text-transform: uppercase;
}

.profile button.logout-btn:hover {
	background-color: #000000;
	color: #fff;
}

.profile button.logout-btn2 {
	color: #fff;
	background-color: #286152;
	border-radius: 36px;
	padding: 7px 15px;
	text-transform: uppercase;
}

.profile button.logout-btn2:hover {
	background-color: #000000;
	color: #fff;
}

.profile .rightSide-profile {
	padding: 47px 62px;
}

.profile .input-box {
	border-bottom: 1px solid #c3c3c3;
	margin-bottom: 28px;
}

.profile .tab-pane {
	border: 2px solid #5252524d;
	border-top-right-radius: 15px;
	border-top-left-radius: 15px;
}
.Accounts {
	/* padding-top: 40px; */
	display: flex;
	justify-content: space-between;
}
.address23 {
	margin: 41px 0px;
}
.Accounts2 {
	/* padding-top: 40px; */
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
}

.input_data {
	border-radius: 0px;
	border: 1px solid #b8b8b8;
	width: 82%;
}

.address {
	padding: 30px 0px;
}
.profile li.nav-item a {
	font-size: 16px;
	color: #2a263d;
	font-family: "Montserrat";
	font-weight: 600;
	display: block;
}
.form-group input {
	background-color: transparent;
	border: none;
}
.form-group label {
	font-size: 15px;
	color: #2a263d;
	font-family: "DM Sans";
	font-weight: 400;
}

.form-group input::placeholder {
	color: #000;
}

.password-btn button {
	font-size: 16px;
	color: #296253;
	font-family: "SF UI Display";
	font-weight: bold;
	text-decoration: underline;
	background: transparent;
	border: none;
}
.for-allign {
	display: flex;
	align-items: center;
}

.first-name h4 {
	font-size: 15px;
	color: #2a263da1;
	font-family: "SF UI Display";
	font-weight: 400;
	margin-bottom: 18px;
}
.first-name h3 {
	font-size: 20px;
	color: #2a263d;
	font-family: "DM Sans";
	font-weight: 500;
}
.phone h3 a {
	font-size: 20px;
	color: #2a263d;
	font-family: "DM Sans";
	font-weight: 500;
}

.Accounts h5 {
	font-size: 25px;
	color: #000000;
	font-family: "SF UI Display";
	padding-bottom: 10px;
	font-weight: bold;
}
.address h4 {
	font-size: 15px;
	color: #2a263d;
	font-family: "DM Sans";
	font-weight: 400;
}
.address h3 {
	font-size: 20px;
	color: #2a263d;
	font-family: "DM Sans";
	font-weight: 500;
}
.profile .img-and-username-wrapper {
	padding: 0px 12px;
	text-align: center;
	border-bottom: 1px solid #f2f2f2;
	border-radius: 0;
}

.profile .img-and-username-wrapper .mainTitle {
	font-size: 30px;
	font-family: "ZangeziSans07_TRIAL";
	color: #286152;
	margin-bottom: 35px;
}
.profile-tabs-wrapper {
	padding: 10px 0px;
	border: 1px solid #f2f2f2;
}
.profile-tabs-wrapper .nav-pills .nav-link.active {
	background: transparent;
	color: inherit;
}
.wallet-balance-wrapper h3 {
	font-size: 16px;
	font-weight: 500;
	color: #2a263d;
	font-family: "DM Sans";
	line-height: 1;
}
.wallet-balance-wrapper h3 span {
	color: #a6674a;
	font-size: 20px;
	font-family: "DM Sans";
	font-weight: bold;
}
.wallet-balance-wrapper h3 sub {
	color: #a6674a;
	font-size: 10px;
	font-family: "DM Sans";
	font-weight: 400;
	margin-top: -10px;
	display: inline-block;
}

.wallet-balance-wrapper {
	background: #fff9f6;
	padding: 9px;
}
.profile .profile-row {
	padding-top: 0px;
	padding-bottom: 69px;
}
.profile-img {
	position: relative;
}
.profile-img .edit-img a {
	position: absolute;
	right: 23%;
	bottom: 2px;
	background: #eeeeee;
	padding: 8px;
	display: flex;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	justify-content: center;
	align-items: center;
	font-size: 21px;
	color: #a6674a;
}
.profile .profile-links-wrapper {
	padding: 30px;
	padding-bottom: 81px;
}
.profile .profile-links-wrapper a.nav-link {
	color: #2a263d !important;
	font-family: "AvegasRoyale-reg";
	font-size: 20px;
	line-height: 36px;
	font-weight: 400;
	padding: 3px 5px;
}

.order-details-btn {
	cursor: pointer;
}
.profile-links-wrapper .active {
	text-decoration: underline;
	color: #920c22 !important;
	background: transparent;
}
.profile-img a.chnage {
	position: absolute;
	bottom: 0px;
	left: 0px;
	right: 0px;
	background: #296253;
	height: 20px;
	font-family: "Montserrat";
	font-weight: 600;
	color: #fff;
	font-size: 7px;
	text-decoration: underline;
	display: flex;
	justify-content: center;
	align-items: center;
}
.profile-img figure {
	position: relative;
	overflow: hidden;
	display: inline-block;
	border: 3px solid #286152;
	height: 180px;
	width: 180px;
	object-fit: contain;
	border-radius: 8px;
}
.profile-img figure img {
	width: 100%;
	height: 100%;
}

.profile-img {
	position: relative;
	/* margin-top: -130px; */
}

/* Scanner Screen */
p.Scanner_paragharaf {
	font-family: "SF UI Display";
	font-size: 15px;
	color: #54544e;
}
/* Scanner Screen */

.member_num {
	color: #286152;
	font-size: 18px;
	font-family: "SF UI Display";
}

@media (max-width: 926px) {
	.edit-img {
		position: relative;
	}
	.profile-img .edit-img a {
		right: 43%;
	}
}
@media (max-width: 630px) {
	.edit-img {
		position: relative;
	}
	.profile-img .edit-img a {
		right: 43%;
		bottom: -9px;
	}
}
/* account form css starts here */
.account-form-wrapper input {
	border-radius: 0px;
	border: 1px solid #b8b8b8;
}
.profile .account-form-wrapper label {
	color: #2a263d;
	font-family: "Montserrat";
	font-size: 15px;
	font-weight: 500;
}
.profile .account-form-wrapper button.btn-submit {
	color: #fff;
	background-color: #286152;
	border-radius: 36px;
	padding: 7px 15px;
	width: 76%;
	float: right;
	text-transform: uppercase;
}

.profile .heading-top-cont {
	margin-left: 26px;
	margin-top: 24px;
	margin-bottom: 16px;
}

.Scanner_div {
	margin: 0px 20px 0px 40px;
}

.heading-top-Scanner {
	margin-left: 40px;
	margin-top: 24px;
	margin-bottom: 16px;
}

h3.form-heading {
	font-size: 25px;
	color: #2a263d;
	font-family: "SF UI Display";
	font-weight: bold;
	margin-bottom: 0px;
}
.account-form-wrapper form {
	margin-top: 34px;
}
/* account form css ends here */
/* order table starts here */
.order-table-wrapper {
	padding-top: 5px;
}
.profile .order-table-wrapper tr.spacer {
	height: 10px;
	border-right: 0;
	border-left: 0;
}
.order-table-wrapper tr.row1 th {
	padding: 12px 0px;
	text-align: center;
	font-weight: 700;
	font-size: 18px;
	color: #2a263d;
	font-family: "SF UI Display";
	border-bottom: 1px solid #c7c7c79c;
	border-top: 1px solid #c7c7c79c;
}
.order-table-wrapper .confirm-cancel a {
	color: #b2b2b2 !important;
}
.profile-row .pagination-wrapper {
	margin-top: 60px;
}
.order-table-wrapper tr.row2 td {
	padding: 20px 10px;
}
.profile .order-table-wrapper tr.row2 td {
	border-bottom: 1px solid #c7c7c79c;
	/* border-top: 1px solid #c7c7c79c; */
	font-family: "SF UI Display";
	color: #2a263d;
	text-align: center;
}
.profile .order-table-wrapper tr.row2 td.confirm {
	color: #00bc26;
}
.profile .order-table-wrapper tr.row2 td.confirm i {
	font-size: 20px;
	margin-left: 5px;
}
.profile .order-table-wrapper tr.row2 td.paid.pending i {
	font-size: 20px;
	margin-left: 24px;
}
.profile .order-table-wrapper tr.row2 td.pending {
	color: #c31b01;
}
.security-form-wrapper {
	padding: 0px 0px 0px 46px;
	padding-top: 0px !important;
	margin-bottom: 35px;
}
.order-table-wrapper td.cancel a {
	color: #a6674a;
	font-family: "DM Sans";
	text-decoration: underline;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 500;
}
.profile .order-table-wrapper tr.row2 td:first-child {
	/* border-left: 1px solid #c7c7c79c; */
}
.profile .order-table-wrapper tr.row2 td:last-child {
	/* border-right: 1px solid #c7c7c79c; */
}

.Book_range_center {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 14px 166px;
}

.Book_range_center .priceRate {
	margin-right: 6px;
}

.priceRate {
	font-family: "ZangeziSans07_TRIAL";
	color: #c40000;
}

.priceRate img {
	width: 17px;
	margin-top: -7px;
	margin-right: -5px;
}

.order-table-wrapper .row2 td {
	color: #2a263d;
	font-family: "Montserrat";
	font-size: 16px;
}
.profile .order-table-wrapper td.paid {
	color: #00bc26;
}
.order-table-wrapper .order-details a {
	color: #2692ff !important;
	font-family: "SF UI Display";
	text-decoration: underline;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 500;
}
.pagination-wrapper a.page-link {
	display: flex;
	line-height: 0px;
	height: unset;
	margin: 5px;
	padding: 14px;
	justify-content: center;
	font-family: "DM Sans";
	font-size: 18px;
	color: #6e6e6e;
	font-weight: 400 !important;
	font-style: normal !important;
	border: 0px;
}
.pagination li a i {
	line-height: 0px;
}

ul.pagination li.page-item:first-child a.page-link,
ul.pagination li.page-item:last-child a.page-link {
	border-radius: 5px !important;
	background: #296253;
	color: #fff;
	border: 0px;
	font-size: 25px;
}
.Total-Product {
	/* align-items: center; */
	/* margin: 0 auto; */
	font-family: "Gotham";
	font-size: 12px;
	color: #666666;
	justify-content: center;
	display: flex;
}
.pagination-wrapper .active a.page-link {
	color: #fff;
	background: #a6674a;
	border: 1px solid #a6674a;
}
/* order responsive start here */
@media (max-width: 1100px) {
	.order-table-wrapper tr.row1 th {
		padding: 23px 9px;
		font-size: 14px;
	}
	.order-table-wrapper .row2 td {
		font-size: 14px;
	}
}
@media (max-width: 1018px) {
	.order-table-wrapper .row2 td {
		font-size: 13px;
	}
}
@media (max-width: 700px) {
	.order-table-wrapper tr.row2 td {
		padding: 9px 7px;
	}
}
/* order responsive ends here */
/* order table ends here */
/* modal css starts here */
.profile-row button.close span {
	color: #a6674a;
}
.profile .modal-dialog {
	max-width: 41%;
}
.close {
	opacity: 1;
}
.modal-header .close {
	margin: 0;
}
.model-header {
	text-align: center;
	padding: 20px 11px;
}
.profile-row h5#exampleModalLongTitle {
	font-size: 43px;
	font-family: "Montserrat";
	color: #a6674a;
}
.profile-row .model-header h6 {
	color: #2a263d;
	font-family: "Montserrat";
	font-size: 37px;
	font-weight: 400;
}
.model-header button.close {
	margin-top: -92px;
	margin-right: 24px;
}
.product-deatail-wrapper {
	padding: 0px 30px;
	display: flex;
	width: 100%;
	align-items: center;
}
.modal-open .modal {
	overflow: scroll !important;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none;
}
.modal-open .modal::-webkit-scrollbar {
	display: none;
}
.product-img {
	flex: 2;
}
.product-content {
	flex: 5;
}
.product-price {
	flex: 1;
}
.profile .modal-body h6 {
	color: #a6674a;
	font-family: "Montserrat";
	font-size: 20px;
}
h6.product-price {
	font-size: 21px;
	font-weight: 600;
}
h6.product-size {
	color: #1f2c35 !important;
	font-weight: 400;
	font-size: 17px !important;
}
h5.product-name {
	font-size: 16px;
	font-weight: 500;
}
.product-deatail-wrapper {
	margin: 20px auto !important;
	width: 80%;
}
.product-deatail-wrapper.active {
	border-right: 3px solid #ef5d61;
	margin: 10px 0px;
}
.order-details-wrapper {
	padding: 0px 32px;
}

.order-details-wrapper h6 {
	display: flex;
	justify-content: space-between;
	color: #000000 !important;
	font-size: 17px !important;
	font-weight: 500;
}

.headbtn {
	margin-top: 10px !important;
}
.myaddress_page {
	padding: 36px 0px;
}
.set-row {
	padding-bottom: 34px !important;
}
.order-details-wrapper {
	padding: 0px 80px;
}
/* responsive starts here */
@media (max-width: 1345px) {
	.profile .modal-dialog {
		max-width: 51% !important;
	}
}
@media (max-width: 1130px) {
	.profile .modal-dialog {
		max-width: 61% !important;
	}
}
@media (max-width: 1280px) {
	.headbtn {
		margin-top: 10px !important;
	}
	.myaddress_page {
		padding: 36px 0px;
	}
	.set-row {
		padding-bottom: 34px !important;
	}
}
@media (max-width: 1024px) {
	.profile .cancel-memberBtn {
		width: 77% !important;
	}
	.profile .modal-dialog {
		max-width: 71% !important;
	}
	.headbtn {
		margin-top: 10px !important;
	}
	.myaddress_page {
		padding: 36px 0px;
	}
	.set-row {
		padding-bottom: 34px !important;
	}
}
@media (max-width: 936px) {
	.profile .modal-dialog {
		max-width: 71% !important;
	}
	.headbtn {
		margin-top: -134px !important;
	}
	.myaddress_page {
		padding: 36px 0px;
	}
	.set-row {
		padding-bottom: 34px !important;
	}
}
@media (max-width: 822px) {
	.profile .modal-dialog {
		max-width: 81% !important;
	}
}
@media (max-width: 820px) {
	.headbtn {
		margin-top: -134px !important;
	}
	.myaddress_page {
		padding: 36px 0px;
	}
	.set-row {
		padding-bottom: 34px !important;
	}
	.profile .cancel-memberBtn {
		padding: 14px 25px;
	}
	.profile .cancel-memberBtn3 {
		width: 77% !important;
	}
}
@media (max-width: 758px) {
	.product-deatail-wrapper {
		flex-direction: column;
		align-items: center;
	}
	.product-deatail-wrapper.active {
		border-right: none;
		border-bottom: 3px solid #ef5d61;
		margin: 10px 0px;
	}
	.profile .tabContainer .nav-link {
		font-size: 12px;
	}
}
@media (max-width: 563px) {
	.profile .modal-dialog {
		max-width: 81% !important;
		margin: 30px auto;
	}
	.order-details-wrapper h6 {
		display: inline-block;
	}
	.order-details-wrapper {
		padding: 0px 52px;
		text-align: center;
	}
	/* .profile .tabContainer .nav-link {
		font-size: 7px;
	} */
}
/* responsive ends here */
/* modal css ends here */
/* wishlist css starts here */
.wishlist-table-wrapper {
	margin-top: 40px;
}
.wishlist-table-wrapper tr.row1 th {
	padding: 13px;
	background: #f2f2f2;
	font-family: "Montserrat";
	font-size: 12px;
}
.wishlist-table-wrapper tr.row1 th.wish-img {
	width: 16%;
}
.wishlist-table-wrapper td.wishlist-product h4 {
	color: #1f2c35;
	font-family: "Montserrat";
	font-size: 15px;
	font-weight: 500;
}
.wishlist-table-wrapper td.wishlist-price h6 {
	color: #ef5d61;
	font-size: 16px;
	font-family: "Montserrat";
	font-weight: 600;
}
.wishlist-table-wrapper td.wishlist-cancel i {
	color: #ef5d61;
	font-size: 23px;
}
.wishlist-table-wrapper tr.row2 td {
	margin: 0;
	padding: 0;
}
.wishlist-table-wrapper tr.row2 {
	border-bottom: 1px solid #707070;
}
.wishlist-table-wrapper tr.row2:last-child {
	border-bottom: none;
}
/* responsive css starts here */
@media (max-width: 600px) {
	.wishlist-table-wrapper tr.row1 th {
		text-align: center;
	}

	.wishlist-table-wrapper tr.row2 td {
		text-align: center;
	}
}
/* wishlist css ends here */
/* security form starts here */
.address-form-wrapper form {
	padding-right: 120px;
	padding-bottom: 20px;
}
.saved-address {
	/* padding-right: 120px; */
}
.security-form-wrapper h3.form-heading {
	padding-bottom: 30px;
}
.security-form-wrapper input {
	border-radius: 0px;
	border: 1px solid #b8b8b8;
}
.profile .account-form-wrapper label {
	color: #2a263d;
	font-family: "Montserrat";
	font-size: 15px;
	font-weight: 500;
}
.profile .security-form-wrapper button.btn-submit {
	color: #fff;
	background-color: #286152;
	border-radius: 36px;
	padding: 7px 15px;
	margin-top: 10px;
	text-transform: uppercase;
}
/* security form ends here */
/* address form starts here */
.address-form-wrapper input {
	border-radius: 0px;
	border: 1px solid #b8b8b8;
}
/* .address-form-wrapper .topbar {
    display: flex;
    align-items: end;
    justify-content: space-between;
    padding-bottom: 50px;
  } */
.profile .address-form-wrapper label {
	color: #2a263d;
	font-size: 15px;
	font-weight: 500;
}
.profile .address-form-wrapper button.btn-submit {
	background: #a6674a;
	padding: 15px 25px;
	font-family: "Montserrat";
	font-size: 14px;
	font-weight: bold;
}
/* saved adress css starts here */
/* 
    .plans {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    
      max-width: 970px;
      padding: 85px 50px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      background: #fff;
      border-radius: 20px;
      -webkit-box-shadow: 0px 8px 10px 0px #d8dfeb;
      box-shadow: 0px 8px 10px 0px #d8dfeb;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    } */

/* .plans .plan input[type="radio"] {
    position: absolute;
    opacity: 0;
  } */

.plans .plan {
	cursor: pointer;
	width: 100%;
}

.plans .plan .plan-content {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between;
	padding: 23px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border: 2px solid #707070;
	border-radius: 0px;
	-webkit-transition: -webkit-box-shadow 0.4s;
	transition: -webkit-box-shadow 0.4s;
	-o-transition: box-shadow 0.4s;
	transition: box-shadow 0.4s;
	transition: box-shadow 0.4s, -webkit-box-shadow 0.4s;
	position: relative;
}

.plans .plan .plan-content img {
	margin-right: 30px;
	height: 72px;
}

.plans .plan .plan-details span {
	margin-bottom: 10px;
	display: block;
	font-size: 20px;
	line-height: 24px;
	color: #252f42;
}

.container .title {
	font-size: 16px;
	font-weight: 500;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
	color: #252f42;
	margin-bottom: 20px;
}

.plans .plan .plan-details p {
	color: #646a79;
	font-size: 14px;
	line-height: 18px;
}

.plans .plan .plan-content:hover {
	-webkit-box-shadow: 0px 3px 5px 0px #e8e8e8;
	box-shadow: 0px 3px 5px 0px #e8e8e8;
}

/* .plans .plan input[type="radio"]:checked + .plan-content:after {
    content: "";
    position: absolute;
    height: 14px;
    width: 14px;
    background: #a6674a;
    right: 20px;
    bottom: 34px;
    border-radius: 100%;
    border: 3px solid #fff;
    -webkit-box-shadow: 0px 0px 0px 2px #a6674a;
    box-shadow: 0px 0px 0px 1px #a6674a;
  } */

/* .plans .plan input[type="radio"] + .plan-content:after {
      content: "";
      position: absolute;
      height: 12px;
      width: 12px; */
/* background: #F35F63; */
/* right: 20px;
      bottom: 39px;
      border-radius: 100%;
      border: 2px solid #fff;
      -webkit-box-shadow: 0px 0px 0px 2px #A6674A;
      box-shadow: 0px 0px 0px 2px #A6674A;
    } */

/* .plans .plan input[type="radio"]:checked + .plan-content {
    border: 2px solid #a6674a;
    -webkit-transition: ease-in 0.3s;
    -o-transition: ease-in 0.3s;
    transition: ease-in 0.3s;
  } */
.saved-address .edits h6 {
	margin-right: 22px;
	font-weight: 600;
	color: #000;
}
@media screen and (max-width: 991px) {
	.plans {
		margin: 0 20px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-ms-flex-align: start;
		align-items: flex-start;
		padding: 40px;
	}

	.plans .plan {
		width: 100%;
	}

	.plan.complete-plan {
		margin-top: 20px;
	}

	.plans .plan .plan-content .plan-details {
		width: 70%;
		display: inline-block;
	}

	.plans .plan input[type="radio"]:checked + .plan-content:after {
		top: 45%;
		-webkit-transform: translate(-50%);
		-ms-transform: translate(-50%);
		transform: translate(-50%);
	}
	.Book_range_center {
		padding: 14px 55px;
	}
}

@media (max-width: 768px) {
	.Total-Product {
		font-size: 24px;
	}
}

@media screen and (max-width: 767px) {
	.profile {
		background: none;
	}
	.plans .plan .plan-content .plan-details {
		width: 60%;
		display: inline-block;
	}
	.profile .order-table-wrapper tr.row2 td {
		min-height: 59px;
	}
	.profile .familyMember-Title {
		font-size: 24px;
	}
	.profile .schedule-btn {
		padding: 8px 4px;
		width: 100%;
		font-size: 12px;
	}
	.Book_range_center {
		padding: 14px 20px;
	}
}

@media screen and (max-width: 540px) {
	.plans .plan .plan-content img {
		margin-bottom: 20px;
		height: 56px;
		-webkit-transition: height 0.4s;
		-o-transition: height 0.4s;
		transition: height 0.4s;
	}

	.plans .plan input[type="radio"]:checked + .plan-content:after {
		top: 20px;
		right: 10px;
	}

	.plans .plan .plan-content .plan-details {
		width: 100%;
	}

	.plans .plan .plan-content {
		padding: 20px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: baseline;
		-ms-flex-align: baseline;
		align-items: baseline;
	}
	.profile .tabContainer {
		overflow-y: scroll;
	}
	/* .profile .tabContainer .nav-link {
		min-width: 90px;
	} */

	.profile .tabContainer .nav-link:nth-child(1) {
		width: 120px;
		min-width: 80px;
	}
	.profile .tabContainer .nav-link:nth-child(2) {
		width: 120px;
		min-width: 88px;
	}
	.profile .tabContainer .nav-link:nth-child(3) {
		min-width: 80px;
	}
	.profile .tabContainer .nav-link:nth-child(4) {
		min-width: 92px;
	}
	.profile .tabContainer .nav-link:nth-child(5) {
		min-width: 111px;
	}
	.profile .tabContainer .nav-link:nth-child(6) {
		min-width: 99px;
	}
	.profile .tabContainer .nav-link:nth-child(7) {
		min-width: 68px;
	}
	.profile .tabContainer .nav-link:nth-child(8) {
		min-width: 59px;
	}
	.Accounts h5 {
		font-size: 20px;
	}
	.order-table-wrapper tr.row1 th {
		padding: 11px 3px;
		font-size: 13px;
		min-width: 127px !important;
	}
	.profile .order-table-wrapper tr.row2 td {
		min-height: 59px;
	}
	.profile .order-table-wrapper tr.row2 td.confirm i {
		font-size: 13px;
		margin-left: 5px;
	}
	.profile .familyMember-Title {
		font-size: 24px;
	}
	.profile .schedule-btn {
		padding: 8px 4px;
		width: 100%;
		font-size: 12px;
	}
	.profile .cancel-memberBtn {
		padding: 6px 11px;
		width: 100%;
		font-size: 14px;
	}
}
.saved-address h4 {
	color: #262626;
	font-size: 20px;
}
.address-info span.phone {
	margin-left: 13px;
}
.address-info span.states {
	margin-left: 25px;
}
.edits {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.update .remove-add i {
	color: #a6674a;
	font-size: 19px;
	margin: 2px;
}
.address-info h6 {
	font-family: "Montserrat";
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 12px;
}
.edits h6 {
	font-size: 16px;
	font-family: "Montserrat";
	font-weight: 500;
}
@media (max-width: 923px) {
	.address-form-wrapper {
		padding-top: 77px;
	}
	.heading {
		padding-top: 100px;
	}
	.security-form-wrapper {
		padding-top: 90px;
	}
	div#v-pills-order h3.form-heading {
		padding-top: 90px;
	}
}
@media (max-width: 837px) {
	.plans {
		padding: 0px;
	}
}
@media (max-width: 644px) {
	.edits {
		width: 40%;
	}
}
@media (max-width: 563px) {
	.edits {
		width: 40%;
	}
}
@media (max-width: 532px) {
	.edits {
		text-align: left !important;
		width: 100%;
	}
	.edits {
		display: flex;
		flex-direction: row-reverse;
	}
	.profile .familyMember-Title {
		font-size: 25px;
	}
}
/* address form ends here */
/* / 2nd Tab Content Css Start Here / */
.profile .tab-content .card-details {
	background: #f3f3f3;
	padding: 30px 40px;
	border-radius: 20px;
}
.profile .tab-content .card-details .radios {
	opacity: 0;
}
.profile .tab-content .card-details .label figure img {
	width: 100%;
}
.profile
	.tab-content
	.card-details
	input:checked
	~ span.checkmark
	.label
	figure {
	border: 5px solid #fff;
	box-shadow: 0px 0px 0px 1px #a1c4a9;
}
.profile .tab-content .card-details .paymentCards {
	display: flex;
	align-items: center;
}
.profile .tab-content .card-details .paymentCards label {
	width: 50px !important;
	margin-right: 20px;
}
.profile .tab-content .saveCards {
	padding-top: 30px;
}
/* .Myprofile .tab-content .saveCards .Yourcards {
        background: #F3F3F3;
        padding: 50px 40px;
    } */
.profile .tab-content .Yourcards .cards .radio span.checkmark .label {
	background: transparent;
	width: 27px;
	height: 27px;
	border-radius: 50px;
	border: 2px solid #a1c4a9;
	text-align: center;
	position: relative;
	top: -7px;
}
.profile .tab-content .Yourcards .cards .radio input {
	opacity: 0;
}
.profile .tab-content .Yourcards .cards .radio span.checkmark .label i {
	background: #a1c4a9;
	width: 25px;
	height: 25px;
	line-height: 25px;
	border-radius: 50px;
	font-size: 12px;
	color: #fff;
	position: relative;
	top: -2px;
	left: -1px;
	opacity: 0;
}
.profile
	.tab-content
	.Yourcards
	.cards
	.radio
	input:checked
	~ span.checkmark
	.label
	i {
	opacity: 1;
}
.profile .tab-content .Yourcards .cards {
	display: flex;
	align-items: center;
	justify-content: left;
	margin-bottom: 20px;
}
.profile .tab-content .Yourcards .cards .persnolCard {
	display: flex;
	align-items: center;
}
.profile .tab-content .Yourcards .cards .persnolCard figure img {
	width: 50px;
	margin: 0 10px;
}
.profile .tab-content .Yourcards .cards .persnolCard .form-group label {
	font-size: 10px;
	color: #000000;
	font-family: "trade-bold";
	margin-bottom: 0;
}
.profile .tab-content .Yourcards .cards .persnolCard .form-group input {
	background: transparent;
	border: 0;
	padding: 0;
	font-size: 16px;
	font-family: "trade-bold";
	color: #000;
}
.profile.tab-content
	.Yourcards
	.cards
	.persnolCard
	.form-group
	input:focus-visible {
	outline: none;
}
.profile .tab-content .Yourcards .cards .editBtn a {
	color: #000000;
	font-size: 10px;
	font-family: "trade-reg";
	font-weight: 800 !important;
}
.card-wrapper input[type="radio"] {
	display: none;
}
.card-wrapper input[type="text"] {
	height: 51px;
}
.card-wrapper label {
	color: #2a263d;
	font-family: "Montserrat";
	font-size: 15px;
}
.card-wrapper input[type="radio"]:checked + label img {
	border: 1px solid #a6674a;
}
.card-wrapper button.btn-card {
	background: #a6674a;
	border: 0px;
	color: #fff;
	padding: 14px 51px;
	font-weight: bold;
	font-size: 13px;
	font-family: "Spartan";
}
.saveCards .card-info {
	display: flex;
	align-items: center;
}
.editss {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-right: 40px;
}
.profile .saveCards label.container {
	padding: 0;
}
.profile .saveCards label.container span.checkmark .card-options {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 1px solid #707070;
	padding: 10px 20px;
	position: relative;
}
.profile
	.saveCards
	label.container
	input:checked
	~ span.checkmark
	.card-options {
	box-shadow: 0px 0px 0px 2px #a6674a;
	border-color: #fff;
}
.profile .saveCards label.container span.checkmark .card-options .visa-card {
	display: flex;
	align-items: center;
}
.profile
	.saveCards
	label.container
	span.checkmark
	.card-options
	.visa-card
	.form-group
	label {
	font-size: 8px;
	color: #000000;
}
.profile
	.saveCards
	label.container
	span.checkmark
	.card-options
	.visa-card
	.form-group
	.form-control {
	border: 0;
	font-size: 18px;
	color: #343a40;
	height: 20px;
	padding: 0;
}
.profile
	.saveCards
	label.container
	span.checkmark
	.card-options
	.visa-card
	.img-box {
	margin-right: 20px;
}
.profile .saveCards label.container span.checkmark .card-options .action-btns {
	text-align: right;
}
.profile
	.saveCards
	label.container
	span.checkmark
	.card-options
	.action-btns
	button.delete {
	background: transparent;
	border: 0;
	padding-bottom: 10px;
}
.profile
	.saveCards
	label.container
	span.checkmark
	.card-options
	.action-btns
	button.delete
	i {
	color: #000;
	font-size: 20px;
	padding-bottom: 16px;
}
.profile
	.saveCards
	label.container
	span.checkmark
	.card-options
	.action-btns
	.use-default
	label {
	font-size: 15px;
	color: #1f2c35;
	padding-right: 60px;
}
.profile
	.saveCards
	label.container
	span.checkmark
	.card-options
	.action-btns
	.use-default
	span.check-box {
	width: 18px;
	height: 18px;
	background: #fff;
	position: absolute;
	right: 24px;
	border-radius: 50%;
	border: 3px solid #ffffff;
	box-shadow: 0px 0px 0px 1px #a6674a;
}
.profile
	.saveCards
	label.container
	input:checked
	~ span.checkmark
	.card-options
	.action-btns
	.use-default
	span.check-box {
	background: #a6674a;
}

/* member ship  */
.profile .schedule-btn {
	color: #fff;
	background-color: #286152;
	border-radius: 36px;
	padding: 7px 15px;
	width: 76%;
	float: right;
	text-transform: uppercase;
}

.profile .cancel-memberBtn {
	color: #fff;
	background-color: #286152;
	border-radius: 36px;
	padding: 7px 25px;
	width: 63%;
	margin-top: 71px;
	float: right;
	text-transform: uppercase;
	letter-spacing: 1px;
}
.profile .cancel-memberBtn:hover,
.profile .cancel-memberBtn3:hover {
	background: #000;
	color: #fff;
}
.profile .cancel-memberBtn2 {
	margin-top: 15px;
}

.profile .cancel-memberBtn3 {
	color: #fff;
	background-color: #286152;
	border-radius: 36px;
	padding: 7px 25px;
	width: 80%;
	margin-top: 15px;
	float: right;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-family: "SF UI Display medium";
	font-size: 14px;
}
.profile .cancel-memberBtn4 {
	color: #fff;
	background-color: #286152;
	border-radius: 36px;
	padding: 7px 25px;
	width: 80%;
	margin-top: 15px;
	float: right;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-family: "SF UI Display medium";
	font-size: 14px;
}
.profile .cancel-memberBtn5 {
	color: #fff;
	background-color: #bc0000;
	border-radius: 36px;
	padding: 7px 25px;
	width: 80%;
	margin-top: 15px;
	float: right;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-family: "SF UI Display medium";
	font-size: 14px;
}

.productDiv {
	display: flex;
}

.heading_div h6 {
	margin-top: -10px;
	padding-bottom: 33px;
	font-family: "SF UI Display semibold";
	color: #2a263d;
	font-size: 16px;
}
.productDiv .productDiv_text {
	margin-top: 10px;
	margin-left: 50px;
}

.productDiv .productDiv_text h4 {
	font-family: "ZangeziSans07_TRIAL";
	color: #286152;
}
.productDiv .productDiv_text p {
	font-family: "SF UI Display semibold";
	color: #2a263d;
	font-size: 13px;
}

.profile .familyMember-Title {
	color: #286152;
	font-size: 32px;
	font-family: "SF UI Display";
	margin: 0px;
}

.info-profile hr {
	width: 113%;
	position: relative;
	left: -47px;
}

.csa_Membership {
	margin-top: 50px;
}
.profile .familyMember-expiry {
	font-size: 13px;
	font-family: "SF UI Display";
	color: #2a263dbd;
	margin-bottom: 2px;
}

.profile .expiryBtn {
	font-size: 13px;
	font-family: "SF UI Display";
	color: #2a263dbd;
	margin-bottom: 13px;
}

.profile .book-details {
	color: #2a263d;
	font-size: 20px;
	font-weight: 500;
	font-family: "SF UI Display";
}

.profile .memb-cont {
	padding-top: 50px;
}

.profile .memb-cont .edit-memb {
	color: #286152;
	font-size: 15px;
	font-weight: bold;
	float: right;
}

.profile .bottom-tabs {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.profile .bottom-tabs .info-nex {
	font-size: 20px;
	color: #2a263d;
	font-weight: 500;
	font-family: "SF UI Display";
}

.profile .date-time {
	width: 100%;
	background-color: #f1efde;
	border: unset;
}

.profile select {
	width: 100%;
	background-color: #f1efde;
	border: unset;
	padding: 4px 8px;
}

/* card tab responsive starts here */
@media (max-width: 1100px) {
	.card-wrapper button.btn-card {
		padding: 14px 31px !important;
	}
}
@media (max-width: 630px) {
	.heading {
		padding-top: 60px;
	}
}
/* / 2nd Tab Content Css Start Here / */
/* profile section css ends here */

/* Third Tab Css Start Here */
.profile .loyality_box .topbar {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	padding-bottom: 30px;
}
.profile .loyality_box .topbar h4.title {
	margin: 0;
	font-size: 22px;
	color: #2a263d;
	font-family: "AvegasRoyale-bold";
}
.profile .loyality_box .topbar .button-group .btn {
	padding: 10px 35px;
}
.profile .loyality_box .balance_detail {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.profile .loyality_box .balance_detail .points_calculator {
	display: flex;
	align-items: center;
	padding-top: 30px;
}
.profile .loyality_box .balance_detail .points_calculator .fileds {
	display: flex;
	align-items: center;
}
.profile
	.loyality_box
	.balance_detail
	.points_calculator
	.fileds
	input.form-control {
	width: 80px;
	border-radius: 0;
	border: 1px solid #00000047;
	margin: 0 5px;
}
.profile .loyality_box .balance_detail .points_calculator .fileds label {
	margin: 0;
}
.profile .loyality_box .balance_detail .points_calculator .button-group {
	margin-left: 20px;
}
.profile
	.loyality_box
	.balance_detail
	.points_calculator
	.button-group
	button.btn
	img {
	margin-left: 10px;
}
.profile .loyality_box .balance_detail .your_balance h5 {
	font-size: 18px;
	font-family: "AvegasRoyale-reg";
}
.profile .loyality_box .balance_detail .your_balance .balance {
	background: #fff9f6;
	padding: 10px 15px;
}
.profile .loyality_box .balance_detail .your_balance .balance h3 {
	color: #a6674a;
	font-size: 40px;
	margin: 0;
}
.profile .loyality_box .balance_detail .your_balance .balance h3 span.points {
	font-size: 16px;
	color: #a6674a;
}
.profile .loyality_box .order-details.table-resposnive {
	margin-top: 20px;
}
.profile .loyality_box .order-details.table-resposnive table.table tr th {
	border: 0;
	text-align: center;
	font-size: 18px;
	font-family: "AvegasRoyale-bold";
}
.profile .loyality_box .order-details.table-resposnive table.table tr td {
	border: 0;
	text-align: center;
	font-size: 15px;
	font-weight: 300;
	color: #2a263d;
	padding: 15px 15px;
}
.profile .loyality_box .order-details.table-resposnive table.table tr {
	border: 1px solid #ddd;
}
.profile .loyality_box .order-details.table-resposnive table.table tr.top_row {
	border: 0;
}
.profile
	.loyality_box
	.order-details.table-resposnive
	table.table
	tr.top_row
	th {
	padding: 20px 10px;
}
.profile .loyality_box .order-details.table-resposnive table.table tr.spacer {
	height: 10px;
	border-right: 0;
	border-left: 0;
}
.profile .loyality_box ul.pagination {
	justify-content: end;
}
/* Third Tab Css End Here */

/* Fourth Tab Css Start Here */
.profile .wallet_box .topbar {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	padding-bottom: 30px;
}
.profile .wallet_box .topbar h4.title {
	margin: 0;
	font-size: 22px;
	color: #2a263d;
	font-family: "AvegasRoyale-bold";
}
.profile .wallet_box .topbar .button-group .btn {
	padding: 10px 35px;
}
.profile .wallet_box .balance_detail {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.profile .wallet_box .balance_detail .your_balance h5 {
	font-size: 18px;
	font-family: "AvegasRoyale-reg";
}
.profile .wallet_box .balance_detail .your_balance .balance {
	background: #fff9f6;
	padding: 10px 15px;
}
.profile .wallet_box .balance_detail .your_balance .balance h3 {
	color: #a6674a;
	font-size: 40px;
	margin: 0;
}
.profile .wallet_box .balance_detail .your_balance .balance h3 span.points {
	font-size: 16px;
	color: #a6674a;
}
.profile .wallet_box .order-details.table-resposnive {
	margin-top: 20px;
}
.profile .wallet_box .order-details.table-resposnive table.table tr th {
	border: 0;
	text-align: left;
	font-size: 18px;
	font-family: "AvegasRoyale-bold";
}
.profile .wallet_box .order-details.table-resposnive table.table tr td {
	border: 0;
	text-align: left;
	font-size: 15px;
	font-weight: 300;
	color: #2a263d;
	padding: 15px 20px;
}
.profile .wallet_box .order-details.table-resposnive table.table tr {
	border: 1px solid #ddd;
}
.profile .wallet_box .order-details.table-resposnive table.table tr.top_row {
	border: 0;
}
.profile .wallet_box .order-details.table-resposnive table.table tr.top_row th {
	padding: 20px 20px;
}
.profile .wallet_box .order-details.table-resposnive table.table tr.spacer {
	height: 10px;
	border-right: 0;
	border-left: 0;
}
.profile .wallet_box ul.pagination {
	justify-content: end;
}
/* Fourth Tab Css End Here */

@media (max-width: 414px) {
	.profile .cancel-memberBtn {
		width: 104% !important;
	}
	.headbtn {
		margin-top: -92px !important;
	}
	.myaddress_page {
		padding: 36px 0px;
	}
	.set-row {
		padding-bottom: 34px !important;
	}
	.Book_range_center .priceRate {
		font-size: 21px;
	}
	.profile .cancel-memberBtn3,
	.profile .cancel-memberBtn4 {
		padding: 7px 20px;
		width: 112% !important;
		margin-left: -17px;
		float: left;
	}
	.profile .cancel-memberBtn5 {
		padding: 7px 20px;
		width: 112% !important;
		margin-left: -17px;
		float: left;
	}
}

@media (max-width: 412px) {
	.priceRate img {
		width: 12px;
	}
	.profile .cancel-memberBtn {
		width: 75%;
	}
	.info-profile hr {
		width: 133%;
		position: relative;
		left: -50px;
	}
}

@media (max-width: 390px) {
	.profile .cancel-memberBtn {
		width: 104% !important;
	}
	/* .Framers-page .main-Title {
		font-size: 16px;
	} */
	.priceRate img {
		width: 12px;
	}

	/* .priceRate img {
		width: 13px;
	} */
	.profile .cancel-memberBtn {
		width: 106%;
	}
	.info-profile hr {
		width: 136%;
		position: relative;
		left: -50px;
	}
}

@media (max-width: 375px) {
	.profile .cancel-memberBtn {
		width: 104% !important;
	}
	.headbtn {
		margin-top: -92px !important;
	}
	.myaddress_page {
		padding: 36px 0px;
	}
	.set-row {
		padding-bottom: 34px !important;
	}
	.info-profile hr {
		width: 146%;
		position: relative;
		left: -53px;
	}

	.profile .cancel-memberBtn2 {
		margin-top: 50px;
	}
	.profile .cancel-memberBtn3,
	.profile .cancel-memberBtn4 {
		padding: 7px 20px;
		width: 112% !important;
		margin-left: -17px;
		float: left;
	}
	.profile .cancel-memberBtn5 {
		padding: 7px 20px;
		width: 112% !important;
		margin-left: -17px;
		float: left;
	}
	.Accounts {
		/* padding-top: 40px; */

		flex-direction: column;
		margin-bottom: 27px;
	}
	.security-form-wrapper {
		padding: 0px 0px 0px 26px;
		padding-top: 0px !important;
		margin-bottom: 35px;
		width: 91%;
	}
}

@media (max-width: 360px) {
	.profile .cancel-memberBtn3,
	.profile .cancel-memberBtn4 {
		padding: 7px 20px;
		width: 121% !important;
		margin-left: -24px;
		float: left;
	}
	.profile .cancel-memberBtn5 {
		padding: 7px 20px;
		width: 121% !important;
		margin-left: -24px;
		float: left;
	}
	.Accounts {
		flex-direction: column;
		margin-bottom: 24px;
	}
	.Framers-page .main-Title {
		font-size: 15px;
	}
	.Book_range_center .priceRate {
		font-size: 20px;
	}
	.info-profile hr {
		width: 140%;
		position: relative;
		left: -50px;
	}
}

@media (max-width: 320px) {
	.info-profile hr {
		width: 100%;
		position: relative;
		left: -1px;
	}
	.profile .rightSide-profile {
		padding: 47px 25px;
	}
	.Camping-page .main-Title {
		font-size: 15px;
	}
	.Book_range_center .priceRate {
		margin-bottom: -9px;
	}
	.priceRate {
		font-size: 20px;
	}
	.profile .cancel-memberBtn3,
	.profile .cancel-memberBtn3 {
		padding: 9px 7px;
		width: 100%;
		float: left;
		font-size: 9px;
		font-weight: 500;
	}

	.profile .cancel-memberBtn5 {
		padding: 9px 7px;
		width: 100%;
		float: left;
		font-size: 9px;
		font-weight: 500;
	}
	.profile .cancel-memberBtn3 img {
		font-size: 9px;
	}
	.priceRate img {
		width: 10px;
		margin-top: -5px;
		margin-right: -4px;
	}
	.family-member_ship input,
	.family-member_ship select {
		width: 102%;
	}
}
