.number input[type="text"] {
	width: 58px;
	text-align: center;
	border: 0px;
	color: #1aac7a;
	font-size: 20px;
	font-weight: bold;
	font-family: "GT Walsheim Pro regular";
}
.cart-page .number span {
	width: 38px;
	height: 31px;
	background-color: #286152;
	/* background-image: linear-gradient(to right, #A2D858 , #51A851); */
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 21px;
	color: #fff;
	border: unset;
}
.number {
	display: flex;
	align-items: center;
	justify-content: center;
}

.toaster_ul {
	margin: unset;
	padding: 21px 21px 14px;
}
.cart-page {
	padding: 80px 0px;
	background-image: url("../img/bgDesign.png");
	background-size: 100% 100%;
	background-attachment: fixed;
	background-size: 100%;
}
.cart-page.table {
	border-bottom: 1px solid #d9e6dc;
}
.cart-page tr td {
	vertical-align: middle;
	font-family: "SF UI Display";
	color: #286152;
	font-size: 15px;
	font-weight: 500;
	border-top: 0px;
	/* padding: 30px 10px; */
}

.inputDiv {
	position: relative;
	width: 100%;
	border-radius: 100px;
	background-color: #f6f6f1;
}

.inputDiv button {
	position: absolute;
	right: -13px;
	top: 0;
	width: 150px;
}

.inputDiv input {
	background: none;
	outline: none;
	padding: 10px;
	border: none;
	width: 100%;
}

.table .pro-name select {
	background: transparent;
	outline: none;
	border: none;
}
td.pro-name select.form-control {
	background: url(../img/down-arrow.png) no-repeat right center;
}
.dicount_section {
	width: 100%;
}

.cart-page .table-responsive tr {
	/* border-left: 1px solid #c4d8c9; */
}
.cart-page .table thead th:not(:last-child) {
	/* border-right: 1px solid #c4d8c9; */
}

.cart-page .table {
	background-color: #f1efde !important;
}

.cart-page .table thead th {
	/* border-right: 1px solid #c4d8c9; */
	/* border-top: 1px solid #c4d8c9; */
	border-bottom: 1px solid #c4d8c9;
	text-align: center;
	/* padding: 30px 0px; */
	font-family: "SF UI Display";
	color: #fff;
	font-size: 16px;
	font-weight: bold;
	background-color: #286152;
}
.cart-page td:not(:last-child) {
	/* border-right: 1px solid #c4d8c9; */
}
.cart-page td {
	/* border-left: 1px solid #c4d8c9; */
	/* border-right: 1px solid #c4d8c9; */
	text-align: center;
}

.cart-page td.pro-img img {
	/* background: #1aac7a; */
	object-fit: cover;
	/* padding: 10px; */
	border-radius: 6px;
}
.cart-page button {
	background: transparent;
	border: 0px;
	color: #ef5d61;
	font-size: 23px;
	padding: 0px;
}

.settotal-price {
	color: #ef5d61 !important;
}

.seticon {
	color: #ef5d61 !important;
}

td.pro-name.main-card-control {
	text-align: left;
}

.cart-page .button-group .clearBtn {
	background: #eeeeee;
	padding: 10px 38px;
	font-family: "SF UI Display";
	color: #898787;
	font-size: 15px;
	margin-right: 18px;
	border-radius: 22px;
	text-transform: uppercase;
}

.cart-page .button-group .continueBtn {
	background: #343436;
	padding: 10px 20px;
	font-family: "SF UI Display";
	color: #fff;
	font-size: 15px;
	margin-right: 18px;
	border-radius: 22px;
	text-transform: uppercase;
}

.cart-table-wrapper {
	/* background: #fff; */
	box-shadow: 3px 3px 10px 1px #00000024;
	/* padding-bottom: 30px; */
	/* border-left: 1px solid #c4d8c9; */
	/* border-right: 1px solid #c4d8c9; */
	/* border-bottom: 1px solid #c4d8c9; */
}

.cart-content-wrapper ul {
	list-style: none;
}
.cart-content-wrapper ul li {
	display: flex;
	justify-content: space-between;
	line-height: 44px;
}
.cart-content-wrapper {
	/* padding: 30px; */
}
.cart-footer-wrapper li {
	display: flex;
	justify-content: space-between;
}
.cart-footer-wrapper {
	padding: 12px 0px;
	border-top: 1px solid #d9e6dc;
	border-bottom: 1px solid #d9e6dc;
}
.cart-content-wrapper ul {
	margin: 0px;
}
.cart-footer-wrapper ul {
	margin: 0px;
}
.cart-t-tal-wrapper .cart-header {
	padding: 20px 0px;
	border-top: 1px solid #d9e6dc;
	border-bottom: 1px solid #d9e6dc;
}

.cart-header .cart-total {
	font-family: "PlayfairDisplay";
	color: #274d5e;
	font-weight: 600;
	font-size: 24px;
	margin: 0px;
}

.cart-t-tal-wrapper {
	/* border-left: 1px solid #d9e6dc;
    border-right: 1px solid #d9e6dc;
    box-shadow: 3px 3px 10px 1px #00000024; */
}
.cart-content-wrapper ul li span.property {
	font-family: "SF UI Display";
	color: #989898;
	font-size: 18px;
	font-weight: 500;
}
.cart-content-wrapper ul li span.value {
	color: #989898;
	font-size: 18px;
	font-weight: 500;
	font-family: "SF UI Display";
}

.cart-footer-wrapper ul li span.property {
	font-family: "SF UI Display";
	color: #ef5d61;
	font-size: 18px;
	font-weight: 500;
}

.cart-footer-wrapper ul li span.value {
	font-family: "SF UI Display";
	color: #ef5d61;
	font-size: 18px;
	font-weight: 500;
}

section.cart button.btn.contine-shop {
	background: #dedede;
	color: #274d5e;
	font-family: "GT Walsheim Pro regular";
	font-size: 16px;
	font-weight: 500;
	border: 2px solid transparent;
	padding: 10px 30px;
}
section.cart button.btn.pro-shop {
	background: #0b390c;
	color: #fff;
	border: 2px solid transparent;
	font-family: "GT Walsheim Pro regular";
	font-size: 16px;
	font-weight: 500;
	border: 2px solid transparent;
	padding: 10px 30px;
}

.discount_code_btn {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.discount-codes {
	display: flex;
	flex-direction: row;
	width: 50%;
	align-items: center;
	justify-content: center;
}

.discount-codes p {
	font-family: "SF UI Display";
	color: #274d5e;
	font-size: 16px;
	font-weight: 500;
	width: 25%;
}

.discount-codes input {
	width: 75%;
	padding: 10px;
	font-family: "SF UI Display";
	font-size: 16px;
	font-weight: 500;
}

.cart-page .checkout-cont {
	float: right;
}

.cart-page .checkoutBtn {
	background: #286152;
	padding: 10px 20px;
	font-family: "SF UI Display";
	color: #fff;
	font-size: 15px;
	margin-right: 18px;
	border-radius: 22px;
	text-transform: uppercase;
}

.cart-page .button-group {
	padding: 20px 0px;
}

@media (max-width: 991px) {
	.cart-page .button-group .clearBtn {
		padding: 10px 51px;
		margin-top: 15px;
	}
}

@media (max-width: 768px) {
	.cart-page {
		background: none;
	}
	.cart-page .table thead th {
		font-size: 12px;
	}
	.cart-page tr td:nth-child(1) {
		width: 120px;
		min-width: 120px;
	}
	.cart-page tr td:nth-child(2) {
		width: 120px;
		min-width: 120px;
	}
	.cart-page tr td:nth-child(3) {
		min-width: 170px;
	}
	.cart-page tr td:nth-child(4) {
		min-width: 170px;
	}
	.cart-page tr td:nth-child(5) {
		min-width: 120px;
	}
	.cart-page tr td:nth-child(7) {
		min-width: 120px;
	}
	.cart-page .button-group .continueBtn {
		margin-right: 92px;
	}
	.cart-page .checkoutBtn {
		margin-top: 10px;
	}
}

@media (max-width: 414px) {
	.cart-page tr td:nth-child(1) {
		width: 120px;
		min-width: 120px;
	}
	.cart-page tr td:nth-child(2) {
		width: 120px;
		min-width: 120px;
	}
	.cart-page tr td:nth-child(3) {
		min-width: 170px;
	}
	.cart-page tr td:nth-child(4) {
		min-width: 240px;
	}
	.cart-page tr td:nth-child(5) {
		min-width: 120px;
	}
	.cart-page tr td:nth-child(7) {
		min-width: 120px;
	}
}

@media (max-width: 375px) {
	.cart-page tr td:nth-child(4) {
		min-width: 255px;
	}
}
@media (max-width: 324px) {
	.cart-page .table thead th {
		font-size: 12px;
	}
	.cart-page .button-group .continueBtn {
		margin-right: 92px;
	}
	.cart-page .checkoutBtn {
		margin-top: 10px;
	}
}

@media (max-width: 320px) {
	.cart-page .button-group .clearBtn {
		padding: 10px 86px;
		margin-top: 15px;
	}
	.cart-page .button-group .continueBtn {
		padding: 10px 55px;
	}
	.cart-page .checkoutBtn {
		padding: 10px 7px;
		margin-right: 4px;
	}
}
