.family-member_ship {
	font-family: "SF UI Display semibold";
	font-size: 15px;
}

.family-member_ship .sub-title-sm {
	font-size: 15px;
	color: #2a263d85;
	font-family: "SF UI Display";
	margin: 0;
	margin-top: 28px;
}

.family_mem_booking {
	display: flex;
	justify-content: space-between;
}

.family_mem_booking button {
	border: none;
	background: transparent;
	color: #286152;
	font-family: "SF UI Display bold";
	font-size: 14px;
}

.family-member_ship input,
.family-member_ship select {
	width: 76%;
	background-color: #f1efde;
	border: unset;
	padding: 4px 8px;
	border-bottom: 1px solid #c3c3c3;
	padding-top: 20px;
}
