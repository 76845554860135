.bg-header {
	background: #fff;
}
.bg-header ul.navbar-nav li a {
	color: #000;
}
.faq {
	padding: 80px 0px;
	background-image: url("../img/bgDesign.png");
	background-size: 100% 100%;
	background-attachment: fixed;
	background-size: 100%;
}

.faq-wrapper h2 {
	color: #286152;
	font-size: 50px;
	font-weight: bold;
	text-align: center;
	margin-bottom: 12px;
	font-family: ZangeziSans07_TRIAL;
}

.faq-wrapper .main-para {
	font-size: 16px;
	color: #54544e;
	font-family: "SF UI Display";
	text-align: center;
}

div#accordion {
	margin-top: 30px;
	border: 1px solid #dcdcdc;
	padding: 24px 0px;
	padding-bottom: 0px;
}
div#accordion .card-header {
	background: transparent;
	border: none;
	padding: 17px 24px;
}
div#accordion .card {
	border-top: 1px solid #dcdcdc !important;
	border-radius: 0px;
	border: none;
}
div#accordion a.card-link {
	color: #000;
	font-family: "AvegasRoyale-bold";
	font-size: 18px;
}
.faq-wrapper .card-body {
	padding: 20px;
	font-family: "Montserrat";
	font-size: 14px;
	color: #afafaf;
}
a.card-link[aria-expanded="true"] .fa-angle-down::before {
	content: "\f068";
}
div#accordion a.card-link:active,
div#accordion a.card-link:focus {
	text-decoration: none;
}

.accordion-button:not(.collapsed) {
	color: #286152;
	background-color: #f1efde;
	box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}
.collapse.show {
	display: block;
	background-color: #f1efde;
}

.accordion-item {
	background-color: #f1efde;
	border: 1px solid #c9c9c9;
}

.accordion-button {
	position: relative;
	color: #54544e;
	display: flex;
	align-items: center;
	width: 100%;
	padding: 1rem 1.25rem;
	font-size: 1rem;
	color: #212529;
	text-align: left;
	background-color: #f1efde;
	border: 0;
	border-radius: 0;
	overflow-anchor: none;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
		border-radius 0.15s ease;
}
.accordion-button::after {
	background-image: url("../img/plus.png");
	/* how to remove background-size: ; */
}

.accordion-button:not(.collapsed)::after {
	background-image: url("../img/minus.png");
}

.faqs_sec .before {
	position: absolute;
	left: 0;
	z-index: -1;
	top: 10%;
	filter: grayscale(1);
}
.faqs_sec {
	padding: 60px 0;
	position: relative;
}
.faqs_sec .heading {
	text-align: center;
	padding-bottom: 20px;
}
.faqs_sec .heading h2 {
	padding-bottom: 10px;
	font-family: "ZangeziSans07_TRIAL";
	font-size: 54px;
	color: #286152;
}
.faqs_sec .heading p {
	color: #54544e;
	font-family: "SF UI Display";
	color: #000000a1;
}
.faqs_sec .accordion-item {
	border: 1px solid #7070708a;
	margin-bottom: 25px;
	border-radius: 10px;
}
.faqs_sec .accordion-item .accordion-header button {
	background: transparent !important;
	padding: 0 20px;
	height: 50px;
	font-size: 16px;
	color: #54544e;
	font-family: "SF UI Display";
	box-shadow: none;
	font-weight: bold;
}
.faqs_sec .accordion-item .accordion-header {
	border: 0 !important;
}
.accordion-item .accordion-body {
	padding-top: 0;
	color: #54544e;
	font-family: "SF UI Display";
	font-size: 16px;
}

.accordion-button.collapsed {
	font-family: "SF UI Display";
	color: #54544e;
}

.faqs_sec .accordion-item .accordion-header button[aria-expanded="true"] {
	color: #54544e;
}
.faqs_sec
	.accordion-item
	.accordion-header
	.accordion-button:not(.collapsed)::after {
	background-image: url("../img/minus.png");
	background-size: 16px;
}
.faqs_sec .accordion-item .accordion-header .accordion-button::after {
	background-image: url("../img/plus.png");
	background-size: 16px;
}
.faqs_sec .after {
	position: absolute;
	right: 0;
	bottom: 0;
	z-index: -1;
}

.faqs_sec .accordion-item .accordion-header button[aria-expanded="true"] {
	color: #286152;
	font-family: "SF UI Display";
	font-size: 19px;
	font-weight: bold;
}

/* responsive starts here */
@media (max-width: 1100px) {
	.faq-wrapper h2 {
		font-size: 30px;
		text-align: center;
	}
}
@media (max-width: 768px) {
	.faq {
		background: none;
	}
}
@media (max-width: 480px) {
	div#accordion a.card-link {
		font-size: 16px;
	}
}
@media (max-width: 414px) {
	div#accordion a.card-link {
		font-size: 13px;
	}
}
