.plantingMixing-page {
	padding: 80px 0px;
	background-image: url("../img/bgDesign.png");
	background-size: 100% 100%;
	background-attachment: fixed;
	background-size: 100%;
}

.plantingMixing-page .main-Title {
	color: #286152;
	font-size: 50px;
	font-weight: bold;
	text-align: center;
	font-family: ZangeziSans07_TRIAL;
}

.plantingMixing-page .main-para {
	font-size: 16px;
	color: #0000008f;
	font-family: SF UI Display;
	text-align: center;
	margin-bottom: 35px;
}

.plantingMixing-page .img-cont {
	overflow: hidden;
	height: 100%;
	width: 100%;
}
.plantingMixing-page .img-cont img {
	width: 100%;
}

.plantingMixing-page .p-card .content {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px 10px;
}

.plantingMixing-page .p-card {
	margin-bottom: 1rem;
}

.plantingMixing-page .p-card:hover {
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	padding: 0px;
}

.plantingMixing-page .p-card .content .title,
.plantingMixing-page .p-card .content .title2 {
	font-family: "SF UI Display";
	font-size: 18px;
	color: #000000b8;
	font-weight: 400;
}

@media (min-width: 1200px) {
	.page-item.active .page-link {
		padding: 12px 20px;
	}
	li.page-item.disabled a.page-link {
		padding: 7px 20px;
	}
	ul.pagination li.page-item:first-child a.page-link,
	ul.pagination li.page-item:last-child a.page-link {
		border-radius: 0px !important;
		background: #ffffff;
		border: 0px;
		color: #6d6d6d;
		font-size: 25px;
		padding: 8px 16px;
	}
	li.page-item a.page-link {
		padding: 12px 20px;
	}
	ul.pagination li.page-item.disabled a.page-link {
		font-size: 13px;
	}
}
@media (min-width: 1024px) {
	.page-item.active .page-link {
		padding: 12px 20px;
	}
	li.page-item.disabled a.page-link {
		padding: 7px 20px;
	}
	ul.pagination li.page-item:first-child a.page-link,
	ul.pagination li.page-item:last-child a.page-link {
		border-radius: 0px !important;
		background: #ffffff;
		border: 0px;
		color: #6d6d6d;
		font-size: 25px;
		padding: 8px 16px;
	}
	li.page-item a.page-link {
		padding: 12px 20px;
	}
	ul.pagination li.page-item.disabled a.page-link {
		font-size: 13px;
	}
}

@media (max-width: 991px) {
	.page-item.active .page-link {
		padding: 12px 20px;
	}
	li.page-item.disabled a.page-link {
		padding: 7px 20px;
	}
	ul.pagination li.page-item:first-child a.page-link,
	ul.pagination li.page-item:last-child a.page-link {
		border-radius: 0px !important;
		background: #ffffff;
		border: 0px;
		color: #6d6d6d;
		font-size: 25px;
		padding: 8px 16px;
	}
	li.page-item a.page-link {
		padding: 12px 20px;
	}
	ul.pagination li.page-item.disabled a.page-link {
		font-size: 13px;
	}
}

@media (max-width: 767px) {
	.plantingMixing-page {
		padding: 40px 0;
		background: none;
	}
	.plantingMixing-page .filter-side {
		margin-bottom: 25px;
	}
	.page-item.active .page-link {
		padding: 12px 20px;
	}
	li.page-item.disabled a.page-link {
		padding: 7px 20px;
	}
	ul.pagination li.page-item:first-child a.page-link,
	ul.pagination li.page-item:last-child a.page-link {
		font-size: 25px;
		padding: 8px 16px;
	}
	li.page-item a.page-link {
		padding: 12px 20px;
	}

	ul.pagination li.page-item.disabled a.page-link {
		font-size: 17px;
	}
	.plantingMixing-page .main-Title {
		font-size: 30px;
	}
}

@media (max-width: 576px) {
	li.page-item a.page-link {
		padding: 12px 9px;
	}
	.page-item.active .page-link {
		padding: 12px 10px;
	}

	ul.pagination li.page-item.disabled a.page-link {
		font-size: 14px;
	}
	ul.pagination li.page-item:first-child a.page-link,
	ul.pagination li.page-item:last-child a.page-link {
		font-size: 21px;
	}
	ul.pagination li.page-item:first-child a.page-link,
	ul.pagination li.page-item:last-child a.page-link {
		padding: 8px 4px;
	}
	.page-item {
		padding: 2px;
	}
}

@media (max-width: 375px) {
	.set_card {
		margin-bottom: -43px;
	}
	.page-item {
		padding: 6px;
	}
}

@media (max-width: 320px) {
	.plantingMixing-page .main-Title {
		font-size: 20px;
	}
	.plantingMixing-page .main-para {
		font-size: 15px;
	}
	.plantingMixing-page .p-card {
		margin-bottom: 0px;
	}
}
